import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from 'src/app/app.component';

export interface DialogData {
  phone: string;
  townshipId: string;
}
@Component({
  selector: 'app-verify-phone',
  templateUrl: './verify-phone.component.html',
  styleUrls: ['./verify-phone.component.scss'],
  standalone: false,
})
export class VerifyPhoneComponent implements OnInit {
  verifyPhoneForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<VerifyPhoneComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  async ngOnInit(): Promise<void> {
    this.verifyPhoneForm = this.fb.group({
      code: ['', [Validators.required]],
    });
    // checks if phone has heartbeat

    const smsCentralErrorsRef = doc(
      db,
      `township/${this.data.townshipId}/smsCentralErrors/${this.data.phone}`
    );
    onSnapshot(smsCentralErrorsRef, (doc) => {
      console.log('doc', doc);
      if (doc.exists) {
        this.dialogRef.close('skip');
      }
    });
  }

  submit() {
    if (this.verifyPhoneForm.valid) {
      this.dialogRef.close(this.verifyPhoneForm.value.code);
    }
  }
}
