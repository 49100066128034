<!-- To use a transparent background with this iframe, use <iframe src="stuff.htm" allowtransparency="true"></iframe> -->
<div
  class="wrapper"
  role="main"
>
  <div
    class="activate-card"
    *ngIf="voucherGroup; else loading"
  >
    <div
      class="imgWrapper"
      *ngIf="showHeaderImg"
    >
      <img
        [src]="townshipImage"
        alt="logo"
        class="logo"
      />
    </div>
    <div class="title">{{ voucherGroup.slogan }}</div>
    <div
      *ngIf="
        !voucherGroup.hideVoucherRemainingInfo &&
        voucherGroup.type !== 'requestActivated'
      "
    >
      <div
        class="statistics"
        role="heading"
        aria-level="1"
        *ngIf="availableVouchers > 0"
      >
        <div class="subtitle above">
          {{ 'header.subtitle-above' | translate }}
        </div>
        <div class="statistic">{{ availableVouchers }}</div>
        <div class="subtitle">
          {{ 'header.subtitle-under' | translate : envName }}
        </div>
      </div>
      <div
        class="statistics"
        role="heading"
        aria-level="1"
        *ngIf="availableVouchers <= 0"
      >
        <div class="subtitle above">
          {{ 'no-vouchers.message-above' | translate : envName }}
        </div>
        <div class="subtitle">
          {{ 'no-vouchers.message-under' | translate }}
        </div>
      </div>
    </div>

    <div class="stepper">
      <div
        class="stepper-step"
        [ngClass]="{
          'stepper-active-step': step >= 1,
          'stepper-inactive-step': step < 1
        }"
      >
        1
      </div>
      <div class="stepper-dashes"></div>
      <div
        class="stepper-step"
        [ngClass]="{
          'stepper-active-step': step >= 2,
          'stepper-inactive-step': step < 2
        }"
      >
        2
      </div>
      <div class="stepper-dashes"></div>
      <div
        class="stepper-step"
        [ngClass]="{
          'stepper-active-step': step >= 3,
          'stepper-inactive-step': step < 3
        }"
      >
        3
      </div>
      <div
        class="stepper-dashes"
        *ngIf="
          voucherGroup.personalisedNote && voucherGroup.sellType === 'physical'
        "
      ></div>
      <div
        class="stepper-step"
        [ngClass]="{
          'stepper-active-step': step >= 4,
          'stepper-inactive-step': step < 4
        }"
        *ngIf="
          voucherGroup.personalisedNote && voucherGroup.sellType === 'physical'
        "
      >
        4
      </div>
    </div>
    <div *ngIf="voucherGroup.type === 'sell'">
      <form
        [formGroup]="formStep1"
        class="step"
        aria-label="stap 1"
        *ngIf="step === 1"
      >
        <div
          role="group"
          aria-labelledby="step1Title"
        >
          <div
            id="step1Title"
            class="titles"
          >
            1: {{ 'buy.step1.title' | translate }}
          </div>
          <mat-form-field
            class="formfield-width-100"
            *ngIf="voucherGroup.voucherPrice === 0"
          >
            <mat-label>{{ 'buy.step1.coupon-value' | translate }}</mat-label>
            <input
              formControlName="value"
              type="number"
              matInput
              required
              autocomplete="off"
              min="10"
              max="300"
              onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
            />
            <mat-error *ngIf="formStep1.controls.value.errors?.min">
              {{ 'errors.minimumBuyVoucherValue' | translate }}
            </mat-error>
            <mat-error *ngIf="formStep1.controls.value.errors?.max">
              {{ 'errors.maximumBuyVoucherValue' | translate }}
            </mat-error>
            <mat-error *ngIf="formStep1.controls.value.errors?.required">
              {{ 'errors.required' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="formfield-width-100">
            <mat-label>{{ 'buy.step1.coupon-amount' | translate }}</mat-label>
            <input
              formControlName="amount"
              type="number"
              matInput
              required
              autocomplete="off"
              min="1"
              onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
            />
            <mat-error *ngIf="formStep1.controls.amount.errors?.required">
              {{ 'errors.required' | translate }}
            </mat-error>
            <mat-error *ngIf="formStep1.controls.amount.errors?.min">
              {{ 'errors.minimal' | translate }}
            </mat-error>
          </mat-form-field>
          <div class="amounts">
            <div class="amount">
              {{ 'buy.step1.costs' | translate }}
              <div class="spacing-flex"></div>
              <div class="value">
                €{{
                  this.voucherGroup.voucherPrice === 0
                    ? getCurrencyString(
                        this.formStep1.value.value * formStep1.value.amount
                      )
                    : getCurrencyString(
                        this.voucherGroup.voucherPrice * formStep1.value.amount
                      )
                }}
              </div>
            </div>
            <div class="amount">
              {{ 'buy.step1.administrationCosts' | translate }}
              <div class="spacing-flex"></div>
              <div class="value">
                €{{ getCurrencyString(this.voucherGroup.administrationCost) }}
              </div>
            </div>
            <hr />
            <div class="amount primary">
              {{ 'buy.step1.total' | translate }}
              <div class="spacing-flex"></div>
              <div class="value">
                €{{
                  this.voucherGroup.voucherPrice === 0
                    ? getCurrencyString(
                        this.formStep1.value.value * formStep1.value.amount +
                          this.voucherGroup.administrationCost
                      )
                    : getCurrencyString(
                        this.voucherGroup.voucherPrice *
                          formStep1.value.amount +
                          this.voucherGroup.administrationCost
                      )
                }}
              </div>
            </div>
          </div>
          <div class="buttons">
            <div class="spacing-flex"></div>
            <button
              mat-flat-button
              (click)="stepForward()"
              [disabled]="this.formStep1.invalid"
              class="border-button"
            >
              {{ 'buy.button.next' | translate }}
            </button>
          </div>
        </div>
      </form>
      <form
        [formGroup]="formStep2"
        class="step"
        aria-label="stap 2"
        *ngIf="step === 2"
      >
        <div
          role="group"
          aria-labelledby="step2Title"
        >
          <div
            class="titles"
            id="step2Title"
          >
            2: {{ 'buy.step2.title' | translate }}
          </div>
          <div class="amounts">
            <div class="amount primary">
              {{ 'buy.step1.total' | translate }}
              <div class="spacing-flex"></div>
              €{{
                this.voucherGroup.voucherPrice === 0
                  ? getCurrencyString(
                      this.formStep1.value.value * formStep1.value.amount +
                        this.voucherGroup.administrationCost
                    )
                  : getCurrencyString(
                      this.voucherGroup.voucherPrice * formStep1.value.amount +
                        this.voucherGroup.administrationCost
                    )
              }}
            </div>
          </div>
          <mat-radio-group
            formControlName="type"
            class="name-margin"
            aria-label="bedrijf of particulier"
          >
            <mat-radio-button value="customer">
              {{ 'buy.step2.private' | translate }}
            </mat-radio-button>
            <mat-radio-button value="company">
              {{ 'buy.step2.company' | translate }}
            </mat-radio-button>
          </mat-radio-group>
          <mat-form-field
            class="formfield-width-100"
            *ngIf="formStep2.value.type === 'company'"
          >
            <mat-label>{{ 'buy.step2.companyName' | translate }}</mat-label>
            <input
              formControlName="companyName"
              matInput
              required
              autocomplete="organization"
            />
            <mat-error>
              {{ getError('name') }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="formfield-width-100">
            <mat-label>
              {{ 'input.name' | translate }}
            </mat-label>
            <input
              formControlName="name"
              matInput
              required
              autocomplete="name"
            />
            <mat-error>
              {{ getError('name') }}
            </mat-error>
          </mat-form-field>
          <mat-form-field
            class="formfield-width-100"
            *ngIf="voucherGroup.requestPhone"
          >
            <mat-label> {{ 'input.phone' | translate }} </mat-label>
            <input
              minlength="10"
              formControlName="phone"
              matInput
              required
              autocomplete="tel-national"
            />
            <mat-error>
              {{ getError('phone') }}
            </mat-error>
          </mat-form-field>
        </div>
        <div
          role="group"
          aria-labelledBy="step2Billing"
        >
          <div
            class="titles"
            id="step2Billing"
          >
            {{ 'buy.step2.billing' | translate }}
          </div>
          <mat-form-field class="formfield-width-100">
            <mat-label>{{ 'input.postal' | translate }}</mat-label>
            <input
              formControlName="postal"
              id="postal"
              matInput
              #postalCode
              required
              autocomplete="postal-code"
              placeholder="1234AB"
            />
            <mat-error *ngIf="formStep2.controls.postal.hasError('apiError')">
              {{ 'Geen adres gevonden' }}
            </mat-error>
            <mat-error>
              {{ getError('postal') }}
            </mat-error>
          </mat-form-field>
          <div>
            <mat-form-field class="house-number">
              <mat-label> {{ 'input.houseNumber' | translate }} </mat-label>
              <input
                formControlName="houseNumber"
                matInput
                required
              />
              <mat-error>
                {{ getError('houseNumber') }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="house-number-addition divider">
              <mat-label>
                {{ 'input.houseNumberAddition' | translate }}
              </mat-label>
              <input
                formControlName="houseNumberAddition"
                matInput
              />
              <mat-hint>Dit veld is optioneel</mat-hint>
              <mat-error>
                {{ getError('houseNumberAddition') }}
              </mat-error>
            </mat-form-field>
          </div>
          <mat-form-field class="formfield-width-100">
            <mat-label> {{ 'input.street' | translate }} </mat-label>
            <input
              formControlName="street"
              matInput
              required
              autocomplete="address-line1"
            />
            <mat-error>
              {{ getError('street') }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="formfield-width-100">
            <mat-label>{{ 'input.city' | translate }}</mat-label>
            <input
              formControlName="city"
              matInput
              required
              autocomplete="address-level2"
            />
            <mat-error>
              {{ getError('city') }}
            </mat-error>
          </mat-form-field>
        </div>
        <div
          role="group"
          aria-labelledBy="step2Delivery"
        >
          <div
            class="titles"
            id="step2Delivery"
          >
            {{ 'buy.step2.delivery' | translate }}
          </div>
          <mat-radio-group
            formControlName="delivery"
            class="name-margin"
            aria-label="afleveradress"
          >
            <mat-radio-button value="equalto">
              {{ 'buy.step2.equal' | translate }}
            </mat-radio-button>
            <mat-radio-button value="differentfrom">
              {{ 'buy.step2.different' | translate }}
            </mat-radio-button>
          </mat-radio-group>

          <div class="delivery-position">
            <mat-form-field class="formfield-width-100">
              <mat-label>{{ 'input.postal' | translate }}</mat-label>
              <input
                formControlName="deliveryPostal"
                id="deliveryPostal"
                matInput
                #postalCode
                required
                autocomplete="postal-code"
                placeholder="1234AB"
              />
              <mat-error
                *ngIf="formStep2.controls.deliveryPostal.hasError('apiError')"
              >
                {{ 'Geen adres gevonden' }}
              </mat-error>
              <mat-error>
                {{ getError('deliveryPostal') }}
              </mat-error>
            </mat-form-field>
            <div>
              <mat-form-field class="house-number">
                <mat-label> {{ 'input.houseNumber' | translate }} </mat-label>
                <input
                  formControlName="deliveryHouseNumber"
                  matInput
                  required
                />
                <mat-error>
                  {{ getError('houseNumber') }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="house-number-addition divider">
                <mat-label>
                  {{ 'input.houseNumberAddition' | translate }}
                </mat-label>
                <input
                  formControlName="deliveryHouseNumberAddition"
                  matInput
                />
                <mat-hint>Dit veld is optioneel</mat-hint>
                <mat-error>
                  {{ getError('houseNumberAddition') }}
                </mat-error>
              </mat-form-field>
            </div>
            <mat-form-field class="formfield-width-100">
              <mat-label>{{ 'input.street' | translate }}</mat-label>
              <input
                formControlName="deliveryStreet"
                matInput
                required
              />
              <mat-error>
                {{ getError('name') }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="formfield-width-100">
              <mat-label>{{ 'input.city' | translate }}</mat-label>
              <input
                formControlName="deliveryCity"
                matInput
                required
                autocomplete="address-level2"
              />
              <mat-error>
                {{ getError('city') }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div
          role="group"
          aria-labelledBy="step2Confirmation"
        >
          <div
            class="titles"
            id="step2Confirmation"
          >
            {{ 'buy.step2.confirmation' | translate }}
          </div>
          <mat-form-field class="formfield-width-100">
            <mat-label>{{ 'input.email' | translate }}</mat-label>
            <input
              formControlName="email"
              matInput
              required
              (keydown.space)="$event.preventDefault()"
              autocomplete="email"
              placeholder="info@voorbeeld.nl"
            />
            <mat-error>
              {{ getError('email') }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="formfield-width-100">
            <mat-label>{{ 'input.confirm-email' | translate }}</mat-label>
            <input
              formControlName="emailConfirm"
              matInput
              required
              (keydown.space)="$event.preventDefault()"
              autocomplete="email"
              placeholder="info@voorbeeld.nl"
            />
            <mat-error>
              {{ getError('emailConfirm') }}
            </mat-error>
          </mat-form-field>
          <div class="buttons">
            <button
              mat-button
              (click)="stepBackward()"
              class="button-back"
              type="button"
            >
              {{ 'buy.button.back' | translate }}
            </button>
            <div class="spacing-flex"></div>
            <button
              class="border-button"
              (click)="stepForward()"
              [disabled]="this.formStep2.invalid"
              mat-flat-button
            >
              {{ 'buy.button.next' | translate }}
            </button>
          </div>
        </div>
      </form>
      <form
        [formGroup]="formStep3"
        class="step"
        aria-label="stap 3"
        *ngIf="extraStepExists && step === 3"
      >
        <div
          role="group"
          aria-labelledBy="step3Title"
        >
          <div
            class="titles"
            id="step3Title"
          >
            3: {{ 'buy.step3.extra' | translate }}
          </div>
          <div class="message">Uw bericht wordt op de pakbon geprint</div>
          <mat-form-field class="step-3-form-field-flex-100">
            <mat-label>
              {{ 'buy.step3.personalMessage' | translate }}
            </mat-label>
            <textarea
              formControlName="personalMessage"
              matInput
              cdkTextareaAutosize
              cdkAutosizeMinRows="{{ this.termsRows }}"
              cdkAutosizeMaxRows="{{ this.termsRows }}"
            ></textarea>
          </mat-form-field>
          <div class="flex-buttons">
            <button
              mat-button
              (click)="stepBackward()"
              type="button"
            >
              {{ 'buy.button.back' | translate }}
            </button>
            <div class="flex-spacer"></div>
            <button
              class="border-button"
              (click)="stepForward()"
              mat-flat-button
            >
              {{ 'buy.button.next' | translate }}
            </button>
          </div>
        </div>
      </form>
      <form
        [formGroup]="formStep4"
        class="step"
        aria-label="stap 4"
        *ngIf="(!extraStepExists && step === 3) || step === 4"
      >
        <div
          class="titles"
          role="heading"
          aria-level="2"
          *ngIf="step === 4"
        >
          4: Bestelling en betaling
        </div>
        <div
          class="titles"
          role="heading"
          aria-level="2"
          *ngIf="step != 4"
        >
          3: Bestelling en betaling
        </div>
        <div class="amounts">
          <div class="amount primary">
            {{
              'buy.step4.buyAmount'
                | translate : { value: this.formStep1.value.amount }
            }}
          </div>
          <div class="amount">
            {{ 'buy.step1.costs' | translate }}
            <div class="spacing-flex"></div>
            <div class="value">
              €{{
                this.voucherGroup.voucherPrice === 0
                  ? getCurrencyString(
                      this.formStep1.value.value * formStep1.value.amount
                    )
                  : getCurrencyString(
                      this.voucherGroup.voucherPrice * formStep1.value.amount
                    )
              }}
            </div>
          </div>
          <div class="amount">
            {{ 'buy.step1.administrationCosts' | translate }}
            <div class="spacing-flex"></div>
            <div class="value">
              €{{ getCurrencyString(this.voucherGroup.administrationCost) }}
            </div>
          </div>
          <hr />
          <div class="amount">
            {{ 'buy.step1.total' | translate }}
            <div class="spacing-flex"></div>
            <div class="value">
              €{{
                this.voucherGroup.voucherPrice === 0
                  ? getCurrencyString(
                      this.formStep1.value.value * formStep1.value.amount +
                        this.voucherGroup.administrationCost
                    )
                  : getCurrencyString(
                      this.voucherGroup.voucherPrice * formStep1.value.amount +
                        this.voucherGroup.administrationCost
                    )
              }}
            </div>
          </div>
        </div>
        <div
          role="group"
          aria-labelledBy="step4Terms"
        >
          <div
            class="titles"
            id="step4Terms"
          >
            {{ 'activate.body.conditions' | translate }}
          </div>
          <div class="textarea-positioning">
            <mat-form-field
              class="textarea"
              *ngIf="voucherGroup.terms"
              [class.voucher-terms]="availableVouchers > 0"
            >
              <textarea
                aria-label="voorwaarden"
                matInput
                cdkTextareaAutosize
                cdkAutosizeMinRows="{{ this.termsRows }}"
                cdkAutosizeMaxRows="{{ this.termsRows }}"
                disabled="true"
                value="{{ voucherGroup.terms }}"
                readonly
              ></textarea>
            </mat-form-field>
          </div>
          <div
            class="general-terms"
            *ngIf="!termsUrl"
          >
            <div class="title">
              {{ 'find-voucher-group.body.terms_general' | translate }}
            </div>
            <app-general-terms></app-general-terms>
          </div>
          <div
            class="general-terms"
            *ngIf="termsUrl"
          >
            <mat-label class="formfield-width-100">
              <span>{{ 'activate.body.terms.read-terms' | translate }} </span>
              <a
                href="{{ termsUrl }}"
                target="_blank"
                class="custom-tooltip terms-link-info"
                aria-label="algemene voorwaarden"
              >
                {{ 'activate.body.terms.terms-condition' | translate }}
              </a>
              <span>{{ 'activate.body.terms.here' | translate }}</span>
            </mat-label>
          </div>
          <mat-checkbox
            class="terms-checkbox"
            formControlName="terms"
            class="titles"
          >
            {{ 'activate.body.terms.accept' | translate }}
          </mat-checkbox>
        </div>
        <!-- <div
          role="group"
          aria-labelledBy="step4Bank"
        >
          <mat-form-field>
            <mat-label id="step4Bank">{{
              'buy.step4.bank' | translate
            }}</mat-label>
            <mat-select formControlName="bank">
              <mat-select-trigger *ngIf="formStep4.value.bank">
                <div class="static-prefix">
                  <div class="icon">
                    <img
                      src="{{ formStep4.value.bank.imgPath }}"
                      alt=""
                    />
                  </div>
                  <div class="issuer">
                    {{ formStep4.value.bank.name }}
                  </div>
                </div>
              </mat-select-trigger>
              <mat-option
                *ngFor="let issuer of issuers"
                [value]="issuer"
              >
                <div class="icon-wrapper">
                  <div class="icon">
                    <img
                      src="{{ issuer.imgPath }}"
                      alt=""
                    />
                  </div>
                  <div class="issuer">
                    {{ issuer.name }}
                  </div>
                </div>
              </mat-option>
            </mat-select>
            <mat-error>
              {{ getError('bank', 'formStep4') }}
            </mat-error>
          </mat-form-field>
        </div> -->
        <div class="buttons">
          <button
            [disabled]="awaitingResponse"
            mat-button
            (click)="stepBackward()"
            class="button-back"
            type="button"
          >
            {{ 'buy.button.back' | translate }}
          </button>
          <div class="spacing-flex"></div>
          <button
            [class.spinner]="awaitingResponse"
            mat-flat-button
            [disabled]="!formStep4.valid || !awaitingResponse"
            (click)="submit()"
            class="border-button"
          >
            {{ 'buy.button.pay' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<ng-template #loading>
  <div class="loading-wrapper">
    <div class="loading">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</ng-template>
