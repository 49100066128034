/* src/app/buy-vouchers/complete-order/complete-order.component.scss */
.wrapper {
  padding: 8px;
  background: none transparent;
}
.wrapper .general-terms {
  position: relative;
  top: -16px;
  margin-bottom: 8px;
}
.wrapper .general-terms a {
  color: var(--primary);
}
.wrapper .voucher-terms mat-label {
  color: rgba(0, 0, 0, 0.6) !important;
}
.wrapper .voucher-terms textarea {
  color: rgba(0, 0, 0, 0.87) !important;
}
.wrapper .terms-checkbox {
  position: relative;
  top: -16px;
  margin-bottom: 8px;
}
.wrapper .activate-card {
  margin: auto;
  width: 300px;
  box-shadow: none;
}
.wrapper .activate-card .title {
  margin-bottom: 16px;
  font-size: 26px;
  text-align: center;
  color: var(--primary);
}
.wrapper .activate-card .success-image {
  position: relative;
  width: 600px;
  left: -150px;
}
.wrapper .activate-card .hint-text {
  height: 8px;
  align-items: flex-end;
}
.wrapper .activate-card .hint-text .mat-hint {
  height: 17px;
  float: right;
  position: relative;
  bottom: 16px;
  right: 0px;
}
.wrapper .activate-card .statistics {
  left: 0;
  right: 0;
  margin: auto;
  top: 40px;
  max-width: 200px;
}
.wrapper .activate-card .statistics .statistic {
  font-size: 50px;
  text-align: center;
  color: var(--primary);
}
.wrapper .activate-card .statistics .subtitle {
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}
.wrapper .activate-card form {
  margin-top: 24px;
}
.wrapper .activate-card .mat-card-actions {
  position: absolute;
  bottom: 16px;
  right: 16px;
}
.wrapper .activate-card .mat-card-actions button {
  text-transform: uppercase;
}
.loading-wrapper {
  height: 100vh;
  width: 100vw;
}
.loading-wrapper .loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}
/*# sourceMappingURL=complete-order.component.css.map */
