<!-- To use a transparent background with this iframe, use <iframe src="stuff.htm" allowtransparency="true"></iframe> -->
<div
  class="wrapper"
  [class.is-dialog]="isDialog"
  [class.is-embedded]="isEmbedded"
  *ngIf="loaded; else loading"
  [ngStyle]="
    highContrast ? { 'filter': 'saturate(0)' } : { 'filter': 'saturate(1)' }
  "
>
  <div
    *ngIf="!isDialog && !isEmbedded && showHeaderImg"
    class="wrapper-bg"
    role="banner"
  >
    <div class="header-wrapper">
      <div class="header-image-wrapper">
        <div
          class="header-img"
          style="background-image:url('{{ townshipImage }}');"
        ></div>
      </div>
      <div class="header-text-button-wrapper">
        <h1 class="header-text">
          {{ townshipName }}
        </h1>
        <div class="header-button-wrapper">
          <button
            (click)="setHighContrast()"
            mat-flat-button
            class="header-button"
          >
            <mat-icon>contrast</mat-icon>
            Hoog contrast
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="activate-wrapper"
    [style.max-width]="pageWidth"
    *ngIf="noGroupProvided || voucherGroupData; else loading"
  >
    <div
      class="voucher-group-card-room"
      *ngIf="voucherGroupData && voucherGroupData.hideHeaderImage"
    ></div>
    <div
      class="voucher-group-card"
      *ngIf="voucherGroupData && !voucherGroupData.hideHeaderImage"
    >
      <div
        class="avatar"
        *ngIf="voucherGroupData.imageUrl"
        style="background-image:url('{{ voucherGroupData.imageUrl }}');"
      ></div>
      <div
        class="avatar placeholder"
        *ngIf="!voucherGroupData.imageUrl"
      >
        {{ voucherGroupData?.name[0] }}
      </div>
      <div class="card-content">
        <div class="title-slogan">
          <div class="card-title">
            {{ voucherGroupData.name }}
          </div>
          <div
            class="slogan"
            *ngIf="voucherGroupData.slogan"
          >
            {{ voucherGroupData.slogan }}
          </div>
        </div>
        <div class="voucher-value-wrapper">
          <div
            class="voucher-value"
            *ngIf="
              voucherGroupData.couponValue &&
              !voucherGroupData.altTextCouponValue
            "
          >
            €{{ getCurrencyString(voucherGroupData.couponValue) }}
          </div>
          <div
            class="coupon"
            *ngIf="!voucherGroupData.altTextCouponValue"
          >
            {{ 'find-voucher-group.body.voucher' | translate }}
          </div>
          <div
            class="alt-coupon"
            *ngIf="voucherGroupData.altTextCouponValue"
          >
            {{ voucherGroupData.altTextCouponValue }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="activate-content"
      *ngIf="voucherGroupData?.type !== 'sell'"
    >
      <div
        *ngIf="
          !voucherGroupData?.hideVoucherRemainingInfo &&
          voucherGroupData?.type !== 'requestActivated'
        "
      >
        <div
          class="statistics"
          *ngIf="availableVouchers > 0 && validDateToRequest && !noStatistics"
        >
          <div
            class="above"
            [ngClass]="isEmbedded ? 'subtitle-embedded' : 'subtitle'"
          >
            {{ 'header.subtitle-above' | translate }}
          </div>
          <div [ngClass]="isEmbedded ? 'statistic-embedded' : 'statistic'">
            {{ availableVouchers }}
          </div>
          <div [ngClass]="isEmbedded ? 'subtitle-embedded' : 'subtitle'">
            {{ 'header.subtitle-under' | translate : envName }}
          </div>
          <div
            class="subtitle acitvateDateNotPassed"
            *ngIf="acitvateDateNotPassed"
          >
            <span>
              <p>
                {{ 'activate.warning.activate-date' | translate }}
              </p>
              {{ usableFromDate | date : 'dd/MM' }}
            </span>
          </div>
        </div>
        <div
          class="statistics"
          *ngIf="availableVouchers <= 0 || !validDateToRequest"
        >
          <div class="subtitle above">
            {{ 'no-vouchers.message-above' | translate : envName }}
          </div>
          <div class="subtitle">
            {{ 'no-vouchers.message-under' | translate }}
          </div>
        </div>
      </div>
      <form
        aria-label="voucher activeren formulier"
        class="activate-voucher-form"
        (ngSubmit)="
          !inputData?.preparingRequest ? submit() : sendPreFilledForm()
        "
        [formGroup]="activateVoucherForm"
      >
        <mat-form-field
          class="formfield-width-100"
          *ngIf="activateVoucherForm.controls.number"
        >
          <mat-label>
            {{ 'activate.body.voucher-number' | translate }}
          </mat-label>
          <input
            formControlName="number"
            matInput
            required
            autocomplete="off"
          />
          <mat-error>
            {{ getError('number') }}
          </mat-error>
        </mat-form-field>
        <mat-form-field
          class="flex-class"
          *ngIf="activateVoucherForm.controls.name"
        >
          <mat-label>{{ 'input.name' | translate }}</mat-label>
          <input
            formControlName="name"
            matInput
            autocomplete="name"
          />
          <mat-hint *ngIf="!nameRequired">Dit veld is optioneel</mat-hint>
          <mat-error>
            {{ getError('name') }}
          </mat-error>
        </mat-form-field>
        <mat-form-field
          class="flex-class"
          *ngIf="activateVoucherForm.controls.identification"
        >
          <mat-label>{{ 'input.identification' | translate }}</mat-label>
          <input
            formControlName="identification"
            matInput
            required
            autocomplete="off"
          />
          <mat-error>
            {{ getError('identification') }}
          </mat-error>
        </mat-form-field>
        <mat-form-field
          class="formfield-width-100"
          *ngIf="activateVoucherForm.controls.phone"
        >
          <mat-label>{{ 'input.phone' | translate }}</mat-label>
          <input
            minlength="10"
            maxlength="10"
            formControlName="phone"
            matInput
            required
            autocomplete="tel-national"
            placeholder="0612345678"
          />
          <mat-error>
            {{ getError('phone') }}
          </mat-error>
        </mat-form-field>
        <div
          role="group"
          aria-label="adresgegevens"
        >
          <div
            class="disabled-inputs"
            *ngIf="
              this.data?.userData?.postal && this.data?.userData?.houseNumber
            "
          >
            <mat-form-field
              class="formfield-width-100"
              *ngIf="activateVoucherForm.controls.postal"
            >
              <mat-label>{{ 'input.postal' | translate }}</mat-label>
              <input
                pattern="[1-9][0-9]{3}[\s]?[A-Za-z]{2}"
                formControlName="postal"
                id="postal"
                matInput
                #postalCode
                required
                autocomplete="postal-code"
                readonly
                placeholder="1234AB"
              />
              <mat-error>
                {{ getError('postal') }}
              </mat-error>
            </mat-form-field>
            <mat-form-field
              class="house-number-input"
              *ngIf="activateVoucherForm.controls.houseNumber"
            >
              <mat-label>{{ 'input.houseNumber' | translate }}</mat-label>
              <input
                formControlName="houseNumber"
                matInput
                autocomplete="address-line2"
                required
                id="houseNumber"
                (keydown.space)="$event.preventDefault()"
                type="text"
                readonly
              />
              <mat-error>
                {{ getError('houseNumber') }}
              </mat-error>
            </mat-form-field>
            <mat-form-field
              class="house-number-addition-input"
              *ngIf="activateVoucherForm.controls.houseNumberAddition"
            >
              <mat-label>{{
                'input.houseNumberAddition' | translate
              }}</mat-label>
              <input
                formControlName="houseNumberAddition"
                matInput
                id="houseNumberAddition"
                autocomplete="off"
                (keydown.space)="$event.preventDefault()"
                readonly
              />
              <mat-hint>Dit veld is optioneel</mat-hint>
              <mat-error>
                {{ getError('houseNumberAddition') }}
              </mat-error>
            </mat-form-field>
          </div>
          <div
            class="enabledInputs"
            *ngIf="
              !this.data?.userData?.postal || !this.data?.userData?.houseNumber
            "
          >
            <mat-form-field
              class="formfield-width-100"
              *ngIf="activateVoucherForm.controls.postal"
            >
              <mat-label>{{ 'input.postal' | translate }}</mat-label>
              <input
                pattern="[1-9][0-9]{3}[\s]?[A-Za-z]{2}"
                formControlName="postal"
                matInput
                #postalCode
                required
                autocomplete="postal-code"
                placeholder="1234AB"
              />
              <mat-error>
                {{ getError('postal') }}
              </mat-error>
            </mat-form-field>
            <mat-form-field
              class="house-number-input"
              *ngIf="activateVoucherForm.controls.houseNumber"
            >
              <mat-label>{{ 'input.houseNumber' | translate }}</mat-label>
              <input
                formControlName="houseNumber"
                matInput
                autocomplete="address-line2"
                required
                (keydown.space)="$event.preventDefault()"
                type="text"
              />
              <mat-error>
                {{ getError('houseNumber') }}
              </mat-error>
            </mat-form-field>
            <mat-form-field
              class="house-number-addition-input"
              *ngIf="
                activateVoucherForm.controls.houseNumberAddition &&
                !(addresses | async)
              "
            >
              <mat-label>{{
                'input.houseNumberAddition' | translate
              }}</mat-label>
              <input
                formControlName="houseNumberAddition"
                matInput
                autocomplete="off"
                (keydown.space)="$event.preventDefault()"
              />
              <mat-hint>Dit veld is optioneel</mat-hint>
              <mat-error>
                {{ getError('houseNumberAddition') }}
              </mat-error>
            </mat-form-field>
            <mat-form-field
              class="house-number-addition-input"
              *ngIf="
                activateVoucherForm.controls.houseNumberAddition &&
                (addresses | async) as addresses
              "
            >
              <mat-label>{{
                'input.houseNumberAddition' | translate
              }}</mat-label>
              <mat-select formControlName="houseNumberAddition">
                <mat-option
                  *ngFor="let address of addresses"
                  [value]="address.houseNumberAddition"
                >
                  <span
                    *ngIf="!address.houseNumberAddition"
                    style="font-style: italic"
                    >Geen</span
                  >
                  <span *ngIf="address.houseNumberAddition">
                    {{ address.houseNumberAddition }}
                  </span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div
          class="fieldset"
          role="group"
          aria-label="bankgegevens"
          *ngIf="activateVoucherForm.controls.bankAccountName"
        >
          <mat-form-field class="formfield-width-100">
            <mat-label>{{
              'input.name-bank-account-holder' | translate
            }}</mat-label>
            <input
              formControlName="bankAccountName"
              matInput
              required
              autocomplete="name"
            />
            <mat-error>
              {{ getError('bankAccountName') }}
            </mat-error>
          </mat-form-field>
          <mat-form-field
            class="formfield-width-100"
            *ngIf="activateVoucherForm.controls.ibanNumber"
          >
            <mat-label>{{ 'input.iban-number' | translate }}</mat-label>
            <input
              formControlName="ibanNumber"
              matInput
              required
              autocomplete="off"
              placeholder="NL91ABNA0417164300"
            />
            <mat-error>
              {{ getError('ibanNumber') }}
            </mat-error>
          </mat-form-field>
        </div>
        <div
          class="fieldset"
          role="group"
          aria-label="email bevestigen"
          *ngIf="activateVoucherForm.controls.email"
        >
          <mat-form-field class="formfield-width-100">
            <mat-label>{{ 'input.email' | translate }}</mat-label>
            <input
              formControlName="email"
              matInput
              required
              (keydown.space)="$event.preventDefault()"
              autocomplete="email"
              placeholder="info@voorbeeld.nl"
            />
            <mat-error>
              {{ getError('email') }}
            </mat-error>
          </mat-form-field>
          <mat-form-field
            class="formfield-width-100"
            *ngIf="activateVoucherForm.controls.emailConfirm"
          >
            <mat-label>{{ 'input.confirm-email' | translate }}</mat-label>
            <input
              formControlName="emailConfirm"
              matInput
              required
              (keydown.space)="$event.preventDefault()"
              autocomplete="email"
              placeholder="info@voorbeeld.nl"
            />
            <mat-error>
              {{ getError('emailConfirm') }}
            </mat-error>
          </mat-form-field>
        </div>
        <div
          class="questionnaire"
          *ngIf="questionnaireEnabled"
        >
          <div class="questionnaire-title">Enquêtevraag</div>
          <form
            [formGroup]="questionnaireForm"
            aria-label="enquete formulier"
          >
            <div *ngFor="let questionData of questionnaireData | keyvalue">
              <div class="questionnaire-question">
                {{ questionData.value.question }}
              </div>
              <mat-form-field>
                <mat-label>Ik ben geinformeerd via</mat-label>
                <mat-select
                  [formControlName]="questionData.key"
                  (selectionChange)="
                    handleQuestionnaireQuestion(questionData.key, $event.value)
                  "
                >
                  <mat-option
                    *ngFor="
                      let answer of questionData.value.answers;
                      let i = index
                    "
                    [value]="i"
                  >
                    {{ answer }}
                  </mat-option>
                </mat-select>
                <mat-error>
                  {{ getError(questionData.key, questionnaireForm) }}
                </mat-error>
              </mat-form-field>
              <mat-form-field
                *ngIf="questionnaireForm.controls[questionData.key + 'Input']"
              >
                <mat-label>Anders, namelijk</mat-label>
                <input
                  cdkFocusInitial
                  [formControlName]="questionData.key + 'Input'"
                  autocomplete="off"
                  matInput
                />
                <mat-error>
                  {{ getError(questionData.key + 'Input', questionnaireForm) }}
                </mat-error>
              </mat-form-field>
            </div>
          </form>
        </div>
        <div
          *ngIf="voucherGroupData?.terms && !data?.termsAccepted"
          class="terms-title"
        >
          Voorwaarden
        </div>
        <mat-form-field
          *ngIf="voucherGroupData?.terms && !data?.termsAccepted"
          class="formfield-width-100"
          [class.voucher-terms]="availableVouchers > 0"
        >
          <mat-label>{{ 'activate.body.conditions' | translate }}</mat-label>
          <textarea
            matInput
            cdkTextareaAutosize
            cdkAutosizeMinRows="{{ this.termsRows }}"
            cdkAutosizeMaxRows="{{ this.termsRows }}"
            disabled="true"
            value="{{ voucherGroupData?.terms }}"
            readonly
          ></textarea>
        </mat-form-field>
        <div
          class="general-terms"
          *ngIf="!termsUrl && !data?.termsAccepted"
        >
          <div class="title">
            {{ 'find-voucher-group.body.terms_general' | translate }}
          </div>
          <app-general-terms></app-general-terms>
        </div>
        <div
          class="general-terms"
          *ngIf="termsUrl && !data?.termsAccepted"
        >
          <mat-label class="formfield-width-100">
            <span>{{ 'activate.body.terms.read-terms' | translate }} </span>
            <a
              href="{{ termsUrl }}"
              target="_blank"
              class="custom-tooltip terms-link-info"
              aria-label="algemene voorwaarden"
            >
              {{ 'activate.body.terms.terms-condition' | translate }}
            </a>
            <span>{{ 'activate.body.terms.here' | translate }}</span>
          </mat-label>
        </div>
        <mat-checkbox
          *ngIf="!data?.termsAccepted"
          class="terms-checkbox"
          formControlName="terms"
        >
          {{ 'activate.body.terms.accept' | translate }}
        </mat-checkbox>
      </form>
    </div>
    <div
      class="actions"
      role="navigation"
      *ngIf="!isEmbedded"
    >
      <button
        mat-dialog-close
        *ngIf="isDialog"
        mat-button
      >
        {{ 'activate.buttons.close' | translate }}
      </button>
      <div></div>
      <button
        [class.spinner]="awaitingResponse"
        (click)="submit()"
        *ngIf="!voucherGroupId"
        [disabled]="
          !activateVoucherForm.valid ||
          (questionnaireEnabled && !questionnaireForm.valid)
        "
        mat-flat-button
      >
        {{ 'activate.buttons.activate' | translate }}
      </button>
      <button
        [class.spinner]="awaitingResponse"
        (click)="submit()"
        *ngIf="voucherGroupData?.type == 'activate'"
        [disabled]="
          !activateVoucherForm.valid ||
          (questionnaireEnabled && !questionnaireForm.valid)
        "
        mat-flat-button
      >
        {{ 'activate.buttons.activate' | translate }}
      </button>
      <button
        [class.spinner]="awaitingResponse"
        (click)="submit()"
        *ngIf="voucherGroupData?.type == 'request'"
        [disabled]="
          !activateVoucherForm.valid ||
          (questionnaireEnabled && !questionnaireForm.valid)
        "
        mat-flat-button
      >
        {{ 'activate.buttons.request' | translate }}
      </button>
      <button
        [class.spinner]="awaitingResponse"
        (click)="submit()"
        *ngIf="voucherGroupData?.type == 'requestActivated'"
        [disabled]="
          !activateVoucherForm.valid ||
          (questionnaireEnabled && !questionnaireForm.valid)
        "
        mat-flat-button
      >
        {{ 'activate.buttons.request' | translate }}
      </button>
    </div>
    <div
      role="navigation"
      class="actions"
      *ngIf="isEmbedded && isPreperationForm"
    >
      <div></div>
      <button
        mat-flat-button
        class="spinner-button"
        (click)="sendPreFilledForm()"
        [disabled]="!activateVoucherForm.value.email || prefilledFormSent"
      >
        {{ 'activate.buttons.preparedForm' | translate }}
        <mat-spinner
          diameter="20"
          *ngIf="awaitingResponse"
        ></mat-spinner>
      </button>
    </div>
    <div
      class="actions"
      *ngIf="isEmbedded && !isPreperationForm"
    >
      <div></div>
      <button
        mat-flat-button
        class="spinner-button"
        (click)="submit()"
        *ngIf="!voucherGroupId"
        [disabled]="
          !activateVoucherForm.valid ||
          (questionnaireEnabled && !questionnaireForm.valid)
        "
      >
        {{ 'activate.buttons.activate' | translate }}
        <mat-spinner
          diameter="20"
          *ngIf="awaitingResponse"
        ></mat-spinner>
      </button>
      <button
        mat-flat-button
        class="spinner-button"
        (click)="submit()"
        *ngIf="voucherGroupData?.type == 'activate'"
        [disabled]="
          !activateVoucherForm.valid ||
          (questionnaireEnabled && !questionnaireForm.valid)
        "
      >
        {{ 'activate.buttons.activate' | translate }}
        <mat-spinner
          diameter="20"
          *ngIf="awaitingResponse"
        ></mat-spinner>
      </button>
      <button
        mat-flat-button
        class="spinner-button"
        (click)="submit()"
        *ngIf="voucherGroupData?.type == 'request'"
        [disabled]="
          !activateVoucherForm.valid ||
          (questionnaireEnabled && !questionnaireForm.valid)
        "
      >
        {{ 'activate.buttons.request' | translate }}
        <mat-spinner
          diameter="20"
          *ngIf="awaitingResponse"
        ></mat-spinner>
      </button>
      <button
        mat-flat-button
        class="spinner-button"
        (click)="submit()"
        *ngIf="voucherGroupData?.type == 'requestActivated'"
        [disabled]="
          !activateVoucherForm.valid ||
          (questionnaireEnabled && !questionnaireForm.valid)
        "
      >
        {{ 'activate.buttons.request' | translate }}
        <mat-spinner
          diameter="20"
          *ngIf="awaitingResponse"
        ></mat-spinner>
      </button>
    </div>
  </div>
</div>
<!-- </div> -->
<ng-template #loading>
  <div
    class="loading-wrapper"
    [ngClass]="activateType === 'passport' ? 'passport' : ''"
    [ngClass]="
      activateType === 'find-voucher-groups' ? 'find-voucher-groups' : ''
    "
  >
    <div class="loading">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</ng-template>
