import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

export interface DialogData {
  phonenumberSubmitted: boolean;
}
@Component({
  selector: 'app-verify-sms',
  templateUrl: './verify-sms.component.html',
  styleUrls: ['./verify-sms.component.scss'],
  standalone: false,
})
export class VerifySmsComponent implements OnInit {
  verifySmsForm: UntypedFormGroup;
  phonenumberSubmitted: boolean = false;
  highContrast: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<VerifySmsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private translate: TranslateService,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    this.verifySmsForm = this.fb.group({
      code: ['', [Validators.required]],
      phonenumber: ['', [Validators.required]],
    });

    if (
      localStorage.getItem('highContrast') &&
      localStorage.getItem('highContrast') === '1'
    ) {
      this.highContrast = true;
    }

    this.phonenumberSubmitted = this.data.phonenumberSubmitted;
  }

  getSnackbar(message: string) {
    let snackbarMessage = this.translate.instant(
      `snackbar-messages.${message}`
    ) as string;
    console.log('snackbarMessage', snackbarMessage);
    if (snackbarMessage.includes('snackbar-messages.')) {
      // translation not found, get oops error instead
      snackbarMessage = this.translate.instant(
        `snackbar-messages.something-went-wrong`
      ) as string;
    }
    this.snackbar.open(snackbarMessage, 'X', {
      duration: 12000,
    });
    return message;
  }

  submit() {
    this.dialogRef.close({
      code: this.verifySmsForm.value.code,
      phonenumber: this.verifySmsForm.value.phonenumber,
    });
  }
}
