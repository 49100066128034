/* src/app/passport/create-passport/create-passport.component.scss */
.page {
  min-height: calc(100vh - 56px);
  width: 100%;
}
.page .content {
  height: 100%;
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
}
.page .content .stepper-header {
  max-width: 1600px;
  margin: 0 auto 30px auto;
}
.page .content .stepper-header .your-home {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page .content .stepper-header .your-home .title {
  font-size: 22px;
  font-family: "Viga";
  margin-bottom: 16px;
  color: var(--primary);
}
.page .content .stepper-header .your-home .infos {
  display: flex;
  row-gap: 10px;
  column-gap: 20px;
  max-width: 580px;
  flex-wrap: wrap;
}
.page .content .stepper-header .your-home .infos div {
  width: 180px;
  font-weight: 500;
}
.page .content .stepper-header .progress {
  display: flex;
  row-gap: 15px;
  margin: 10px 0 0 0;
  flex-direction: column;
  --progress-outer-color: var(--primary);
  --progress-inner-color: var(--secondary);
}
.page .content .stepper-header .progress .text {
  font-size: 14px;
  color: var(--primary);
}
.page .content .stepper-header .progress progress {
  width: 100%;
  appearance: none;
  block-size: 3px;
  background-color: var(--progress-inner-color);
  border-radius: 5px;
}
.page .content .stepper-header .progress progress::-webkit-progress-bar {
  background-color: var(--progress-inner-color);
  border-radius: 5px;
}
.page .content .stepper-header .progress progress::-webkit-progress-value {
  background-color: var(--progress-outer-color);
  border-radius: 5px;
}
.page .content .stepper-header .progress progress::-moz-progress-bar {
  background-color: var(--progress-outer-color);
  border-radius: 5px;
}
.page .content .step .actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 0 0 0;
}
.page .content .step .house-type-form,
.page .content .step .sustainability-options-form,
.page .content .step .goal-form,
.page .content .step .verification-form,
.page .content .step .step-6-wrapper {
  min-height: calc(100vh - 444px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1600px;
  margin: 0 auto 0 auto;
}
.page .content .step .house-type-form .title-option-wrapper,
.page .content .step .sustainability-options-form .title-option-wrapper,
.page .content .step .goal-form .title-option-wrapper,
.page .content .step .verification-form .title-option-wrapper,
.page .content .step .step-6-wrapper .title-option-wrapper {
  max-width: 800px;
  margin: 0 auto 0 auto;
  width: 100%;
}
.page .content .step .house-type-form .title-option-wrapper .title,
.page .content .step .sustainability-options-form .title-option-wrapper .title,
.page .content .step .goal-form .title-option-wrapper .title,
.page .content .step .verification-form .title-option-wrapper .title,
.page .content .step .step-6-wrapper .title-option-wrapper .title {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 38px;
  font-family: "Viga";
  margin: 0 0 16px 0;
  color: var(--primary);
}
.page .content .step .house-type-form .title-option-wrapper .small-title,
.page .content .step .sustainability-options-form .title-option-wrapper .small-title,
.page .content .step .goal-form .title-option-wrapper .small-title,
.page .content .step .verification-form .title-option-wrapper .small-title,
.page .content .step .step-6-wrapper .title-option-wrapper .small-title {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  padding: 0 0 12px 0;
}
.page .content .step .house-type-form .title-option-wrapper .group-links-wrapper,
.page .content .step .sustainability-options-form .title-option-wrapper .group-links-wrapper,
.page .content .step .goal-form .title-option-wrapper .group-links-wrapper,
.page .content .step .verification-form .title-option-wrapper .group-links-wrapper,
.page .content .step .step-6-wrapper .title-option-wrapper .group-links-wrapper {
  margin: 24px 0 0 0;
}
.page .content .step .house-type-form .title-option-wrapper .group-links-wrapper .group-link-title,
.page .content .step .sustainability-options-form .title-option-wrapper .group-links-wrapper .group-link-title,
.page .content .step .goal-form .title-option-wrapper .group-links-wrapper .group-link-title,
.page .content .step .verification-form .title-option-wrapper .group-links-wrapper .group-link-title,
.page .content .step .step-6-wrapper .title-option-wrapper .group-links-wrapper .group-link-title {
  font-weight: 500;
  font-size: 20px;
}
.page .content .step .house-type-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card,
.page .content .step .sustainability-options-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card,
.page .content .step .goal-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card,
.page .content .step .verification-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card,
.page .content .step .step-6-wrapper .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card {
  min-height: 160px;
  box-sizing: border-box;
  cursor: pointer;
  border-color: white;
}
@media screen and (max-width: 625px) {
  .page .content .step .house-type-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card,
  .page .content .step .sustainability-options-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card,
  .page .content .step .goal-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card,
  .page .content .step .verification-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card,
  .page .content .step .step-6-wrapper .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card {
    flex-direction: column;
    max-width: 325px;
    margin: 12px auto 16px auto;
  }
}
.page .content .step .house-type-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .avatar,
.page .content .step .sustainability-options-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .avatar,
.page .content .step .goal-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .avatar,
.page .content .step .verification-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .avatar,
.page .content .step .step-6-wrapper .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .avatar {
  min-width: 120px;
}
@media screen and (max-width: 625px) {
  .page .content .step .house-type-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .avatar,
  .page .content .step .sustainability-options-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .avatar,
  .page .content .step .goal-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .avatar,
  .page .content .step .verification-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .avatar,
  .page .content .step .step-6-wrapper .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .avatar {
    height: 125px;
    width: 100%;
  }
}
.page .content .step .house-type-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .card-content .title-slogan,
.page .content .step .sustainability-options-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .card-content .title-slogan,
.page .content .step .goal-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .card-content .title-slogan,
.page .content .step .verification-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .card-content .title-slogan,
.page .content .step .step-6-wrapper .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .card-content .title-slogan {
  margin: 0;
}
.page .content .step .house-type-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .card-content .title-slogan .title,
.page .content .step .sustainability-options-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .card-content .title-slogan .title,
.page .content .step .goal-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .card-content .title-slogan .title,
.page .content .step .verification-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .card-content .title-slogan .title,
.page .content .step .step-6-wrapper .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .card-content .title-slogan .title {
  display: block;
  text-align: left;
  font-size: 22px;
  color: var(--primary);
}
.page .content .step .house-type-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .card-content .voucher-value-wrapper,
.page .content .step .sustainability-options-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .card-content .voucher-value-wrapper,
.page .content .step .goal-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .card-content .voucher-value-wrapper,
.page .content .step .verification-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .card-content .voucher-value-wrapper,
.page .content .step .step-6-wrapper .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .card-content .voucher-value-wrapper {
  margin: 12px 0 0 0;
}
.page .content .step .house-type-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .card-content .voucher-value-wrapper .voucher-value,
.page .content .step .house-type-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .card-content .voucher-value-wrapper .alt-coupon,
.page .content .step .sustainability-options-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .card-content .voucher-value-wrapper .voucher-value,
.page .content .step .sustainability-options-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .card-content .voucher-value-wrapper .alt-coupon,
.page .content .step .goal-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .card-content .voucher-value-wrapper .voucher-value,
.page .content .step .goal-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .card-content .voucher-value-wrapper .alt-coupon,
.page .content .step .verification-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .card-content .voucher-value-wrapper .voucher-value,
.page .content .step .verification-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .card-content .voucher-value-wrapper .alt-coupon,
.page .content .step .step-6-wrapper .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .card-content .voucher-value-wrapper .voucher-value,
.page .content .step .step-6-wrapper .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .card-content .voucher-value-wrapper .alt-coupon {
  color: var(--primary);
}
.page .content .step .house-type-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .selected-voucher-group-card,
.page .content .step .sustainability-options-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .selected-voucher-group-card,
.page .content .step .goal-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .selected-voucher-group-card,
.page .content .step .verification-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .selected-voucher-group-card,
.page .content .step .step-6-wrapper .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .selected-voucher-group-card {
  border-color: var(--primary);
}
.page .content .step .house-type-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .separator,
.page .content .step .sustainability-options-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .separator,
.page .content .step .goal-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .separator,
.page .content .step .verification-form .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .separator,
.page .content .step .step-6-wrapper .title-option-wrapper .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .separator {
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
}
.page .content .step .house-type-form .title-input-wrapper,
.page .content .step .sustainability-options-form .title-input-wrapper,
.page .content .step .goal-form .title-input-wrapper,
.page .content .step .verification-form .title-input-wrapper,
.page .content .step .step-6-wrapper .title-input-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto 0 auto;
}
.page .content .step .house-type-form .title-input-wrapper .title,
.page .content .step .sustainability-options-form .title-input-wrapper .title,
.page .content .step .goal-form .title-input-wrapper .title,
.page .content .step .verification-form .title-input-wrapper .title,
.page .content .step .step-6-wrapper .title-input-wrapper .title {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 38px;
  font-family: "Viga";
  margin: 0 0 16px 0;
}
.page .content .step .house-type-form .title-input-wrapper .small-title-no-sub,
.page .content .step .sustainability-options-form .title-input-wrapper .small-title-no-sub,
.page .content .step .goal-form .title-input-wrapper .small-title-no-sub,
.page .content .step .verification-form .title-input-wrapper .small-title-no-sub,
.page .content .step .step-6-wrapper .title-input-wrapper .small-title-no-sub {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  margin: 0 0 36px 0;
}
.page .content .step .house-type-form .title-input-wrapper .pin-info::after,
.page .content .step .sustainability-options-form .title-input-wrapper .pin-info::after,
.page .content .step .goal-form .title-input-wrapper .pin-info::after,
.page .content .step .verification-form .title-input-wrapper .pin-info::after,
.page .content .step .step-6-wrapper .title-input-wrapper .pin-info::after {
  content: "U kunt uw pincode vinden in de door u ontvangen brief.";
}
.page .content .step .house-type-form .title-cards-wrapper .title,
.page .content .step .sustainability-options-form .title-cards-wrapper .title,
.page .content .step .goal-form .title-cards-wrapper .title,
.page .content .step .verification-form .title-cards-wrapper .title,
.page .content .step .step-6-wrapper .title-cards-wrapper .title {
  font-size: 38px;
  font-family: "Viga";
  margin: 0 0 16px 0;
  color: var(--primary);
}
.page .content .step .house-type-form .title-cards-wrapper .small-title,
.page .content .step .sustainability-options-form .title-cards-wrapper .small-title,
.page .content .step .goal-form .title-cards-wrapper .small-title,
.page .content .step .verification-form .title-cards-wrapper .small-title,
.page .content .step .step-6-wrapper .title-cards-wrapper .small-title {
  font-size: 22px;
  font-weight: 500;
}
.page .content .step .house-type-form .title-cards-wrapper .small-title-no-sub,
.page .content .step .sustainability-options-form .title-cards-wrapper .small-title-no-sub,
.page .content .step .goal-form .title-cards-wrapper .small-title-no-sub,
.page .content .step .verification-form .title-cards-wrapper .small-title-no-sub,
.page .content .step .step-6-wrapper .title-cards-wrapper .small-title-no-sub {
  font-size: 22px;
  font-weight: 500;
  margin: 0 0 36px 0;
}
.page .content .step .house-type-form .title-cards-wrapper .sub-title,
.page .content .step .sustainability-options-form .title-cards-wrapper .sub-title,
.page .content .step .goal-form .title-cards-wrapper .sub-title,
.page .content .step .verification-form .title-cards-wrapper .sub-title,
.page .content .step .step-6-wrapper .title-cards-wrapper .sub-title {
  margin: 36px 0 0 0;
  font-size: 20px;
  font-weight: 500;
}
.page .content .step .house-type-form .title-cards-wrapper .cards-wrapper,
.page .content .step .sustainability-options-form .title-cards-wrapper .cards-wrapper,
.page .content .step .goal-form .title-cards-wrapper .cards-wrapper,
.page .content .step .verification-form .title-cards-wrapper .cards-wrapper,
.page .content .step .step-6-wrapper .title-cards-wrapper .cards-wrapper {
  max-width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}
.page .content .step .house-type-form .title-cards-wrapper .cards-wrapper .card,
.page .content .step .sustainability-options-form .title-cards-wrapper .cards-wrapper .card,
.page .content .step .goal-form .title-cards-wrapper .cards-wrapper .card,
.page .content .step .verification-form .title-cards-wrapper .cards-wrapper .card,
.page .content .step .step-6-wrapper .title-cards-wrapper .cards-wrapper .card {
  height: 220px;
  width: 200px;
  min-width: 200px;
  border: 1px solid #656d71;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  background-color: #f3f1e9;
  --active-border-color: var(--primary);
  cursor: pointer;
}
@media screen and (max-width: 515px) {
  .page .content .step .house-type-form .title-cards-wrapper .cards-wrapper .card,
  .page .content .step .sustainability-options-form .title-cards-wrapper .cards-wrapper .card,
  .page .content .step .goal-form .title-cards-wrapper .cards-wrapper .card,
  .page .content .step .verification-form .title-cards-wrapper .cards-wrapper .card,
  .page .content .step .step-6-wrapper .title-cards-wrapper .cards-wrapper .card {
    width: 100% !important;
  }
}
.page .content .step .house-type-form .title-cards-wrapper .cards-wrapper .card .card-type-image,
.page .content .step .sustainability-options-form .title-cards-wrapper .cards-wrapper .card .card-type-image,
.page .content .step .goal-form .title-cards-wrapper .cards-wrapper .card .card-type-image,
.page .content .step .verification-form .title-cards-wrapper .cards-wrapper .card .card-type-image,
.page .content .step .step-6-wrapper .title-cards-wrapper .cards-wrapper .card .card-type-image {
  background-color: white;
  height: 165px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
}
.page .content .step .house-type-form .title-cards-wrapper .cards-wrapper .card .card-type-image mat-icon,
.page .content .step .sustainability-options-form .title-cards-wrapper .cards-wrapper .card .card-type-image mat-icon,
.page .content .step .goal-form .title-cards-wrapper .cards-wrapper .card .card-type-image mat-icon,
.page .content .step .verification-form .title-cards-wrapper .cards-wrapper .card .card-type-image mat-icon,
.page .content .step .step-6-wrapper .title-cards-wrapper .cards-wrapper .card .card-type-image mat-icon {
  height: 165px;
  width: 200px;
  fill: var(--primary);
}
.page .content .step .house-type-form .title-cards-wrapper .cards-wrapper .card .card-type-text,
.page .content .step .sustainability-options-form .title-cards-wrapper .cards-wrapper .card .card-type-text,
.page .content .step .goal-form .title-cards-wrapper .cards-wrapper .card .card-type-text,
.page .content .step .verification-form .title-cards-wrapper .cards-wrapper .card .card-type-text,
.page .content .step .step-6-wrapper .title-cards-wrapper .cards-wrapper .card .card-type-text {
  padding: 12px 12px 24px 12px;
  font-weight: 500;
  font-size: 14px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.page .content .step .house-type-form .title-cards-wrapper .cards-wrapper .card .card-type-sub-text,
.page .content .step .sustainability-options-form .title-cards-wrapper .cards-wrapper .card .card-type-sub-text,
.page .content .step .goal-form .title-cards-wrapper .cards-wrapper .card .card-type-sub-text,
.page .content .step .verification-form .title-cards-wrapper .cards-wrapper .card .card-type-sub-text,
.page .content .step .step-6-wrapper .title-cards-wrapper .cards-wrapper .card .card-type-sub-text {
  font-weight: 400;
  font-size: 13px;
  padding: 0px 12px 2px 12px;
}
.page .content .step .house-type-form .title-cards-wrapper .cards-wrapper .card.level,
.page .content .step .sustainability-options-form .title-cards-wrapper .cards-wrapper .card.level,
.page .content .step .goal-form .title-cards-wrapper .cards-wrapper .card.level,
.page .content .step .verification-form .title-cards-wrapper .cards-wrapper .card.level,
.page .content .step .step-6-wrapper .title-cards-wrapper .cards-wrapper .card.level {
  height: auto;
}
.page .content .step .house-type-form .title-cards-wrapper .cards-wrapper .card.level .card-type-text,
.page .content .step .sustainability-options-form .title-cards-wrapper .cards-wrapper .card.level .card-type-text,
.page .content .step .goal-form .title-cards-wrapper .cards-wrapper .card.level .card-type-text,
.page .content .step .verification-form .title-cards-wrapper .cards-wrapper .card.level .card-type-text,
.page .content .step .step-6-wrapper .title-cards-wrapper .cards-wrapper .card.level .card-type-text {
  padding: 12px 12px 18px 12px;
}
.page .content .step .house-type-form .title-cards-wrapper .cards-wrapper .card.active,
.page .content .step .sustainability-options-form .title-cards-wrapper .cards-wrapper .card.active,
.page .content .step .goal-form .title-cards-wrapper .cards-wrapper .card.active,
.page .content .step .verification-form .title-cards-wrapper .cards-wrapper .card.active,
.page .content .step .step-6-wrapper .title-cards-wrapper .cards-wrapper .card.active {
  height: 216px;
  width: 196px;
  min-width: 196px;
  border: 3px solid;
  border-color: var(--active-border-color);
}
.page .content .step .house-type-form .title-cards-wrapper .cards-wrapper .card.active .card-type-image,
.page .content .step .sustainability-options-form .title-cards-wrapper .cards-wrapper .card.active .card-type-image,
.page .content .step .goal-form .title-cards-wrapper .cards-wrapper .card.active .card-type-image,
.page .content .step .verification-form .title-cards-wrapper .cards-wrapper .card.active .card-type-image,
.page .content .step .step-6-wrapper .title-cards-wrapper .cards-wrapper .card.active .card-type-image {
  height: 163px;
}
.page .content .step .house-type-form .title-cards-wrapper .cards-wrapper .card.active .card-type-image mat-icon,
.page .content .step .sustainability-options-form .title-cards-wrapper .cards-wrapper .card.active .card-type-image mat-icon,
.page .content .step .goal-form .title-cards-wrapper .cards-wrapper .card.active .card-type-image mat-icon,
.page .content .step .verification-form .title-cards-wrapper .cards-wrapper .card.active .card-type-image mat-icon,
.page .content .step .step-6-wrapper .title-cards-wrapper .cards-wrapper .card.active .card-type-image mat-icon {
  margin: -2px 0 0 0;
}
.page .content .step .house-type-form .title-cards-wrapper .cards-wrapper .card.active .card-type-text,
.page .content .step .sustainability-options-form .title-cards-wrapper .cards-wrapper .card.active .card-type-text,
.page .content .step .goal-form .title-cards-wrapper .cards-wrapper .card.active .card-type-text,
.page .content .step .verification-form .title-cards-wrapper .cards-wrapper .card.active .card-type-text,
.page .content .step .step-6-wrapper .title-cards-wrapper .cards-wrapper .card.active .card-type-text {
  padding: 12px 10px 16px 10px;
}
.page .content .step .house-type-form .title-cards-wrapper .cards-wrapper .card.active .card-type-sub-text,
.page .content .step .sustainability-options-form .title-cards-wrapper .cards-wrapper .card.active .card-type-sub-text,
.page .content .step .goal-form .title-cards-wrapper .cards-wrapper .card.active .card-type-sub-text,
.page .content .step .verification-form .title-cards-wrapper .cards-wrapper .card.active .card-type-sub-text,
.page .content .step .step-6-wrapper .title-cards-wrapper .cards-wrapper .card.active .card-type-sub-text {
  padding: 0px 10px 0px 10px;
}
.page .content .step .house-type-form .title-cards-wrapper .cards-wrapper .card.level.active,
.page .content .step .sustainability-options-form .title-cards-wrapper .cards-wrapper .card.level.active,
.page .content .step .goal-form .title-cards-wrapper .cards-wrapper .card.level.active,
.page .content .step .verification-form .title-cards-wrapper .cards-wrapper .card.level.active,
.page .content .step .step-6-wrapper .title-cards-wrapper .cards-wrapper .card.level.active {
  height: auto;
  max-height: 100%;
}
.page .content .step .house-type-form .title-cards-wrapper .cards-wrapper .card.level.active .card-type-text,
.page .content .step .sustainability-options-form .title-cards-wrapper .cards-wrapper .card.level.active .card-type-text,
.page .content .step .goal-form .title-cards-wrapper .cards-wrapper .card.level.active .card-type-text,
.page .content .step .verification-form .title-cards-wrapper .cards-wrapper .card.level.active .card-type-text,
.page .content .step .step-6-wrapper .title-cards-wrapper .cards-wrapper .card.level.active .card-type-text {
  padding: 12px 10px 18px 10px;
}
.page .content .step .step-6-wrapper {
  min-height: calc(100vh - 276px);
}
.page .content .step .verification-form {
  justify-content: center;
}
.page .content .step .voucher-group-details {
  width: 100%;
  max-width: 600px;
  padding: 0 8px;
  margin: auto;
}
.page .content .step .voucher-group-details .top-title {
  font-size: 38px;
  font-family: "Viga";
  text-align: center;
  margin: 0 0 24px 0;
  color: var(--primary);
}
.page .content .step .voucher-group-details .avatar {
  width: 100%;
  min-width: 100%;
  height: 160px;
  border-radius: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.page .content .step .voucher-group-details .avatar.image {
  height: 0 !important;
  padding-top: 70%;
}
.page .content .step .voucher-group-details .avatar.placeholder {
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  align-items: center;
  font-weight: 550;
  font-size: 50px;
  background-color: var(--primary);
  color: var(--primary-contrast);
  -webkit-user-select: none;
  user-select: none;
}
.page .content .step .voucher-group-details .slogan-desc {
  color: #1c1c18;
  white-space: pre-line;
  margin: 10px 0 0 0;
}
.page .content .step .voucher-group-details .slogan-desc .slogan {
  margin: 0 0 10px 0;
}
.page .content .step .voucher-group-details .slogan-desc .description {
  font-size: 14px;
}
.page .content .step .voucher-group-details .terms .title {
  font-size: 16px;
  color: var(--primary);
}
.page .content .step .voucher-group-details .terms .voucher-group-terms {
  font-size: 12px;
  white-space: pre-line;
}
.page .content .step .voucher-group-details .terms .general-terms {
  margin: 16px 0;
  border: solid #888 1px;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
}
.page .content .step .voucher-group-details .terms .general-terms .title {
  font-weight: 500;
  margin-bottom: 16px;
}
.page .content .step .voucher-group-details .terms .general-terms .terms-link-info::after {
  width: 210px;
  content: "Door op deze link te klikken wordt er een nieuw tabblad geopend.";
}
.page .content .step .floating-back-button-wrapper {
  width: 100%;
  max-width: 590px;
  margin: auto;
}
.page .content .step .floating-back-button-wrapper .floating-back-button {
  position: relative;
  pointer-events: none;
  bottom: 48px;
}
.page .content .step .floating-back-button-wrapper .floating-back-button button {
  pointer-events: auto;
}
.page .content .step-0 {
  display: flex;
  width: 100%;
}
@media screen and (max-width: 850px) {
  .page .content .step-0 {
    flex-direction: column-reverse;
  }
}
@media screen and (min-width: 851px) {
  .page .content .step-0 {
    height: calc(100vh - 276px);
  }
}
.page .content .step-0 .form-wrapper {
  min-width: 300px;
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 851px) {
  .page .content .step-0 .form-wrapper {
    overflow-y: auto;
    overflow-x: hidden;
  }
}
.page .content .step-0 .form-wrapper .enter-address-form {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;
  max-width: 600px;
  margin: 0 auto 0 auto;
}
.page .content .step-0 .form-wrapper .enter-address-form .step-0-title {
  font-size: 38px;
  font-weight: 500;
  font-family: "Viga";
  color: var(--primary);
}
@media screen and (max-width: 850px) {
  .page .content .step-0 .form-wrapper .enter-address-form .step-0-title {
    font-size: 30px;
  }
}
.page .content .step-0 .form-wrapper .enter-address-form .step-0-subtitle {
  color: #191c1a;
  font-size: 18px;
  margin: 20px 0 16px 0;
}
.page .content .step-0 .form-wrapper .enter-address-form mat-form-field {
  width: 100%;
}
.page .content .step-0 .form-wrapper .enter-address-form .half-fields {
  width: 100%;
  display: flex;
  gap: 10px;
}
.page .content .step-0 .form-wrapper .enter-address-form .actions {
  display: flex;
  justify-content: flex-end;
}
.page .content .step-0 .address-image-wrapper {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0 60px 0;
}
@media screen and (max-width: 850px) {
  .page .content .step-0 .address-image-wrapper {
    padding: 0 0 10px 0;
  }
}
.page .content .step-0 .address-image-wrapper .address-image {
  background-image: url(https://firebasestorage.googleapis.com/v0/b/wemaron-vouchers.appspot.com/o/img%2Fbanners%2FGroeneBonBanner.png?alt=media&token=d749efe8-0549-4261-a25f-a8f0df394439);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 100%;
  width: 100%;
  min-height: 250px;
}
.page .content .step-8 .title {
  font-size: 38px;
  font-family: "Viga";
  margin: 0 0 16px 0;
  text-align: center;
  color: var(--primary);
}
.page .content .step-8 .small-title-no-sub {
  font-size: 22px;
  font-weight: 500;
  text-align: center;
}
/*# sourceMappingURL=create-passport.component.css.map */
