<h1
  mat-dialog-title
  [ngStyle]="
    highContrast ? { 'filter': 'saturate(0)' } : { 'filter': 'saturate(1)' }
  "
>
  {{ 'verify.pin_title' | translate }}
</h1>
<div
  mat-dialog-content
  [ngStyle]="
    highContrast ? { 'filter': 'saturate(0)' } : { 'filter': 'saturate(1)' }
  "
>
  <form
    class="verify-form"
    (ngSubmit)="submit()"
    [formGroup]="verifyPinForm"
  >
    <div style="margin-bottom: 10px">{{ 'verify.pin_info' | translate }}</div>
    <mat-form-field>
      <mat-label>{{ 'verify.code' | translate }}</mat-label>
      <input
        cdkFocusInitial
        placeholder="1234"
        formControlName="code"
        autocomplete="off"
        matInput
        required
        (keydown.space)="$event.preventDefault()"
      />
      <mat-error *ngIf="verifyPinForm.controls.code.hasError('required')">
        {{ 'errors.required' | translate }}
      </mat-error>
    </mat-form-field>
  </form>
</div>
<mat-dialog-actions
  align="end"
  [ngStyle]="
    highContrast ? { 'filter': 'saturate(0)' } : { 'filter': 'saturate(1)' }
  "
>
  <button
    mat-button
    class="cancel-button"
    mat-dialog-close
  >
    {{ 'verify.cancel' | translate }}
  </button>
  <button
    [disabled]="verifyPinForm.invalid"
    mat-flat-button
    class="submit-button"
    (click)="submit()"
  >
    {{ 'verify.submit' | translate }}
  </button>
</mat-dialog-actions>
