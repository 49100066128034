import { Township, TownshipInfo, TownshipPublicSettings } from './interfaces';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import tinycolor from 'tinycolor2';
import { ThemeColors } from 'src/app/interfaces';

export function hexToRgb(hex: string, addOpacityValue?: number) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result) {
    const rgb = {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    };
    // console.log('rgb', rgb);
    let rgbString = addOpacityValue ? 'rgba(' : 'rgb(';
    rgbString = rgbString + `${rgb.r},${rgb.g},${rgb.b}`;
    rgbString = rgbString + (addOpacityValue ? `,${addOpacityValue})` : ')');
    // console.log('rgbString', rgbString);
    return {
      rgb,
      rgbString,
    };
  } else {
    return null;
  }
}

export function calculateTextColor(color: string) {
  const aRGBHex = color.replace('#', '').match(/.{1,2}/g);
  const RGB =
    1 -
    (0.299 * parseInt(aRGBHex[0], 16) +
      0.733 * parseInt(aRGBHex[1], 16) +
      0.114 * parseInt(aRGBHex[2], 16)) /
      255;

  return RGB < 0.45 ? '#000000' : '#FFFFFF';
}

export function decodeRouteParam(param: string) {
  const temp = decodeURIComponent(param);
  return window.atob(temp);
}

export function getCurrencyString(number: number) {
  let newNumber;
  if (!number.toString().includes('.')) {
    // this isnt a decimal
    newNumber = `${number.toString()},-`;
  } else {
    newNumber = `${number.toFixed(2)}`;
    newNumber = newNumber.replace('.', ',');
  }
  return newNumber;
}

export function getHeaderImageUrl(voucherPrefix: string): string {
  let headerImageUrl: string;
  switch (voucherPrefix) {
    case 'groene':
      headerImageUrl = '../../assets/images/groenebon.png';
      break;
    case 'toegangs':
      headerImageUrl = '../../assets/images/toegangsbon.png';
      break;
    case 'duurzaamwonen':
      headerImageUrl = '../../assets/images/duurzaamwonen.png';
      break;
    default:
      headerImageUrl = '../../assets/images/lokalebon.png';
      break;
  }
  return headerImageUrl;
}

export function fixTermsUrl(termsUrl: string): string {
  let fixedUrl: string;
  if (termsUrl.includes('https://')) {
    fixedUrl = termsUrl;
  } else if (termsUrl.includes('http://')) {
    fixedUrl = termsUrl.replace('http://', 'https://');
  } else {
    fixedUrl = 'https://' + termsUrl;
  }
  return fixedUrl;
}

export function getEnvName(voucherPrefix: string) {
  let envName;
  switch (voucherPrefix) {
    case 'groene':
      envName = { value: 'Groene Bon' };
      break;
    case 'toegangs':
      envName = { value: 'Toegangsbon' };
      break;
    case 'duurzaamwonen':
      envName = { value: 'Duurzaam Wonen Bon' };
      break;
    default:
      envName = { value: 'Lokale Bon' };
      break;
  }
  return envName;
}

export async function getTownshipInfo(options: {
  townshipName?: string;
  townshipId?: string;
}) {
  let townshipName = options.townshipName;
  let townshipId = options.townshipId;
  if (townshipName && !townshipId) {
    const townshipNameDoc = (
      await getDoc(doc(getFirestore(), `townshipNames/${townshipName}`))
    ).data();
    console.log('townshipNameDoc', townshipNameDoc);
    if (!townshipNameDoc.townshipId) {
      return;
    }
    townshipId = townshipNameDoc.townshipId;
  }
  if (!townshipId) {
    return;
  }
  const townshipRef = doc(
    getFirestore(),
    `township/${townshipId}/settings/public`
  );
  const township = (await getDoc(townshipRef)).data() as TownshipPublicSettings;
  townshipName = township.name;
  let townshipLogo: string = getHeaderImageUrl(township.voucherPrefix);
  if (township.logoImageUrl) {
    townshipLogo = township.logoImageUrl;
  }

  return {
    townshipId,
    township,
    townshipName,
    townshipLogo,
  } as TownshipInfo;
}

export async function getTownshipColors(township) {
  let theme = {
    primary: { background: '#006239', text: '#FFFFFF' },
    accent: { background: '#913a00', text: '#FFFFFF' },
    image: '../../assets/images/groenebon.png',
  };

  if (township) {
    let image: any;
    if (township.logoImageUrl) {
      image = township.logoImageUrl;
    } else {
      switch (township.voucherPrefix) {
        case 'lokale':
          image = '../../assets/images/lokalebon.png';
          break;
        case 'toegangs':
          image = '../../assets/images/toegangsbon.png';
          break;
        case 'duurzaamwonen':
          image = '../../assets/images/duurzaamwonen.png';
          break;
        default:
          image = '../../assets/images/groenebon.png';
          break;
      }
    }
    theme = {
      primary: {
        background: township.primaryColor ?? '#006239',
        text: calculateTextColor(township.primaryColor ?? '#006239'),
      },
      accent: {
        background: township.accentColor ?? '#913a00',
        text: calculateTextColor(township.accentColor ?? '#913a00'),
      },
      image: image ?? '../../assets/images/groenebon.png',
    };
  }
  const rootVariables = [
    { name: '--primary', value: theme.primary.background },
    { name: '--primary-contrast', value: theme.primary.text },
    { name: '--accent', value: theme.accent.background },
    { name: '--accent-contrast', value: theme.accent.text },
  ];
  for (const data of rootVariables) {
    document.documentElement.style.setProperty(data.name, data.value);
  }
  return theme;
}

export async function getTermsUrl(voucherGroupUrl, townshipUrl) {
  let termsUrl = null;
  if (voucherGroupUrl) {
    termsUrl = fixTermsUrl(voucherGroupUrl);
  } else if (townshipUrl) {
    const termsLowerCase = townshipUrl.toLocaleLowerCase();
    if (
      !termsLowerCase.includes('groeneapp') &&
      !termsLowerCase.includes('groenebon')
    ) {
      termsUrl = fixTermsUrl(townshipUrl);
    }
  }
  return termsUrl;
}

export function ucFirst(string: string) {
  return String(string).charAt(0).toUpperCase() + String(string).slice(1);
}

export function getTownshipImage(township: TownshipPublicSettings) {
  let image: any;
  if (township.logoImageUrl) {
    image = township.logoImageUrl;
  } else {
    switch (township.voucherPrefix) {
      case 'lokale':
        image = '../../assets/images/lokalebon.png';
        break;
      case 'toegangs':
        image = '../../assets/images/toegangsbon.png';
        break;
      case 'duurzaamwonen':
        image = '../../assets/images/duurzaamwonen.png';
        break;
      default:
        image = '../../assets/images/groenebon.png';
        break;
    }
  }
  return image;
}

export function updateTheme(themeColors: ThemeColors) {
  const palettes = Object.keys(themeColors);
  for (const palette of palettes) {
    if (themeColors[palette]) {
      // generate the shades of the palette, based on the base color of the theme palette
      const colors = computeColors(themeColors[palette]);
      console.log(themeColors[palette]);
      // set colors on all root variables of the palette that the dynamic theme uses
      for (const color of colors) {
        document.documentElement.style.setProperty(
          `--${palette}-${color.name}`,
          color.hex
        );
      }
      // set the root variables for the main color and contrast color of the palette. the contrast variable is used by the dynamic theme to fix some incorrect contrast colors.
      const mainColor = colors.find((color) => color.name === '40');
      document.documentElement.style.setProperty(`--${palette}`, mainColor.hex);
      document.documentElement.style.setProperty(
        `--${palette}-contrast`,
        mainColor.darkContrast ? 'black' : 'white'
      );
    }
  }
  updateRippleColors();
}

function computeColors(hex: string): Color[] {
  return [
    getColorObject(tinycolor(hex).darken(30), '10'),
    getColorObject(tinycolor(hex).darken(20), '20'),
    getColorObject(tinycolor(hex).darken(15), '25'),
    getColorObject(tinycolor(hex).darken(10), '30'),
    getColorObject(tinycolor(hex).darken(5), '35'),
    getColorObject(tinycolor(hex), '40'),
    getColorObject(tinycolor(hex).lighten(10), '50'),
    getColorObject(tinycolor(hex).lighten(20), '60'),
    getColorObject(tinycolor(hex).lighten(30), '70'),
    getColorObject(tinycolor(hex).lighten(40), '80'),
    getColorObject(tinycolor(hex).lighten(50), '90'),
    getColorObject(tinycolor(hex).lighten(55), '95'),
    getColorObject(tinycolor(hex).lighten(58), '98'),
    getColorObject(tinycolor(hex).lighten(59), '99'),
  ];
}

function getColorObject(value, name): Color {
  const c = tinycolor(value);
  return {
    name: name,
    hex: c.toHexString(),
    darkContrast: c.isLight(),
  };
}

function updateRippleColors() {
  // update Angular ripple variables that do not have rgba when using a dynamic theme, despite needing rgba for the opacity
  const rippleVariables = [
    '--mat-fab-ripple-color',
    '--mat-fab-small-ripple-color',
    '--mat-protected-button-ripple-color',
    '--mat-text-button-ripple-color',
    '--mat-outlined-button-ripple-color',
  ];
  rippleVariables.forEach((rippleVariable) => {
    const rgba = hexToRgba(
      window.getComputedStyle(document.body).getPropertyValue(rippleVariable),
      0.12
    );
    if (rgba) {
      document.body.style.setProperty(
        rippleVariable,
        `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`
      );
    }
  });
}

function hexToRgba(hex, opacity) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        a: opacity,
      }
    : null;
}

export interface Color {
  name: string;
  hex: string;
  darkContrast: boolean;
}
