// For testing the REGEX's and making new ones: https://regex101.com
// IBAN Information: https://www.iban.com/structure LAST CHECKED 09-02-2024
// Swift Codes: https://bank.codes/swift-code/
export class CustomValidators {
  static cannotContainSpace(c) {
    return cannotContainSpaceValidator(c);
  }
  static numberInput(minus, variableDecimal, decimal) {
    return numberInputValidator(minus, variableDecimal, decimal);
  }
  static email(c) {
    // return emailValidator(c);
    return emailValidator();
  }
  static phone(c) {
    return phoneValidator(c);
  }
  static postal(c) {
    return postalValidator(c);
  }
  static streetName(c) {
    return streetNameValidator(c);
  }
  static iban(c) {
    return ibanValidator(c);
  }
  static bic(c) {
    return bicValidator(c);
  }
  static requiredFileType(type) {
    return requiredFileTypeValidator(type);
  }
  static maxValue(maxValue) {
    return maxValueValidator(maxValue);
  }
  static alphaNumericOnly(c) {
    return alphaNumericOnlyValidator(c);
  }
  static arrayNotEmpty(c) {
    return arrayNotEmptyValidator(c);
  }
}
function isEmptyInputValue(value) {
  return value == null || (typeof value === 'string' || Array.isArray(value)) && value.length === 0;
}
function cannotContainSpaceValidator(c) {
  const REGEX = /[\s]/g;
  if (isEmptyInputValue(c.value)) {
    return null;
  }
  return REGEX.test(c.value) ? {
    cannotContainSpace: true
  } : null;
}
export function numberInputValidator(minus, variableDecimal, decimal) {
  return c => {
    const REGEX = new RegExp(`^(-{${minus ? 1 : 0}})?[0-9]+([.,][0-9]{${variableDecimal ? '1,' : ''}${decimal}})?$`);
    //if minus === true and decimal === 2 the regex will return true on these formats:
    // -123.00
    // -123,00
    // -123
    // 123.00
    // 123,00
    // 123
    //if minus === false and decimal === 2 the regex will return true on these formats:
    //123,00
    //123.00
    //123
    //if minus === false and decimal === 0 the regex will return true on these formats:
    //123
    if (isEmptyInputValue(c.value)) {
      return null;
    }
    return REGEX.test(c.value.toString()) ? null : {
      numberInput: true
    };
  };
}
export function emailValidator() {
  return c => {
    const REGEX = /^([a-zA-Z0-9]+(?:[._+-][a-zA-Z0-9]+)*)@([a-z0-9]+(?:[.-][a-z0-9]+)*\.[a-z]{2,})$/;
    // all the email formats this regex will return true on:
    //test@mail.nl
    //test@mail.com
    //test@mail.co.uk
    //test@mail.de
    //T.est@mail.nl
    //test+test@mail.nl
    //T.est+test@mail.co.uk
    if (isEmptyInputValue(c.value)) {
      return null;
    }
    return REGEX.test(c.value) ? null : {
      emailValidator: true
    };
  };
}
// function emailValidator(c: AbstractControl): ValidationErrors | null {
//   const REGEX =
//     /^([a-zA-Z0-9]+(?:[._+-][a-zA-Z0-9]+)*)@([a-z0-9]+(?:[.-][a-z0-9]+)*\.[a-z]{2,})$/;
//   // all the email formats this regex will return true on:
//   //test@mail.nl
//   //test@mail.com
//   //test@mail.co.uk
//   //test@mail.de
//   //T.est@mail.nl
//   //test+test@mail.nl
//   //T.est+test@mail.co.uk
//   if (isEmptyInputValue(c.value)) {
//     return null;
//   }
//   return REGEX.test(c.value)
//     ? null
//     : {
//         emailValidator: true,
//       };
// }
function phoneValidator(c) {
  const REGEX = /^((\+|00(s|s?-s?)?)31(s|s?-s?)?((0)[-s]?)?|0)[1-9]((s|s?-s?|\s)?[0-9])((s|s?-s?)?[0-9])((\s|s?-s?)?[0-9])((\s|s?-s?)?[0-9])((\s|s?-s?)?[0-9])s?[0-9]((\s|s?-s?)?[0-9])s?[0-9]$/;
  // all the phone number formats this regex will return true on:
  // 06 123 456 78
  // 06-123 456 78
  // 06-123-456-78
  // 06 12 34 56 78
  // 06-12 34 56 78
  // 06-12-34-56-78
  // 06 12345678
  // 06-12345678
  // 0612345678
  // +316 123 456 78
  // +316-108 275 59
  // +316-108-275-59
  // +316 12 34 56 78
  // +316-12 34 56 78
  // +316-12-34-56-78
  // +316 12345678
  // +316-12345678
  // +31612345678
  if (isEmptyInputValue(c.value)) {
    return null;
  }
  return REGEX.test(c.value) ? null : {
    phoneValidator: true
  };
}
function postalValidator(c) {
  const REGEX = /^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/;
  // all the postal formats this regex will return true on:
  // 1000 AA
  // 1000AA
  // 1000 Aa
  // 1000Aa
  // 1000 aA
  // 1000aA
  // 1000 aa
  // 1000aa
  if (isEmptyInputValue(c.value)) {
    return null;
  }
  return REGEX.test(c.value) ? null : {
    postalValidator: true
  };
}
function streetNameValidator(c) {
  const REGEX = /^([1-9][e][\s])?((?:[a-zA-Z][\.])(?:[\s]?))*([a-zA-Z-\s\']+)$/;
  // all the street name formats this regex will return true on:
  // A.A. de Lannoy-Willemsstraat
  // van der Duyn van Maasdamstraat
  // 1e Glanshof
  // 1e Kekerstraat
  // A. Eversplein
  // A.A.H. Struijckenkade
  // Aakhof
  if (isEmptyInputValue(c.value)) {
    return null;
  }
  return REGEX.test(c.value) ? null : {
    streetNameValidator: true
  };
}
function alphaNumericOnlyValidator(c) {
  const REGEX = /^([A-Za-z0-9]*)$/;
  return REGEX.test(c.value) ? null : {
    alphaNumericOnlyValidator: true
  };
}
function ibanValidator(c) {
  // all the iban formats this regex will return true on:
  // RU0204452560040702810412345678901
  // RU02 0445 2560 0407 0281 0412 3456 7890 1
  // DE75512108001245126199
  // DE75 5121 0800 1245 1261 99
  // NL02ABNA0123456789
  // NL02 ABNA 0123 4567 89
  // NO8330001234567
  // NO83 3000 1234 567
  // BE71096123456769
  // BE71 0961 2345 6769
  //FOR VERSION 3, IBAN checksum validation.
  // https://www.ibantest.com/en/how-is-the-iban-check-digit-calculated
  // https://stackoverflow.com/questions/21928083/iban-validation-check
  if (isEmptyInputValue(c.value)) {
    return null;
  }
  let FourDigitGroupings = 2;
  let lastDigitGrouping = true;
  const countryCode = c.value.substring(0, 2).toUpperCase();
  const countryWithIbanLength15 = ['NO'];
  const countryWithIbanLength16 = ['BE'];
  const countryWithIbanLength18 = ['FO', 'GL', 'DK', 'FI', 'NL', 'SD', 'FK'];
  const countryWithIbanLength19 = ['MK', 'SI'];
  const countryWithIbanLength20 = ['AT', 'BA', 'EE', 'KZ', 'XK', 'LT', 'LU', 'MN'];
  const countryWithIbanLength21 = ['HR', 'LV', 'LI', 'CH'];
  const countryWithIbanLength22 = ['BH', 'BG', 'CR', 'GE', 'DE', 'IE', 'ME', 'RS', 'GB', 'VA'];
  const countryWithIbanLength23 = ['GI', 'IL', 'TL', 'AE', 'IQ', 'SO'];
  const countryWithIbanLength24 = ['AD', 'CZ', 'MD', 'PK', 'RO', 'SA', 'SK', 'ES', 'SE', 'TN', 'VG'];
  const countryWithIbanLength25 = ['AO', 'CV', 'GW', 'LY', 'MZ', 'PT', 'ST'];
  const countryWithIbanLength26 = ['DZ', 'IR', 'IS', 'TR'];
  const countryWithIbanLength27 = ['BI', 'CF', 'CG', 'CM', 'DJ', 'FR', 'GA', 'GQ', 'GR', 'IT', 'KM', 'MC', 'MG', 'MR', 'SM', 'TD'];
  const countryWithIbanLength28 = ['AL', 'AZ', 'BF', 'BJ', 'BY', 'CI', 'CY', 'DO', 'GT', 'HU', 'HN', 'LB', 'MA', 'ML', 'NE', 'PL', 'SN', 'SV', 'TG', 'NI'];
  const countryWithIbanLength29 = ['BR', 'EG', 'PS', 'QA', 'UA'];
  const countryWithIbanLength30 = ['JO', 'KW', 'MU'];
  const countryWithIbanLength31 = ['MT', 'SC'];
  const countryWithIbanLength32 = ['LC'];
  const countryWithIbanLength33 = ['RU'];
  switch (countryCode) {
    case getCountryCodeLength(countryWithIbanLength15, countryCode):
      FourDigitGroupings = 1;
      lastDigitGrouping = true;
      break;
    case getCountryCodeLength(countryWithIbanLength16, countryCode):
      FourDigitGroupings = 2;
      lastDigitGrouping = false;
      break;
    case getCountryCodeLength(countryWithIbanLength18, countryCode):
      FourDigitGroupings = 2;
      lastDigitGrouping = true;
      break;
    case getCountryCodeLength(countryWithIbanLength19, countryCode):
      FourDigitGroupings = 2;
      lastDigitGrouping = true;
      break;
    case getCountryCodeLength(countryWithIbanLength20, countryCode):
      FourDigitGroupings = 3;
      lastDigitGrouping = false;
      break;
    case getCountryCodeLength(countryWithIbanLength21, countryCode):
      FourDigitGroupings = 3;
      lastDigitGrouping = true;
      break;
    case getCountryCodeLength(countryWithIbanLength22, countryCode):
      FourDigitGroupings = 3;
      lastDigitGrouping = true;
      break;
    case getCountryCodeLength(countryWithIbanLength23, countryCode):
      FourDigitGroupings = 3;
      lastDigitGrouping = true;
      break;
    case getCountryCodeLength(countryWithIbanLength24, countryCode):
      FourDigitGroupings = 4;
      lastDigitGrouping = false;
      break;
    case getCountryCodeLength(countryWithIbanLength25, countryCode):
      FourDigitGroupings = 4;
      lastDigitGrouping = true;
      break;
    case getCountryCodeLength(countryWithIbanLength26, countryCode):
      FourDigitGroupings = 4;
      lastDigitGrouping = true;
      break;
    case getCountryCodeLength(countryWithIbanLength27, countryCode):
      FourDigitGroupings = 4;
      lastDigitGrouping = true;
      break;
    case getCountryCodeLength(countryWithIbanLength28, countryCode):
      FourDigitGroupings = 5;
      lastDigitGrouping = false;
      break;
    case getCountryCodeLength(countryWithIbanLength29, countryCode):
      FourDigitGroupings = 5;
      lastDigitGrouping = true;
      break;
    case getCountryCodeLength(countryWithIbanLength30, countryCode):
      FourDigitGroupings = 5;
      lastDigitGrouping = true;
      break;
    case getCountryCodeLength(countryWithIbanLength31, countryCode):
      FourDigitGroupings = 5;
      lastDigitGrouping = true;
      break;
    case getCountryCodeLength(countryWithIbanLength32, countryCode):
      FourDigitGroupings = 6;
      lastDigitGrouping = false;
      break;
    case getCountryCodeLength(countryWithIbanLength33, countryCode):
      FourDigitGroupings = 6;
      lastDigitGrouping = true;
      break;
    default:
      break;
  }
  const REGEX = new RegExp(`^([a-zA-Z]{2})([0-9]{2})\\s?([a-zA-Z0-9]{4})((\\s?[0-9]{4}){${FourDigitGroupings}})((\\s?[0-9]{1,3})){${lastDigitGrouping ? 1 : 0}}$`);
  if (REGEX.test(c.value)) {
    const ibanSeparated = c.value.toUpperCase().replace(/[^A-Z0-9]/g, '').match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
    const lettersToNumbers = (ibanSeparated[3] + ibanSeparated[1] + '00').replace(/[A-Z]/g, function (letter) {
      return letter.charCodeAt(0) - 55;
    });
    let checksum = String(98 - Number(BigInt(lettersToNumbers) % 97n));
    checksum = checksum.length == 1 ? '0' + checksum : checksum;
    const REGEXCheckSum = new RegExp(`^([a-zA-Z]{2})(${checksum})\\s?([a-zA-Z0-9]{4})((\\s?[0-9]{4}){${FourDigitGroupings}})((\\s?[0-9]{1,3})){${lastDigitGrouping ? 1 : 0}}$`);
    return REGEXCheckSum.test(c.value) ? null : {
      ibanValidator: true
    };
  } else {
    return {
      ibanValidator: true
    };
  }
}
function getCountryCodeLength(array, item) {
  return array.includes(item) ? item : 'none found';
}
function bicValidator(c) {
  const REGEX = /^[a-zA-Z]{6}[a-zA-Z0-9]{2}([a-zA-Z0-9]{3})?$/;
  // all the BIC formats this regex will return true on:
  // AAAABBCCDDD
  // RABONL2U
  if (isEmptyInputValue(c.value)) {
    return null;
  }
  return REGEX.test(c.value) ? null : {
    bicValidator: true
  };
}
function maxValueValidator(maxValue) {
  return c => {
    const value = c.value;
    if (isEmptyInputValue(c.value)) {
      return null;
    }
    return value > maxValue ? {
      maxValue: true
    } : null;
  };
}
export function requiredFileTypeValidator(type) {
  return c => {
    let file = c.value;
    const requiredType = type.toLowerCase();
    if (file) {
      file = file.name.split('.')[1].toLowerCase();
    } else {
      return null;
    }
    return requiredType !== file ? {
      requiredFileType: true
    } : null;
  };
}
function arrayNotEmptyValidator(c) {
  return !c.value || c.value.length == 0 ? {
    arrayNotEmpty: true
  } : null;
}
