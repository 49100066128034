<!-- To use a transparent background with this iframe, use <iframe src="stuff.htm" allowtransparency="true"></iframe> -->
<div
  class="wrapper"
  [ngStyle]="
    highContrast ? { 'filter': 'saturate(0)' } : { 'filter': 'saturate(1)' }
  "
  *ngIf="loaded; else loading"
  role="main"
>
  <div
    class="wrapper-bg"
    role="banner"
    *ngIf="step === 1"
  >
    <div class="header-wrapper">
      <div
        class="header-img"
        style="background-image:url('{{ townshipLogo }}');"
      ></div>
      <div class="header-text">
        <div class="title">
          {{ townshipName }}
        </div>
      </div>
    </div>
  </div>
  <mat-toolbar
    class="toolbar"
    *ngIf="step > 1"
  >
    <div class="logo-env-wrapper">
      <div
        class="header-logo"
        style="background-image:url('{{ envLogo }}');"
      ></div>
      <div class="header-text">{{ townshipName }}</div>
    </div>
  </mat-toolbar>
  <div class="content-wrapper">
    <div
      class="step-1"
      *ngIf="step === 1"
    >
      <div *ngTemplateOutlet="progress"></div>
      <div class="card">
        <div class="title">Bewijs uitvoering verduurzaming woning</div>
        <div class="title-subtext">Campagne informatie</div>
        <div *ngTemplateOutlet="info"></div>
      </div>
      <div class="card">
        <div class="title">Informatie</div>
        <div>Beste inwoner,</div>
        <div>
          Onlangs heeft u materialen aangeschaft of werkzaamheden laten
          uitvoeren voor de verduurzaming van uw woning. Hiervoor is subsidie
          voor u gereserveerd. Wij vragen u om te bewijzen dat de juiste
          werkzaamheden en / of materialen zijn toegepast in uw woning. Daarvoor
          dient dit formulier. De uiterste datum dat het formulier ingevuld kan
          worden vind u bovenaan dit formulier.
        </div>
        <div class="subtitle">Waarom vragen wij foto's en bewijs?</div>
        <div>
          U moet bewijzen dat de subsidie is gebruikt voor het verduurzamen van
          uw woning. Dit doet u met foto's.
        </div>
        <div>
          Let op: maak alleen foto's van wat nodig is en zorg dat er geen
          gevoelige informatie zichtbaar is.
        </div>
        <ul class="card-list">
          <li class="card-list-item">
            Maak foto's <b>voor</b> de werkzaamheden om te laten zien waar de
            maatregel wordt toegepast.
          </li>
          <li class="card-list-item">
            Maak foto's <b>tijdens</b> de werkzaamheden om te laten zien hoe de
            producten worden verwerkt.
          </li>
          <li class="card-list-item">
            Maak foto's <b>na</b> de werkzaamheden om te bewijzen dat ze klaar
            zijn.
          </li>
        </ul>
        <div>
          U heeft tot de genoemde inleverdatum om deze informatie aan te
          leveren. De gemeente kan controleren of de producten goed zijn
          verwerkt in uw woning. Als dit niet zo is, kan de subsidie worden
          ingehouden of teruggevorderd.
        </div>
        <div class="subtitle">Let op!</div>
        <div>
          Markeer het formulier pas als <b>'compleet'</b> wanneer alle
          energiebesparende maatregelen zijn aangeschaft en/of uitgevoerd. Zijn
          nog niet alle werkzaamheden uitgevoerd óf heeft u nog niet alle
          materialen aangeschaft? Kies dan de optie "niet compleet" zodat het
          formulier aangevuld kan worden.
        </div>
      </div>
      <form
        [formGroup]="infoForm"
        aria-label="informatie gelezen checkbox"
      >
        <mat-checkbox
          class="info-checkbox"
          formControlName="acceptInfo"
        >
          Ik heb de informatie gelezen en ga hiermee akkoord
        </mat-checkbox>
      </form>
      <div class="actions">
        <button
          mat-flat-button
          (click)="step = step + 1"
          [disabled]="!formValid()"
        >
          Volgende stap
        </button>
      </div>
    </div>
    <div
      class="page"
      *ngIf="step > 1"
    >
      <div class="voucher-info">
        <div class="info-header">
          <div
            class="logo-img"
            style="background-image:url('{{ townshipLogo }}');"
          ></div>
          <div class="title info-title">{{ townshipName }}</div>
        </div>
        <div class="separator-line"></div>
        <div class="title">Bewijs uitvoering verduurzaming woning</div>
        <div class="title-subtext">Campagne informatie</div>
        <div *ngTemplateOutlet="info"></div>
      </div>
      <div class="steps">
        <div *ngTemplateOutlet="progress"></div>
        <div class="step-wrapper">
          <div class="step-content">
            <div
              class="step step-2"
              *ngIf="step === 2"
            >
              <div class="normal-title">
                Geef hieronder aan welk geregistreerd energielabel u heeft.
                Heeft u geen geregistreerd energielabel of energielabel C? Kies
                dan minstens twee slecht geïsoleerde bouwdelen van uw woning
                (volgens de voorwaarden).
              </div>
              <form
                [formGroup]="energyLabelForm"
                aria-label="energielabel formulier"
              >
                <mat-form-field>
                  <mat-label>Label</mat-label>
                  <mat-select
                    formControlName="energyLabel"
                    (selectionChange)="handleEnergyLabelChange($event.value)"
                  >
                    <mat-option
                      *ngFor="let label of energyLabels"
                      [value]="label"
                      >{{ label }}</mat-option
                    >
                  </mat-select>
                  <mat-error>{{
                    getValidatorErrorMessage(energyLabelForm, 'energyLabel')
                  }}</mat-error>
                </mat-form-field>
                <div class="normal-title">
                  {{
                    hasBadEnergyLabel
                      ? 'Kies minstens twee bouwdelen die slecht geïsoleerd zijn:'
                      : 'Vink slecht geïsoleerde bouwdelen aan (optioneel):'
                  }}
                </div>
                <div class="checkbox-list">
                  <div
                    *ngFor="
                      let part of badlyInsulatedParts | keyvalue : valueAscOrder
                    "
                  >
                    <mat-checkbox
                      [checked]="
                        energyLabelForm.value.badlyInsulatedParts.includes(
                          part.key
                        )
                      "
                      [disabled]="
                        status === 'Submitted' || status === 'Accepted'
                      "
                      (change)="handleCheckboxChange($event, part.key)"
                    >
                      {{ part.value }}
                    </mat-checkbox>
                  </div>
                </div>
              </form>
            </div>
            <div
              class="step step-3"
              *ngIf="step === 3"
            >
              <form [formGroup]="selectedSubsidyOptionsForm">
                <div class="normal-title">
                  Waarvoor heeft u de gekochte producten gebruikt?
                </div>
                <div class="subsidy-options checkbox-list">
                  <div *ngFor="let option of subsidyOptionsInVoucherGroup">
                    <mat-checkbox
                      (change)="updateSubsidyOption($event.checked, option)"
                      [checked]="subsidyOptionIsChecked(option.name)"
                      [disabled]="saving || status === 'Submitted'"
                    >
                      {{ option.name }}
                    </mat-checkbox>
                    <div
                      *ngIf="
                        selectedSubsidyOptionsForm.controls[
                          option.name + 'MainUnit'
                        ]
                      "
                    >
                      <mat-radio-group
                        formControlName="{{ option.name }}DIY"
                        [disabled]="
                          status === 'Submitted' || status === 'Accepted'
                        "
                      >
                        <mat-radio-button [value]="true"
                          >Ik heb het zelf uitgevoerd</mat-radio-button
                        >
                        <mat-radio-button [value]="false"
                          >Ik heb het door een professional laten
                          uitvoeren</mat-radio-button
                        >
                      </mat-radio-group>
                    </div>
                    <div
                      class="subsidy-option-wrapper"
                      *ngIf="
                        selectedSubsidyOptionsForm.controls[
                          option.name + 'MainUnit'
                        ]
                      "
                    >
                      <mat-form-field
                        class="subsidy-option-form-field"
                        [hideRequiredMarker]="false"
                        *ngIf="option.name !== 'Warmtepomp'"
                      >
                        <mat-label>{{
                          ucFirst(option.mainUnitLabel)
                        }}</mat-label>
                        <input
                          formControlName="{{ option.name }}MainUnit"
                          autocomplete="off"
                          data-lpignore="true"
                          matInput
                        />
                        <mat-error>{{
                          getValidatorErrorMessage(
                            selectedSubsidyOptionsForm,
                            option.name + 'MainUnit',
                            option.mainUnitLabel
                          )
                        }}</mat-error>
                      </mat-form-field>
                      <mat-form-field
                        class="subsidy-option-form-field"
                        [hideRequiredMarker]="option.name !== 'Warmtepomp'"
                        *ngIf="option.code"
                      >
                        <mat-label>Meldcode</mat-label>
                        <input
                          formControlName="{{ option.name }}Code"
                          autocomplete="off"
                          data-lpignore="true"
                          matInput
                        />
                        <mat-error>{{
                          getValidatorErrorMessage(
                            selectedSubsidyOptionsForm,
                            option.name + 'Code'
                          )
                        }}</mat-error>
                      </mat-form-field>
                      <mat-form-field
                        class="subsidy-option-form-field"
                        *ngIf="
                          option.secondaryUnit && option.name !== 'Warmtepomp'
                        "
                      >
                        <mat-label>{{ option.secondaryUnitLabel }}</mat-label>
                        <input
                          formControlName="{{ option.name }}SecondaryUnit"
                          autocomplete="off"
                          data-lpignore="true"
                          matInput
                        />
                        <mat-error>{{
                          getValidatorErrorMessage(
                            selectedSubsidyOptionsForm,
                            option.name + 'SecondaryUnit',
                            option.secondaryUnitLabel
                          )
                        }}</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div
              class="step step-4"
              *ngIf="step === 4"
            >
              <div class="normal-title">
                Upload hier uw aankoopbewijs, zoals een kassabon of factuur
              </div>
              U kunt tot 5 bestanden uploaden, met een maximum van 10MB per
              bestand.
              <form
                class="selected-files-wrapper"
                [formGroup]="receiptsForm"
              >
                <div *ngFor="let receipt of receipts">
                  <mat-chip-listbox class="selected-file-wrapper">
                    <mat-chip (removed)="removeFile(receipt, 'receipts')">
                      {{ receipt.name }}
                      <button
                        *ngIf="status !== 'Submitted'"
                        matChipRemove
                        [disabled]="saving"
                      >
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </mat-chip>
                  </mat-chip-listbox>
                  <mat-form-field
                    *ngIf="receiptsForm.controls[receipt.name]"
                    class="receipt-form-field"
                    [hideRequiredMarker]="false"
                  >
                    <mat-label>Aankoopwaarde</mat-label>
                    <input
                      [formControlName]="receipt.name"
                      autocomplete="off"
                      data-lpignore="true"
                      placeholder="150,00"
                      matInput
                    />
                    <mat-error>{{
                      getValidatorErrorMessage(
                        receiptsForm,
                        receipt.name,
                        'Aankoopwaarde'
                      )
                    }}</mat-error>
                  </mat-form-field>
                </div>

                <input
                  class="hidden-file-input-button"
                  type="file"
                  id="receipts"
                  (change)="selectedFiles($event, 'receipts')"
                  accept="image/png, image/jpeg, application/pdf"
                  multiple="true"
                />
                <button
                  *ngIf="status !== 'Submitted'"
                  mat-flat-button
                  class="upload-button secondary-color"
                  (click)="openFileInput('receipts')"
                  [disabled]="saving || status === 'Submitted'"
                >
                  <mat-icon>upload</mat-icon>
                  Aankoopbewijs toevoegen
                </button>
              </form>
            </div>
            <div
              class="step step-5"
              *ngIf="step === 5"
            >
              <div
                *ngTemplateOutlet="
                  imageStep;
                  context: {
                    titleBoldText: 'voor',
                    imagePath:
                      '../../assets/images/burden-of-proof/example-photo-before.jpg',
                    pictures: picturesBefore,
                    picturesName: 'picturesBefore'
                  }
                "
              ></div>
            </div>
            <div
              class="step step-6"
              *ngIf="step === 6"
            >
              <div
                *ngTemplateOutlet="
                  imageStep;
                  context: {
                    titleBoldText: 'tijdens',
                    imagePath:
                      '../../assets/images/burden-of-proof/example-photo-during.jpg',
                    pictures: picturesDuring,
                    picturesName: 'picturesDuring'
                  }
                "
              ></div>
            </div>
            <div
              class="step step-7"
              *ngIf="step === 7"
            >
              <div
                *ngTemplateOutlet="
                  imageStep;
                  context: {
                    titleBoldText: 'na',
                    imagePath:
                      '../../assets/images/burden-of-proof/example-photo-after.jpg',
                    pictures: picturesAfter,
                    picturesName: 'picturesAfter'
                  }
                "
              ></div>
            </div>
            <div
              class="step step-8"
              *ngIf="step === 8"
            >
              <div class="title">
                Heeft u alles correct en volledig ingevuld?
              </div>
              <div class="normal-title">
                U kunt uw antwoorden nog aanpassen totdat u ‘Ja’ kiest. Kies
                alleen ‘Ja’ als u klaar bent met alle isolerende maatregelen. U
                kunt maar één formulier opsturen.
              </div>
              <form [formGroup]="completedForm">
                <mat-checkbox formControlName="complete">
                  Ja, mijn antwoorden zijn compleet en correct. Ik ga akkoord
                  met de voorwaarden.
                </mat-checkbox>
              </form>
              <div>
                Wanneer u op ‘Verzenden’ klikt, ontvangt u een bevestiging op
                het e-mailadres dat u heeft gebruikt voor de aanvraag. Wilt u
                later verder gaan? Sla uw formulier dan op als concept.
              </div>
            </div>
          </div>
          <div class="actions">
            <button
              class="web-button"
              mat-stroked-button
              (click)="
                step === 5 && !this.voucherGroup.cashback
                  ? (step = step - 2)
                  : (step = step - 1)
              "
              [disabled]="saving"
            >
              Vorige stap
            </button>
            <button
              class="phone-button"
              mat-icon-button
              (click)="
                step === 5 && !this.voucherGroup.cashback
                  ? (step = step - 2)
                  : (step = step - 1)
              "
              [disabled]="saving"
            >
              <mat-icon>arrow_back</mat-icon>
            </button>
            <div class="button-group-end">
              <button
                *ngIf="status !== 'Submitted'"
                mat-stroked-button
                class="web-button"
                (click)="submit('Concept')"
                [disabled]="saving"
              >
                <mat-icon>bookmark</mat-icon>
                Concept opslaan
              </button>
              <button
                *ngIf="status !== 'Submitted'"
                mat-icon-button
                class="phone-button"
                (click)="submit('Concept')"
                [disabled]="saving"
              >
                <mat-icon>bookmark</mat-icon>
              </button>
              <button
                *ngIf="step !== 8"
                class="web-button"
                mat-flat-button
                (click)="
                  step === 3 && !this.voucherGroup.cashback
                    ? (step = step + 2)
                    : (step = step + 1)
                "
                [disabled]="saving || !formValid()"
              >
                Volgende stap
              </button>
              <button
                *ngIf="step !== 8"
                class="phone-button"
                mat-icon-button
                (click)="
                  step === 3 && !this.voucherGroup.cashback
                    ? (step = step + 2)
                    : (step = step + 1)
                "
                [disabled]="saving || !formValid()"
              >
                <mat-icon>arrow_forward</mat-icon>
              </button>
              <button
                *ngIf="step === 8 && status !== 'Submitted'"
                mat-flat-button
                class="submit-button"
                (click)="submit('Submitted')"
                [disabled]="saving || !formValid()"
              >
                Verzenden
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #info>
  <div class="column-wrapper">
    <div class="column">
      <div class="info-wrapper">
        <div class="info-label">Bonnummer</div>
        <div>{{ voucher.number }}</div>
      </div>
      <div class="info-wrapper">
        <div class="info-label">Beschikbaar bedrag</div>
        <div>€{{ getCurrencyString(voucher.value) }}</div>
      </div>
      <div
        class="info-wrapper"
        *ngIf="voucher.originalValue - voucher.value > 0"
      >
        <div class="info-label">Geclaimd bedrag</div>
        <div>
          €{{ getCurrencyString(voucher.originalValue - voucher.value) }}
        </div>
      </div>
      <div
        class="info-wrapper"
        *ngIf="voucher.burdenOfProofFillInBeforeDate"
      >
        <div class="info-label">Invullen voor</div>
        <div>
          {{
            voucher.burdenOfProofFillInBeforeDate | date : 'dd-MM-yyyy HH:mm'
          }}
        </div>
      </div>
      <div
        class="info-wrapper"
        *ngIf="
          !voucher.burdenOfProofFillInBeforeDate &&
          voucherGroup.burdenOfProofUntilDate
        "
      >
        <div class="info-label">Invullen voor</div>
        <div>
          {{ voucherGroup.burdenOfProofUntilDate | date : 'dd-MM-yyyy HH:mm' }}
        </div>
      </div>
    </div>
    <div class="column">
      <div class="info-wrapper">
        <div class="info-label">Naam</div>
        <div>{{ voucher.name }}</div>
      </div>
      <div class="info-wrapper">
        <div class="info-label">Adresgegevens</div>
        <div>
          {{ voucher.postal }} {{ voucher.houseNumber
          }}{{ voucher.houseNumberAddition }}
        </div>
      </div>
      <div class="info-wrapper">
        <div class="info-label">E-mailadres</div>
        <div>{{ voucher.email }}</div>
      </div>
    </div>
  </div>
</ng-template>
<div *ngIf="saving">
  <div *ngTemplateOutlet="loading"></div>
</div>

<ng-template #progress>
  <div class="progress">
    <progress
      title="progress"
      [value]="step > 3 && !this.voucherGroup.cashback ? step - 1 : step"
      [max]="!this.voucherGroup.cashback ? 7 : 8"
    ></progress>
  </div>
</ng-template>

<ng-template
  #imageStep
  let-titleBoldText="titleBoldText"
  let-imagePath="imagePath"
  let-pictures="pictures"
  let-picturesName="picturesName"
>
  <div class="normal-title">
    Upload hier foto's van de situatie <b>{{ titleBoldText }}</b> de uitvoering
    van de maatregelen.
  </div>
  U kunt tot 5 bestanden uploaden, met een maximum van 10MB per bestand.
  <div class="normal-title">Voorbeeldfoto</div>
  <div class="example-image">
    <img [src]="imagePath" />
  </div>
  <form
    class="selected-files-wrapper"
    [formGroup]="receiptsForm"
  >
    <div class="normal-title">Mijn foto's</div>
    <div class="upload-preview-wrapper">
      <div
        class="upload-preview-options"
        *ngFor="let picture of pictures"
      >
        <button
          *ngIf="status !== 'Submitted'"
          class="delete-option"
          mat-icon-button
          (click)="removeFile(picture, picturesName)"
          [disabled]="saving"
        >
          <mat-icon>delete</mat-icon>
        </button>
        <div
          class="upload-preview"
          [ngStyle]="{
            'background-image':
              'url(' +
              (picture.downloadUrl ? picture.downloadUrl : picture.previewUrl) +
              ')'
          }"
        ></div>
      </div>
    </div>
    <input
      class="hidden-file-input-button"
      type="file"
      [id]="picturesName"
      (change)="selectedFiles($event, picturesName)"
      accept="image/png, image/jpeg"
      multiple="true"
    />
    <button
      *ngIf="status !== 'Submitted'"
      mat-flat-button
      class="upload-button secondary-color"
      (click)="openFileInput(picturesName)"
      [disabled]="saving || status === 'Submitted'"
    >
      <mat-icon>upload</mat-icon>
      Foto's uploaden
    </button>
  </form>
</ng-template>

<ng-template #loading>
  <div class="loading-wrapper">
    <div class="loading">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</ng-template>
