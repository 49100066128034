import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { TownshipPublicSettings, VoucherGroup } from '../interfaces';
import {
  doc,
  getDoc,
  getDocs,
  query,
  where,
  collection,
  onSnapshot,
} from 'firebase/firestore';
import { getHeaderImageUrl, updateTheme } from '../globals';
import { db } from '../app.component';

@Component({
  selector: 'app-group-link',
  templateUrl: './group-link.component.html',
  styleUrls: ['./group-link.component.scss'],
  standalone: false,
})
export class GroupLinkComponent implements OnInit {
  groupLink: string;
  townshipId: string;

  township: TownshipPublicSettings;
  townshipRef: any;
  headerImg: string;

  groupLinkForm: UntypedFormGroup;
  voucherGroups: VoucherGroup[] = [];

  waiting = false;
  groupLinkTxt: string;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.subscribe(async (params) => {
      this.groupLink = params.groupLink;
      this.townshipId = params.townshipId;
      console.log('groupLink', this.groupLink);

      this.township = (
        await getDoc(doc(db, `township/${this.townshipId}/settings/public`))
      ).data() as TownshipPublicSettings;

      if (this.township.logoImageUrl) {
        this.headerImg = this.township.logoImageUrl;
      } else {
        this.headerImg = getHeaderImageUrl(this.township.voucherPrefix);
      }
      updateTheme({
        primary: this.township.primaryColor,
        secondary: this.township.accentColor,
      });

      const voucherGroupsQuery = query(
        collection(db, `township/${this.townshipId}/voucherGroups`),
        where('groupLink', '==', this.groupLink)
      );

      onSnapshot(voucherGroupsQuery, (querySnapshot) => {
        const newVoucherGroups = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data() as VoucherGroup;
          data.id = doc.id;
          newVoucherGroups.push(data);
        });

        console.log('newVoucherGroups', newVoucherGroups);
        const updatedVoucherGroups = [];

        newVoucherGroups.forEach((voucherGroup: VoucherGroup) => {
          const updatedVoucherGroup = voucherGroup as any;
          console.log('location', location);
          let url = environment.iframeUrl;
          if (voucherGroup.type === 'sell') {
            url += 'buy/';
          } else {
            url += 'activate/';
          }
          url += `${this.townshipId}/${voucherGroup.id}`;
          updatedVoucherGroup.url = url;
          updatedVoucherGroups.push(updatedVoucherGroup);
        });

        this.voucherGroups = updatedVoucherGroups;

        newVoucherGroups.forEach(async (voucherGroup) => {
          if (voucherGroup.groupLinkTxt && !this.groupLinkTxt) {
            this.groupLinkTxt = voucherGroup.groupLinkTxt;
          }
          if (!voucherGroup.groupLinkTxt && !this.groupLinkTxt) {
            const q = query(
              collection(db, `township/${this.townshipId}/groupLinks`),
              where('name', '==', this.groupLink)
            );
            const allGroupLinks = await getDocs(q);
            allGroupLinks.forEach((groupLink) => {
              if (groupLink.data().text) {
                this.groupLinkTxt = groupLink.data().text;
              }
            });
          }
        });
      });
    });
  }

  sortBy(name: string) {
    return this.voucherGroups.sort((voucherGroupA, voucherGroupB) =>
      voucherGroupA[name] > voucherGroupB[name]
        ? 1
        : voucherGroupA[name] === voucherGroupB[name]
        ? 0
        : -1
    );
  }

  async navigate(voucherGroupId) {
    this.waiting = true;
    console.log('voucherGroupId', voucherGroupId);

    const voucherGroupRef = doc(
      db,
      `township/${this.townshipId}/voucherGroups/`,
      `${voucherGroupId}`
    );
    const voucherGroup = (await getDoc(voucherGroupRef)).data() as VoucherGroup;
    console.log('voucherGroup', voucherGroup);

    let url = environment.iframeUrl;
    if (voucherGroup.type === 'sell') {
      url += 'buy/';
    } else {
      url += 'activate/';
    }
    url += `${this.townshipId}/${voucherGroupId}`;
    window.location.href = url;
    // }
    this.waiting = false;
  }
}
