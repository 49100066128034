/* src/app/find-voucher-groups/find-voucher-groups.component.scss */
:host ::ng-deep .mat-horizontal-stepper-header-container {
  display: none;
}
h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}
.wrapper {
  overflow-y: auto;
  background-color: white;
  height: 100vh;
}
.wrapper .wrapper-bg {
  width: 100%;
  box-sizing: border-box;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 22px 12px;
  background-color: var(--primary);
}
.stepper-buttons-wrapper {
  margin-top: 16px;
  width: 100%;
}
.stepper-buttons {
  margin: auto;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.address-form-wrapper {
  margin: 24px auto;
  min-width: 300px;
  max-width: 600px;
  padding: 0 8px;
}
.address-form-wrapper .postal {
  width: 100%;
}
.address-form-wrapper .house-number {
  width: 60%;
  padding-right: 6px;
  box-sizing: border-box;
}
.address-form-wrapper .addition {
  width: 40%;
  padding-left: 6px;
  box-sizing: border-box;
}
h2 {
  width: 100%;
}
.top-title {
  font-size: 38px;
  font-family: "Viga";
  text-align: center;
  margin: 0 0 24px 0;
}
.voucher-groups-wrapper {
  margin: auto;
  max-width: 800px;
  padding: 0 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.voucher-groups-wrapper .title-wrapper {
  margin: 16px 0 0 0;
}
.voucher-groups-wrapper .title-wrapper .title {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 38px;
  font-family: "Viga";
  margin: 0 0 16px 0;
  color: var(--primary);
}
.voucher-groups-wrapper .title-wrapper .small-title {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  padding: 0 0 12px 0;
}
.terms-checkbox ::ng-deep .mat-checkbox-layout {
  white-space: normal !important;
}
.error-searching-address {
  color: red;
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}
.floating-back-button-wrapper {
  width: 100%;
  max-width: 590px;
  margin: auto;
}
.floating-back-button-wrapper .floating-back-button {
  position: relative;
  pointer-events: none;
  bottom: 50px;
}
.floating-back-button-wrapper .floating-back-button button {
  pointer-events: auto;
}
.activate-title-wrapper {
  margin: 16px 0 0 0;
}
.activate-title-wrapper .title {
  font-size: 38px;
  font-family: "Viga";
  margin: 0 0 16px 0;
  text-align: center;
  color: var(--primary);
}
.activate-title-wrapper .small-title-no-sub {
  font-size: 22px;
  font-weight: 500;
  text-align: center;
}
.verification-form-wrapper {
  margin: 24px auto;
  min-width: 300px;
  max-width: 800px;
  padding: 0 8px;
}
.verification-form-wrapper mat-form-field {
  width: 100%;
}
.verification-form-wrapper .title {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 38px;
  font-family: "Viga";
  margin: 0 0 16px 0;
  color: var(--primary);
}
.verification-form-wrapper .small-title-no-sub {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  margin: 0 0 36px 0;
}
.verification-form-wrapper .actions {
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.cards-wrapper {
  max-width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}
.cards-wrapper .card {
  height: 220px;
  width: 200px;
  min-width: 200px;
  border: 1px solid #656d71;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  background-color: #f3f1e9;
  cursor: pointer;
}
@media screen and (max-width: 515px) {
  .cards-wrapper .card {
    width: 100% !important;
  }
}
.cards-wrapper .card .card-type-image {
  background-color: white;
  height: 165px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
}
.cards-wrapper .card .card-type-image mat-icon {
  height: 165px;
  width: 200px;
}
.cards-wrapper .card .card-type-text {
  padding: 12px 12px 24px 12px;
  font-weight: 500;
  font-size: 14px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cards-wrapper .card .card-type-sub-text {
  font-weight: 400;
  font-size: 13px;
  padding: 0px 12px 2px 12px;
}
.cards-wrapper .card.level {
  height: 250px;
}
.cards-wrapper .card.level .card-type-text {
  padding: 12px 12px 18px 12px;
}
.cards-wrapper .card.active {
  height: 216px;
  width: 196px;
  min-width: 196px;
  border: 3px solid;
  border-color: var(--active-border-color);
}
.cards-wrapper .card.active .card-type-image {
  height: 163px;
}
.cards-wrapper .card.active .card-type-image mat-icon {
  margin: -2px 0 0 0;
}
.cards-wrapper .card.active .card-type-text {
  padding: 12px 10px 16px 10px;
}
.cards-wrapper .card.active .card-type-sub-text {
  padding: 0px 10px 0px 10px;
}
.cards-wrapper .card.level.active {
  height: 246px;
}
.cards-wrapper .card.level.active .card-type-text {
  padding: 12px 10px 18px 10px;
}
.group-links-wrapper {
  margin: 24px 0 0 0;
  width: 100%;
}
.group-links-wrapper .group-link-title {
  font-weight: 500;
  font-size: 20px;
}
.group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card {
  min-height: 160px;
  box-sizing: border-box;
  cursor: pointer;
  border-color: white;
}
@media screen and (max-width: 625px) {
  .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card {
    flex-direction: column;
    max-width: 325px;
    margin: 12px auto 16px auto;
  }
}
.group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .avatar {
  min-width: 120px;
}
@media screen and (max-width: 625px) {
  .group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .avatar {
    height: 125px;
    width: 100%;
  }
}
.group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .card-content .title-slogan {
  margin: 0;
}
.group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .card-content .title-slogan .title {
  font-family: "Viga";
  margin: 0 0 16px 0;
  display: block;
  text-align: left;
  font-size: 22px;
  color: var(--primary);
}
.group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .card-content .voucher-value-wrapper {
  margin: 12px 0 0 0;
}
.group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .card-content .voucher-value-wrapper .voucher-value,
.group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .voucher-group-card .card-content .voucher-value-wrapper .alt-coupon {
  color: var(--primary);
}
.group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .selected-voucher-group-card {
  border-color: var(--primary);
}
.group-links-wrapper .voucher-group-cards-wrapper .voucher-group-card-wrapper .separator {
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
}
.voucher-group-details {
  width: 100%;
  max-width: 600px;
  padding: 0 8px;
  margin: auto;
}
.voucher-group-details .top-title {
  font-size: 38px;
  font-family: "Viga";
  text-align: center;
  margin: 16px 0 0 0;
  color: var(--primary);
}
.voucher-group-details .small-top-title {
  font-size: 22px;
  text-align: center;
  margin: 0 0 24px 0;
  color: var(--primary);
}
.voucher-group-details .avatar {
  width: 100%;
  min-width: 100%;
  height: 160px;
  border-radius: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.voucher-group-details .avatar.image {
  height: 0 !important;
  padding-top: 70%;
}
.voucher-group-details .avatar.placeholder {
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  align-items: center;
  font-weight: 550;
  font-size: 50px;
  background-color: var(--primary);
  color: var(--primary-contrast);
  -webkit-user-select: none;
  user-select: none;
}
.voucher-group-details .title-slogan-desc {
  color: #1c1c18;
  white-space: pre-line;
  margin: 10px 0 0 0;
}
.voucher-group-details .title-slogan-desc .slogan {
  margin: 0 0 10px 0;
}
.voucher-group-details .title-slogan-desc .description {
  font-size: 14px;
  white-space: pre-line;
}
.voucher-group-details .terms .title {
  font-size: 16px;
  color: var(--primary);
}
.voucher-group-details .terms .voucher-group-terms {
  font-size: 12px;
  white-space: pre-line;
}
.voucher-group-details .terms .general-terms {
  margin: 16px 0;
  border: solid #888 1px;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
}
.voucher-group-details .terms .general-terms .title {
  font-weight: 500;
  margin-bottom: 16px;
}
.voucher-group-details .terms .general-terms .terms-link-info::after {
  width: 210px;
  content: "Door op deze link te klikken wordt er een nieuw tabblad geopend.";
}
.pin-info::after {
  content: "U kunt uw pincode vinden in de door u ontvangen brief.";
}
/*# sourceMappingURL=find-voucher-groups.component.css.map */
