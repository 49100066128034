/* src/app/group-link/group-link.component.scss */
.wrapper {
  margin: auto;
  overflow-y: auto;
  max-height: calc(100vh - 16px);
  padding: 8px;
  background: none transparent;
  width: 300px;
  margin-top: 6px;
}
.wrapper .img-wrapper {
  text-align: center;
}
.wrapper .img-wrapper .logo {
  height: 90px;
  content: var(--logo-fullwidth);
  margin-top: -16px;
  margin-bottom: 12px;
}
.wrapper .group-link {
  background-color: white;
}
.wrapper .group-link form {
  width: 100%;
}
.wrapper .group-link form mat-form-field {
  width: 100%;
}
mat-card-content .header {
  margin-top: 0px;
  color: var(--primary);
  font-weight: 500;
  font-size: 20px;
}
mat-card-content p {
  font-size: 18px;
  text-align: center;
  width: 100%;
  margin-bottom: 0px;
}
mat-card-content ::ng-deep .mat-list-item-content {
  justify-content: center;
}
.loading-wrapper {
  height: 100vh;
  width: 100vw;
}
.loading-wrapper .loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}
/*# sourceMappingURL=group-link.component.css.map */
