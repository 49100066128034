/* src/app/passport/passport-navigation/passport-navigation.component.scss */
.nav-bar {
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 16px;
}
.left,
.right {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.logo {
  margin-right: 16px;
  align-items: center;
  height: 40px;
  src: "../../../assets/images/logos/groene_app_logo.png";
}
.title {
  margin: 0 16px;
  font: normal normal normal 18px Viga;
}
/*# sourceMappingURL=passport-navigation.component.css.map */
