/* src/app/app.component.scss */
@font-face {
  font-family: Viga;
  src: url("./media/Viga-Regular.ttf");
}
::ng-deep .mat-mdc-form-field {
  --mat-form-field-container-text-line-height: 26px;
}
::ng-deep .mat-mdc-unelevated-button:not(:disabled).header-button {
  background-color: white !important;
  color: var(--primary) !important;
}
::ng-deep .mdc-button {
  font-size: 18px !important;
}
::ng-deep .mdc-button:focus {
  border: 2px solid rgba(0, 0, 0, 0.5);
  opacity: 0.9;
}
::ng-deep .mat-mdc-form-field-subscript-wrapper:has(.mat-mdc-form-field-hint),
::ng-deep .half-fields .mat-mdc-form-field-subscript-wrapper {
  margin: 0 0 14px 0;
}
@media screen and (max-width: 625px) {
  ::ng-deep .mat-mdc-form-field-subscript-wrapper:has(.mat-mdc-form-field-hint),
  ::ng-deep .half-fields .mat-mdc-form-field-subscript-wrapper {
    margin: 0 0 28px 0;
  }
}
::ng-deep .mat-mdc-form-field-hint-wrapper {
  padding: 0 0 0 16px !important;
}
::ng-deep .mat-mdc-select-panel {
  border-radius: 0 0 24px 24px !important;
  padding: 0 !important;
}
::ng-deep .mat-stepper-vertical,
::ng-deep .mat-stepper-horizontal {
  background-color: transparent !important;
}
/*# sourceMappingURL=app.component.css.map */
