/* src/app/burden-of-proof-form/burden-of-proof-form.component.scss */
.page {
  padding: 1rem 1rem 1rem 1rem;
  height: calc(100vh - 64px - 1rem);
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  box-sizing: border-box;
  overflow: auto;
  border-radius: 8px;
}
::ng-deep .mdc-button {
  font-weight: normal;
}
::ng-deep .mat-mdc-select-panel {
  --mat-select-panel-background-color: #efeee2;
}
.wrapper {
  overflow-y: auto;
  height: 100vh;
  background-color: #efeee2 !important;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
}
@media only screen and (max-width: 579px) {
  .wrapper {
    font-size: 14px;
  }
}
.wrapper .title {
  font-size: 1.875em;
  font-family: "Viga";
}
.wrapper .title-subtext {
  font-size: 1.25em;
}
.wrapper .subtitle {
  margin: 14px 0;
  font-size: 1.25em;
  font-family: "Viga";
}
.wrapper .normal-title {
  font-size: 1.5em;
}
.wrapper .wrapper-bg .header-wrapper {
  margin: auto;
  max-width: 600px;
  display: flex;
  align-items: center;
}
.wrapper .wrapper-bg .header-wrapper .header-img {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 16px;
  height: 105px;
  width: 180px;
}
.wrapper .wrapper-bg .header-wrapper .header-text {
  color: white;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.wrapper .toolbar {
  background-color: #efeee2;
  font-size: 1em;
  padding: 0 8px;
}
.wrapper .toolbar .logo-env-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.wrapper .toolbar .logo-env-wrapper .header-logo {
  margin: 0 0 0 16px;
  height: 48px;
  width: 80px;
  min-width: 80px;
  display: block;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 579px) {
  .wrapper .toolbar .logo-env-wrapper .header-logo {
    margin: 0;
  }
}
.wrapper .toolbar .logo-env-wrapper .header-text {
  display: none;
  word-break: break-word;
  text-wrap: auto;
  line-height: 1.25;
}
@media only screen and (max-width: 579px) {
  .wrapper .toolbar .logo-env-wrapper .header-text {
    display: block;
  }
}
.wrapper .content-wrapper {
  margin: 0 24px;
}
@media only screen and (max-width: 579px) {
  .wrapper .content-wrapper {
    margin: 0;
  }
}
.wrapper .content-wrapper .column-wrapper {
  display: flex;
  flex-direction: row;
}
@media only screen and (max-width: 579px) {
  .wrapper .content-wrapper .column-wrapper {
    flex-direction: column;
  }
}
.wrapper .content-wrapper .column-wrapper .column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.wrapper .content-wrapper .actions {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 12px;
}
.wrapper .content-wrapper .actions .button-group-end {
  display: flex;
  gap: 15px;
}
.wrapper .content-wrapper .actions button {
  border-radius: 15px;
}
.wrapper .content-wrapper .actions .phone-button {
  display: none;
  border: 1px solid gray;
  border-radius: 30px;
}
@media only screen and (max-width: 579px) {
  .wrapper .content-wrapper .actions {
    margin: 20px 0 12px;
  }
  .wrapper .content-wrapper .actions .web-button {
    display: none;
  }
  .wrapper .content-wrapper .actions .phone-button {
    display: block;
  }
  .wrapper .content-wrapper .actions .submit-button {
    height: 100%;
    border-radius: 30px;
  }
}
.wrapper .content-wrapper .progress {
  display: flex;
  row-gap: 15px;
  margin-top: 18px;
  flex-direction: column;
  --progress-outer-color: var(--primary);
  --progress-inner-color: var(--secondary);
}
.wrapper .content-wrapper .progress progress {
  width: 100%;
  appearance: none;
  block-size: 4px;
  background-color: var(--progress-inner-color);
  border-radius: 5px;
}
.wrapper .content-wrapper .progress progress::-webkit-progress-bar {
  background-color: var(--progress-inner-color);
  border-radius: 5px;
}
.wrapper .content-wrapper .progress progress::-webkit-progress-value {
  background-color: var(--progress-outer-color);
  border-radius: 5px;
}
.wrapper .content-wrapper .progress progress::-moz-progress-bar {
  background-color: var(--progress-outer-color);
  border-radius: 5px;
}
.wrapper .content-wrapper .info-wrapper .info-label {
  font-family: "Viga";
}
.wrapper .content-wrapper .step-1 {
  margin: 0 auto 24px;
  max-width: 80%;
}
@media only screen and (max-width: 579px) {
  .wrapper .content-wrapper .step-1 {
    max-width: 90%;
  }
}
.wrapper .content-wrapper .step-1 .card {
  margin: 24px 0;
  padding: 18px 12px;
  background-color: #fbfaed;
  border-radius: 10px;
}
.wrapper .content-wrapper .step-1 .card .title {
  margin-bottom: 18px;
}
.wrapper .content-wrapper .step-1 .card .title-subtext {
  margin: 14px auto;
}
.wrapper .content-wrapper .step-1 .card .card-list {
  padding-left: 30px;
}
.wrapper .content-wrapper .step-1 .card .card-list .card-list-item {
  margin: 10px 0;
}
.wrapper .content-wrapper .step-1 .info-checkbox {
  margin-bottom: 12px;
}
.wrapper .content-wrapper .step-1 .info-checkbox ::ng-deep .mdc-label {
  font-size: 1em;
}
.wrapper .content-wrapper .step-1 .actions {
  justify-content: end;
}
.page {
  padding: 0;
  background-color: transparent;
  flex-direction: row;
  gap: 1rem;
}
@media only screen and (max-width: 899px) {
  .page {
    flex-direction: column;
    height: auto;
  }
}
.page .voucher-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  background-color: #fbfaed;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
  gap: 16px;
  overflow-y: auto;
}
@media only screen and (max-width: 899px) {
  .page .voucher-info {
    overflow: hidden;
  }
}
@media only screen and (max-width: 579px) {
  .page .voucher-info {
    display: none;
  }
}
.page .voucher-info .info-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.page .voucher-info .info-header .logo-img {
  flex: 1;
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 16px;
  min-height: 125px;
  width: 100%;
}
.page .voucher-info .info-header .info-title {
  flex: 3;
}
.page .voucher-info .separator-line {
  width: 100%;
  border-bottom: 1px solid lightgray;
}
.page .voucher-info .column-wrapper {
  flex-direction: column;
  gap: 15px;
}
@media only screen and (max-width: 899px) {
  .page .voucher-info .column-wrapper {
    flex-direction: row;
  }
}
.page .voucher-info .column-wrapper .column {
  gap: 15px;
}
.page .steps {
  flex: 3;
  display: flex;
  flex-direction: column;
  background-color: #fbfaed;
  border-radius: 8px;
  padding: 0.5rem 1rem 1rem 1rem;
  min-width: 0;
}
@media only screen and (max-width: 899px) {
  .page .steps {
    min-height: calc(95vh - 1rem);
    margin-bottom: 1rem;
  }
}
.page .steps .progress {
  margin-bottom: 10px;
}
.page .steps .step-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  overflow: hidden;
}
.page .steps .step-wrapper .step-content {
  padding: 1rem 0;
  overflow-y: auto;
}
.page .steps .step-wrapper .step-content mat-form-field {
  width: 100%;
}
.page .steps .step-wrapper .step-content .step {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.page .steps .step-wrapper .step-content .step .selected-files-wrapper {
  margin-top: 20px;
}
.page .steps .step-wrapper .step-content .step .selected-files-wrapper ::ng-deep .mat-mdc-chip {
  display: flex;
  max-width: 95%;
}
.page .steps .step-wrapper .step-content .step .selected-files-wrapper ::ng-deep .mat-mdc-chip .mdc-evolution-chip__cell {
  overflow: hidden;
}
.page .steps .step-wrapper .step-content .step .checkbox-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.page .steps .step-wrapper .step-content .step .example-image {
  height: 250px;
}
.page .steps .step-wrapper .step-content .step .example-image img {
  height: 100%;
  max-width: 100%;
  object-fit: contain;
  object-position: top;
}
.page .steps .step-wrapper .step-content .step .upload-button {
  border-radius: 15px;
}
.page .steps .step-wrapper .step-content .step .upload-button mat-icon {
  color: inherit;
}
.page .steps .step-wrapper .step-content .step .upload-preview-wrapper {
  padding: 0 0 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 10px;
}
.page .steps .step-wrapper .step-content .step .upload-preview-wrapper .upload-preview-options {
  height: 200px;
  flex: 1 1 calc(33% - 20px);
  position: relative;
  max-width: calc(33% - 20px);
}
@media only screen and (max-width: 899px) {
  .page .steps .step-wrapper .step-content .step .upload-preview-wrapper .upload-preview-options {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
}
@media only screen and (max-width: 579px) {
  .page .steps .step-wrapper .step-content .step .upload-preview-wrapper .upload-preview-options {
    height: 125px;
  }
}
.page .steps .step-wrapper .step-content .step .upload-preview-wrapper .upload-preview-options .delete-option {
  position: absolute;
  right: 0;
  z-index: 2;
  margin: 10px;
  border-radius: 90px;
  background-color: var(--secondary);
  color: var(--secondary-contrast);
}
.page .steps .step-wrapper .step-content .step .upload-preview-wrapper .upload-preview-options .upload-preview {
  border-radius: 15px;
  height: 100%;
  width: 100%;
  background-color: rgb(221, 221, 221);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.page .steps .step-wrapper .step-content .step .upload-preview-wrapper .upload-name {
  display: flex;
  justify-content: center;
  width: 100%;
}
.page .steps .step-wrapper .step-content .step-2 .section-title,
.page .steps .step-wrapper .step-content .step-3 .section-title,
.page .steps .step-wrapper .step-content .step-8 .section-title,
.page .steps .step-wrapper .step-content .step-7 .section-title {
  margin-bottom: 10px;
}
.page .steps .step-wrapper .step-content .step-3 .subsidy-options {
  margin: 10px 0;
}
.page .steps .step-wrapper .step-content .step-3 .subsidy-options .subsidy-option-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}
@media only screen and (max-width: 579px) {
  .page .steps .step-wrapper .step-content .step-3 .subsidy-options .subsidy-option-wrapper {
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;
  }
}
.page .steps .step-wrapper .step-content .step-3 .subsidy-options .subsidy-option-wrapper mat-form-field {
  flex: 1;
  margin-right: 20px;
  max-width: calc(33% - 20px);
}
@media only screen and (max-width: 579px) {
  .page .steps .step-wrapper .step-content .step-3 .subsidy-options .subsidy-option-wrapper mat-form-field {
    max-width: 100%;
  }
}
.page .steps .step-wrapper .step-content .step-4 .selected-file-wrapper {
  margin-bottom: 10px;
}
.page .steps .step-wrapper .step-content .step-4 .receipt-form-field {
  max-width: calc(33% - 20px);
  margin-bottom: 5px;
}
@media only screen and (max-width: 579px) {
  .page .steps .step-wrapper .step-content .step-4 .receipt-form-field {
    max-width: 100%;
  }
}
@media only screen and (max-width: 350px) {
  .page .steps .step-wrapper .step-content .step-4 .upload-button mat-icon {
    display: none;
  }
}
.loading-wrapper {
  position: absolute;
  top: 0;
}
/*# sourceMappingURL=burden-of-proof-form.component.css.map */
