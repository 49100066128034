import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-general-terms',
    templateUrl: './general-terms.component.html',
    styleUrls: ['./general-terms.component.scss'],
    standalone: false
})
export class GeneralTermsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
