import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { MatIconRegistry } from '@angular/material/icon';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { updateTheme } from './globals';
import { getStorage } from 'firebase/storage';

export const app = initializeApp(environment.firebase);
export const db = getFirestore(app);
export const functions = getFunctions(app, 'europe-west1');
export const storage = getStorage(app, 'europe-west1');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
})
export class AppComponent implements OnInit {
  public pageTitle: string;
  icons: string[] = [
    'Building_Apartment',
    'Building_Detached',
    'Building_Semidetached',
    'Building_Townhouse_Corner',
    'Building_Townhouse',
    'Level_Bronze',
    'Level_Gold',
    'Level_Platinum',
    'Level_Silver',
    'Measure_Facade_Insulation',
    'Measure_Floor_Insulation',
    'Measure_Glass_Insulation',
    'Measure_Heat_Pump',
    'Measure_Heating_Network',
    'Measure_Roof_Insulation',
    'Measure_Solar_Boiler',
    'Measure_Solar_Panels',
  ];

  constructor(
    private titleService: Title,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    this.titleService.setTitle(environment.name.name);
    this.icons.forEach((icon) => {
      iconRegistry.addSvgIcon(
        icon,
        sanitizer.bypassSecurityTrustResourceUrl(
          `../assets/images/passport/${icon}.svg`
        )
      );
    });
    updateTheme({
      primary: environment.themeColors.primary,
      secondary: environment.themeColors.secondary,
    });
  }
  ngOnInit(): void {}
}
