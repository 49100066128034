<div
  class="loading-wrapper"
  *ngIf="awaitingResponse"
>
  <div class="loading">
    <mat-spinner></mat-spinner>
  </div>
</div>

<div
  class="wrapper"
  role="main"
  *ngIf="!awaitingResponse"
>
  <img
    class="success-image"
    src="https://firebasestorage.googleapis.com/v0/b/wemaron-vouchers.appspot.com/o/img%2Femails%2Fheaders%2FImageEmail2.png?alt=media&token=69d41c69-93e8-4eea-895f-ccdb1246a286"
    alt="success image"
  />
  <div class="title-position">
    <div
      role="heading"
      aria-level="1"
    >
      <div class="title">{{ titleAbove }}</div>
      <div class="title">{{ titleBelow }}</div>
    </div>
    <div
      class="order-confirmation"
      *ngIf="order.status === 'done' || order.status === 'paid'"
    >
      {{ 'buy.succeed.order-confirmation' | translate }}
    </div>
  </div>
  <div
    class="your-order"
    role="heading"
    aria-level="2"
    *ngIf="order.status === 'done' || order.status === 'paid'"
  >
    {{ 'buy.succeed.your-order' | translate }}
  </div>
  <mat-divider></mat-divider>
  <div
    class="order-table"
    *ngIf="order.status === 'done' || order.status === 'paid'"
  >
    <div class="order-table-row">
      <div class="order-table-column">Artikel</div>
      <div class="order-table-column">Aantal</div>
      <div class="order-table-column">Prijs</div>
    </div>
    <div
      class="order-table-row"
      *ngFor="let dataObject of data"
    >
      <div class="order-table-column">{{ dataObject.article }}</div>
      <div class="order-table-column">{{ dataObject.amount }}</div>
      <div class="order-table-column">{{ dataObject.price }}</div>
    </div>
  </div>
  <button
    class="button-positioning spinner-button secondary-color"
    mat-flat-button
    class="spinner-button"
    (click)="openWindow(order.downloadUrl)"
    *ngIf="order.downloadUrl && !awaitingResponse"
  >
    {{ 'buy.button.download' | translate }}
    <!-- <mat-spinner
      diameter="20"
      color="spinner"
      *ngIf="awaitingResponse"
    ></mat-spinner> -->
  </button>
  <button
    class="button-positioning"
    mat-flat-button
    (click)="openWindow(redirectUrl)"
    *ngIf="!order.downloadUrl && redirectUrl && !awaitingResponse"
  >
    {{ 'buy.button.close' | translate }}
  </button>
</div>
