/* src/app/find-voucher-groups/dialogs/verify-pin/verify-pin.component.scss */
mat-form-field {
  width: 100%;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
button {
  text-transform: uppercase;
}
.cancel-button {
  color: #006239;
}
.submit-button {
  background-color: #006239;
  color: white;
}
.verify-form {
  display: flex;
  flex-wrap: wrap;
}
/*# sourceMappingURL=verify-pin.component.css.map */
