<div class="nav-bar">
  <div class="left">
    <img
      class="logo"
      src="../../../assets/images/logos/groene_app_logo.png"
    />
    <div
      class="title"
      [style.color]="theme.primary.background"
    >
      Woningpaspoort
    </div>
  </div>
  <div class="right"></div>
</div>
