<div
  class="terms-wrapper"
  tabindex="0"
>
  <h4>Voorwaarden Groene App</h4>

  <h4>Betrokken partijen</h4>

  <h5>Platformbeheerder</h5>
  <p>
    Wemaron B.V. is de beheerder van het Groene App platform. weMaron zorgt voor
    de technische systemen maar is geen verantwoordelijke uitgever van de Groene
    App. Kijk op https://wemaron.nl/ voor meer informatie over de
    platformbeheerder.
  </p>

  <h5>Uitgevers</h5>
  <p>
    Groene App is een platform voor overheden, woningcorporaties en
    samenwerkingsverbanden die een duurzaam woningbestand bevorderen. Zij kunnen
    onder eigen beheer en verantwoordelijkheid zogenaamde “Groene Bon”
    cadeaubonnen uitgeven.
  </p>
  <h5>Innende organisaties</h5>
  <p>
    Zijn (lokale) ondernemers en webshops die producten en/of diensten aanbieden
    en/of bijeenkomsten organiseren waarvoor de Groene Bon kan worden ingezet.
    Uiteindelijk zullen uitgevers eventuele vergoedingen rechtstreeks aan
    innende organisaties uitbetalen.
  </p>
  <h5>Groene App bezitter</h5>
  <p>
    Een Groene Bon bezitter is een persoon die op rechtmatige wijze een Groene
    Bon heeft verkregen. Dat kan zijn door de ontvangst van een aan hem
    geadresseerde brief of mail op basis van juist verstrekte informatie. De
    Groene Bon bezitter mag de bon vrij besteden bij een innende organisatie
    conform de waarde, geldigheidsduur en gebruiksvoorwaarden.
  </p>
  <h4>Gebruik van de Groene App</h4>
  <p>
    Per Groene App actie kan de uitgever bepalen wat de waarde, de
    geldigheidsduur en de gebruiksvoorwaarden zijn.
  </p>
  <h5>Waarde</h5>
  <p>
    Een Groene Bon is een gift met een vast bepaald bestemmingsdoel. De waarde
    van een Groene Bon is dan ook enkel van toepassing als aan het
    bestemmingsdoel wordt voldaan. De Groene Bon is nooit inwisselbaar voor
    geld. Goederen / diensten die worden geretourneerd, worden niet vergoed of
    in enige vorm gerestitueerd. U dient de Groene Bon te besteden bij een
    deelnemende organisatie. Een eventuele restwaarde vervalt, zodra de
    geldigheidsduur van de bon is verstreken. In sommige gevallen is de Groene
    Bon een inschrijving voor of deelname aan een activiteit. Ook in die
    gevallen kan de Groene Bon nooit tot geldelijke restitutie leiden.
  </p>
  <h5>Geldigheidsduur</h5>
  <p>
    Iedere Groene Bon heeft een beperkte geldigheidsduur die bepaald wordt door
    het vroegste van de volgende data: de datum vermeld op de Groene Bon, de
    datum tot wanneer de Groene App actie loopt waarvoor de bon inzetbaar is, de
    datum tot waarop er deelnemende organisaties de Groene Bon accepteren voor
    het beoogde gebruiksdoel. Wanneer de geldigheidsduur verstreken is, dan is
    de Groene Bon niet meer inwisselbaar.
  </p>
  <h5>Gebruiksvoorwaarden</h5>
  <p>
    Iedere Groene Bon kent gebruiksvoorwaarden. Deze kunnen omvatten of en welke
    producten, diensten, deelname aan activiteiten en inschrijvingen op
    mailinglijsten of bellijsten van toepassing zijn. Dit kan ook betekenen dat
    u informatie dient te verstrekken om tot levering over te kunnen gaan.
    Telkens als u gevraagd wordt om informatie te verstrekken, zijn de
    privacy-voorwaarden van de uitgever van toepassing.
  </p>
  <h5>Actievoorwaarden</h5>
  <p>
    Tenzij uitdrukkelijk anders vermeld op de bon, mag slechts één bon worden
    aangevraagd, één bon eenmalig worden aangeboden en dient de aanvrager op het
    adres te wonen waarvoor de bon is aangevraagd of over de uitdrukkelijke
    toestemming van de bewoner van het adres waarvoor de bon is aangevraagd te
    beschikken.
  </p>
  <p>
    De Groene App is enkel geldig als aan alle voorwaarden is voldaan.
    Bewijslast om dit aan te tonen rust bij zowel de innende organisatie als bij
    de persoon die de bon heeft gekregen.
  </p>
  <h5>Bewijslast Groene App bezitter</h5>
  <p>
    Wanneer u een Groene Bon heeft gekregen, dan kunt u deze binnen de
    geldigheidsduur en volgens de gebruiksvoorwaarden aanbieden bij een innende
    organisatie. Deze organisaties worden kenbaar gemaakt op de Groene App site
    van de uitgever. U mag niet proberen om de Groene App op enigerlei andere
    manier in te zetten, te verhandelen of te verspreiden. Misbruik is
    strafbaar. De innende organisatie mag uw Groene Bon zonder opgave van
    redenen weigeren.
  </p>
  <h5>Bewijslast innende organisatie</h5>
  <p>
    De innende organisatie verplicht zich tot een correcte administratieve
    afhandeling van de Groene Bon. Dat betekent dat de organisatie een
    aangeboden Groene Bon alleen accepteert wanneer aan alle voorwaarden voldaan
    is en voor de aanschaf van goedgekeurde diensten en producten voor de
    betreffende Groene Bon. De organisatie verplicht zich tot het correcte
    gebruik van de daarvoor bestemde applicaties waar hij toegang toe heeft
    gekregen, danwel het gebruik van een interface waarover aparte afspraken
    zijn gemaakt. De innende organisatie slaat de kassabon op bij de registratie
    van de Groene Bon en ‘claimt’ daarmee de Groene Bon éénmalig. De innende
    organisatie heeft enkel recht op uitbetaling van Groene App wanneer aan de
    bewijsplicht is voldaan. Wanneer er sprake is van een foutieve claim, kan
    uitbetaling geweigerd worden en -indien reeds uitbetaald is – de uitbetaling
    teruggevorderd worden. Misbruik is strafbaar en foutieve claims kunnen tot
    uitsluiting van verdere deelname leiden.
  </p>
</div>
