<h1 mat-dialog-title>{{ 'verify.title' | translate }}</h1>
<div mat-dialog-content>
  <form
    class="verify-phone-form"
    (ngSubmit)="submit()"
    [formGroup]="verifyPhoneForm"
  >
    <div style="margin-bottom: 10px">{{ 'verify.info' | translate }}</div>
    <mat-form-field>
      <mat-label>{{ 'verify.code' | translate }}</mat-label>
      <input
        cdkFocusInitial
        placeholder="123456"
        formControlName="code"
        autocomplete="off"
        matInput
        required
        (keydown.space)="$event.preventDefault()"
        type="number"
      />
      <mat-error *ngIf="verifyPhoneForm.controls.code.hasError('required')">
        {{ 'errors.required' | translate }}
      </mat-error>
    </mat-form-field>
  </form>
</div>
<mat-dialog-actions align="end">
  <button
    mat-button
    mat-dialog-close
  >
    {{ 'verify.cancel' | translate }}
  </button>
  <button
    [disabled]="verifyPhoneForm.invalid"
    mat-flat-button
    color="primary"
    (click)="submit()"
  >
    {{ 'verify.submit' | translate }}
  </button>
</mat-dialog-actions>
