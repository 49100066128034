/* src/app/activate-voucher/dialogs/verify-phone/verify-phone.component.scss */
mat-form-field {
  width: 100%;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.verify-phone-form {
  display: flex;
  flex-wrap: wrap;
}
input[type=number] {
  -moz-appearance: textfield;
}
button {
  text-transform: uppercase;
}
/*# sourceMappingURL=verify-phone.component.css.map */
