import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { environment } from 'src/environments/environment';
import {
  FileInfo,
  SubsidyOption,
  TownshipPublicSettings,
  Voucher,
  VoucherGroup,
} from 'src/app/interfaces';
import { fixTermsUrl, getTownshipColors } from 'src/app/globals';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { initializeApp } from 'firebase/app';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  StorageReference,
  getDownloadURL,
  ref,
  uploadBytes,
} from 'firebase/storage';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import imageCompression from 'browser-image-compression';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../validators/custom-validators';
import { badlyInsulatedParts } from '../enums';
import { KeyValue } from '@angular/common';
const app = initializeApp(environment.firebase);
const functions = getFunctions(app, 'europe-west1');

@Component({
  selector: 'app-burden-of-proof-form',
  templateUrl: './burden-of-proof-form.component.html',
  styleUrls: ['./burden-of-proof-form.component.scss'],
})
export class BurdenOfProofFormComponent implements OnInit {
  highContrast: boolean = false;
  loaded: boolean = false;
  township: TownshipPublicSettings;
  townshipId: string;
  townshipName: string;
  townshipLogo: string;
  termsUrl?: string;
  voucherId: string;
  voucher: Voucher;
  voucherGroup: VoucherGroup;
  postal: string;
  houseNumber: string;
  subsidyOptions: SubsidyOption[] = [];
  subsidyOptionsInVoucherGroup: SubsidyOption[] = [];
  selectedSubsidyOptions: any[] = [];
  selectedSubsidyOptionsForm: FormGroup = new FormGroup([]);
  receipts: FileInfo[] = [];
  receiptsForm: FormGroup = new FormGroup([]);
  picturesBefore: FileInfo[] = [];
  picturesDuring: FileInfo[] = [];
  picturesAfter: FileInfo[] = [];
  status: string;
  hasEnergyLabel: boolean;
  energyLabels: string[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
  badlyInsulatedParts = badlyInsulatedParts;
  badlyInsulatedPartsSelected: string[] = [];
  energyLabelForm: FormGroup = new FormGroup({
    energyLabel: new FormControl(null, [Validators.required]),
  });
  saving: boolean;
  hideSendButton: boolean = false;

  theme: any = {};

  constructor(
    private route: ActivatedRoute,
    public db: AngularFirestore,
    private st: AngularFireStorage,
    private translate: TranslateService,
    private snackbar: MatSnackBar
  ) {}

  async ngOnInit(): Promise<void> {
    this.translate.setDefaultLang(environment.language);
    this.translate.use(environment.language);

    if (
      localStorage.getItem('highContrast') &&
      localStorage.getItem('highContrast') === '1'
    ) {
      this.highContrast = true;
    }

    this.route.params.subscribe(async (params) => {
      console.log('params', params);
      this.townshipName = params.townshipName;
      if (this.townshipName) {
        this.postal = params.postal;
        this.houseNumber = params.houseNumber;
        await this.getInfo(params.encryptedId);
      }
    });

    const subsidyOptionDocs = (
      await getDocs(
        collection(this.db.firestore, `/globalSettings/global/subsidyOptions`)
      )
    ).docs;
    console.log('subdocs:', subsidyOptionDocs);

    subsidyOptionDocs.forEach((subDoc) => {
      const subsidyOption = {
        id: subDoc.id,
        ...(subDoc.data() as SubsidyOption),
      };
      this.subsidyOptions.push(subsidyOption);
    });
  }

  async getInfo(encryptedId: string) {
    const townshipNameDoc = (
      await getDoc(doc(this.db.firestore, `townshipNames/${this.townshipName}`))
    ).data();
    console.log('townshipNameDoc', townshipNameDoc);
    if (!townshipNameDoc.townshipId) {
      return;
    }
    this.townshipId = townshipNameDoc.townshipId;
    const townshipRef = doc(
      this.db.firestore,
      `township/${this.townshipId}/settings/public`
    );
    this.township = (
      await getDoc(townshipRef)
    ).data() as TownshipPublicSettings;
    console.log('township', this.township);
    if (!this.township) {
      return console.error('township not found');
    }
    this.theme = await getTownshipColors(this.township);
    const callable = httpsCallable(functions, 'httpGetVoucherInfo', {
      timeout: 60000,
    });
    const result = await callable({
      townshipId: this.townshipId,
      encryptedId,
      postal: this.postal,
      houseNumber: this.houseNumber,
    });
    console.log('result', result);
    const resData = result.data as any;
    this.voucherId = resData.voucher.number;
    this.voucher = resData.voucher as Voucher;
    this.voucherGroup = resData.voucherGroup as VoucherGroup;
    console.log('voucher', this.voucher);
    console.log('voucherGroup', this.voucherGroup);
    if (!this.voucher) {
      return console.error('error getting voucher');
    }
    if (this.voucherGroup.subsidyOptions) {
      this.voucherGroup.subsidyOptions.forEach((id) => {
        this.subsidyOptionsInVoucherGroup.push(
          this.subsidyOptions.find((subOption) => {
            return subOption.id === id;
          })
        );
      });
    }
    if (this.voucher.burdenOfProofForm) {
      this.voucher.burdenOfProofForm.subsidyOptions?.forEach((subsidyOption) =>
        this.updateSubsidyOption(true, subsidyOption, true)
      );
      this.picturesBefore = this.voucher.burdenOfProofForm.picturesBefore;
      this.picturesDuring = this.voucher.burdenOfProofForm.picturesDuring;
      this.picturesAfter = this.voucher.burdenOfProofForm.picturesAfter;
      this.voucher.burdenOfProofForm.receipts?.forEach((receipt) => {
        const receiptValue: string = receipt.receiptValue
          .toFixed(2)
          .toString()
          .replace('.', ',');
        this.receiptsForm.addControl(
          receipt.name,
          new FormControl(receiptValue, [
            CustomValidators.numberInput(false, false, 2),
            Validators.required,
          ])
        );
      });
      this.receipts = this.voucher.burdenOfProofForm.receipts;
      this.badlyInsulatedPartsSelected =
        this.voucher.burdenOfProofForm.badlyInsulatedParts ?? [];
      if (
        this.voucher.burdenOfProofForm.energyLabel === 'Niet aanwezig' ||
        !this.voucher.burdenOfProofForm.energyLabel
      ) {
        this.hasEnergyLabel = false;
        this.energyLabelForm.controls['energyLabel'].disable();
      } else {
        this.hasEnergyLabel = true;
        this.energyLabelForm.controls['energyLabel'].setValue(
          this.voucher.burdenOfProofForm.energyLabel
        );
      }
      if (
        this.voucher.burdenOfProofForm.status === 'Submitted' ||
        this.voucher.burdenOfProofForm.status === 'Accepted'
      ) {
        this.status = 'Submitted';
        this.hideSendButton = true;
        this.saving = true;
        this.receiptsForm.disable();
        this.energyLabelForm.disable();
        this.selectedSubsidyOptionsForm.disable();
      }
    }
    this.townshipName = this.township.name;
    if (this.township.logoImageUrl) {
      this.townshipLogo = this.township.logoImageUrl;
    } else {
      if (this.township.voucherPrefix === 'groene') {
        this.townshipLogo = '../../assets/images/groenebon.png';
      } else if (this.township.voucherPrefix === 'toegangs') {
        this.townshipLogo = '../../assets/images/toegangsbon.png';
      } else if (this.township.voucherPrefix === 'duurzaamwonen') {
        this.townshipLogo = '../../assets/images/duurzaamwonen.png';
      } else {
        this.townshipLogo = '../../assets/images/lokalebon.png';
      }
    }
    this.termsUrl = fixTermsUrl(this.township.termsUrl);
    if (this.termsUrl) {
      const termsLowerCase = this.termsUrl.toLocaleLowerCase();
      if (
        termsLowerCase.includes('groeneapp') ||
        termsLowerCase.includes('groenebon')
      ) {
        this.termsUrl = null;
      }
    }
    this.loaded = true;
  }

  updateSubsidyOption(
    checked: boolean,
    option: any,
    existingData: boolean = false
  ) {
    if (checked) {
      this.selectedSubsidyOptions.push(option);
      this.selectedSubsidyOptionsForm.addControl(
        `${option.name}DIY`,
        new FormControl(existingData ? option.diy : true, [Validators.required])
      );
      if (option.name === 'Warmtepomp') {
        this.selectedSubsidyOptionsForm.addControl(
          `${option.name}MainUnit`,
          new FormControl(existingData ? option.mainUnit : '', [])
        );
        if (typeof option.code !== 'undefined') {
          this.selectedSubsidyOptionsForm.addControl(
            `${option.name}Code`,
            new FormControl(existingData ? option.code : '', [
              Validators.pattern('^[a-zA-Z0-9]*$'),
              Validators.required,
            ])
          );
        }
        if (typeof option.secondaryUnit !== 'undefined') {
          this.selectedSubsidyOptionsForm.addControl(
            `${option.name}SecondaryUnit`,
            new FormControl(existingData ? option.secondaryUnit : '', [
              CustomValidators.numberInput(false, false, 2),
            ])
          );
        }
      } else {
        this.selectedSubsidyOptionsForm.addControl(
          `${option.name}MainUnit`,
          new FormControl(existingData ? option.mainUnit : '', [
            Validators.required,
            CustomValidators.numberInput(false, false, 2),
          ])
        );
        const mainUnitFormControl =
          this.selectedSubsidyOptionsForm.controls[`${option.name}MainUnit`];
        if (option.name === 'PV(T)-systeem / Zonnepanelen') {
          mainUnitFormControl.removeValidators(
            CustomValidators.numberInput(false, false, 2)
          );
          mainUnitFormControl.addValidators(
            CustomValidators.numberInput(false, false, -1)
          );
          mainUnitFormControl.updateValueAndValidity();
        } else if (
          option.name === 'Klushulp' ||
          option.name === 'Schouw / Advies'
        ) {
          mainUnitFormControl.removeValidators(
            CustomValidators.numberInput(false, false, 2)
          );
          mainUnitFormControl.updateValueAndValidity();
        }
        if (typeof option.code !== 'undefined') {
          this.selectedSubsidyOptionsForm.addControl(
            `${option.name}Code`,
            new FormControl(existingData ? option.code : '', [
              Validators.pattern('^[a-zA-Z0-9]*$'),
            ])
          );
        }
        if (typeof option.secondaryUnit !== 'undefined') {
          this.selectedSubsidyOptionsForm.addControl(
            `${option.name}SecondaryUnit`,
            new FormControl(existingData ? option.secondaryUnit : '', [
              CustomValidators.numberInput(false, false, 2),
            ])
          );
        }
      }
    } else {
      const index = this.selectedSubsidyOptions.findIndex((object) => {
        return object.id == option.id;
      });
      if (index !== -1) {
        this.selectedSubsidyOptions.splice(
          this.selectedSubsidyOptions.findIndex((object) => {
            return object.id == option.id;
          }),
          1
        );
        this.selectedSubsidyOptionsForm.removeControl(`${option.name}MainUnit`);
        this.selectedSubsidyOptionsForm.removeControl(`${option.name}Code`);
        this.selectedSubsidyOptionsForm.removeControl(
          `${option.name}SecondaryUnit`
        );
        this.selectedSubsidyOptionsForm.removeControl(`${option.name}DIY`);
      }
    }
  }

  subsidyOptionIsChecked(name: string) {
    let exists = false;
    this.voucher.burdenOfProofForm?.subsidyOptions?.forEach((option) => {
      if (option.name == name) exists = true;
    });
    return exists;
  }

  getSubsidyOptionFormName(option: string) {
    return `subOpt.${option}`;
  }

  getCurrencyString(number: number) {
    let newNumber: string;
    if (!number.toString().includes('.')) {
      // this isnt a decimal
      newNumber = `${number.toString()},-`;
    } else {
      newNumber = `${number.toFixed(2)}`;
      newNumber = newNumber.replace('.', ',');
    }
    return newNumber;
  }

  setHighContrast() {
    this.highContrast = this.highContrast ? false : true;
    localStorage.setItem('highContrast', this.highContrast ? '1' : '0');
  }

  openFileInput(htmlId: string) {
    const element: HTMLElement = document.getElementById(htmlId) as HTMLElement;
    element.click();
  }

  removeFile(file: FileInfo, type: string) {
    switch (type) {
      case 'receipts':
        this.receipts.splice(this.receipts.indexOf(file), 1);
        this.receiptsForm.removeControl(file.name);
        break;
      case 'picturesBefore':
        this.picturesBefore.splice(this.picturesBefore.indexOf(file), 1);
        break;
      case 'picturesDuring':
        this.picturesDuring.splice(this.picturesDuring.indexOf(file), 1);
        break;
      case 'picturesAfter':
        this.picturesAfter.splice(this.picturesAfter.indexOf(file), 1);
        break;
    }
  }
  selectedFiles(event: any, type: string) {
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i] as File;
      const fileInfo: FileInfo = {
        name: file.name.replace(
          /\.[a-zA-Z0-9]*/,
          file.name.match(/\.[a-zA-Z0-9]*/)[0].toLowerCase()
        ),
        file: file,
      };
      switch (type) {
        case 'receipts':
          if (this.arrayContains(this.receipts, fileInfo)) {
            return;
          }
          this.receiptsForm.addControl(
            fileInfo.name,
            new FormControl('', [
              CustomValidators.numberInput(false, false, 2),
              Validators.required,
            ])
          );

          this.receipts.push(fileInfo);
          break;
        case 'picturesBefore':
          if (this.arrayContains(this.picturesBefore, fileInfo)) {
            return;
          }
          this.picturesBefore.push(fileInfo);
          break;
        case 'picturesDuring':
          if (this.arrayContains(this.picturesDuring, fileInfo)) {
            return;
          }
          this.picturesDuring.push(fileInfo);
          break;
        case 'picturesAfter':
          if (this.arrayContains(this.picturesAfter, fileInfo)) {
            return;
          }
          this.picturesAfter.push(fileInfo);
          break;
      }
    }
  }

  arrayContains(array: FileInfo[], fileInfo: FileInfo) {
    let exists = false;
    array.forEach((e) => {
      if (e.name === fileInfo.name) {
        exists = true;
      }
    });
    return exists;
  }

  async submit() {
    if (this.saving) {
      return;
    }
    this.saving = true;
    if (!this.status) {
      this.snackbar.open(
        `Je moet nog aangeven dat je klaar bent met het invullen van het formulier.`,
        'X',
        {
          duration: 5000,
        }
      );
      return (this.saving = false);
    }
    if (
      this.picturesBefore.length > 5 ||
      this.picturesDuring.length > 5 ||
      this.picturesAfter.length > 5 ||
      this.receipts.length > 5
    ) {
      this.snackbar.open(
        `Je mag maximaal 5 foto's per categorie uploaden.`,
        'X',
        {
          duration: 5000,
        }
      );
      return (this.saving = false);
    }
    if (this.status == 'Submitted') {
      if (this.selectedSubsidyOptions.length < 1) {
        this.snackbar.open(
          'Je moet minimaal 1 subsidie maatregeling kiezen.',
          'X',
          {
            duration: 5000,
          }
        );
        return (this.saving = false);
      }
      if (this.voucherGroup.cashback && this.receipts.length < 1) {
        this.snackbar.open(
          'Je moet in iedere categorie minstens 1 bestand uploaden.',
          'X',
          {
            duration: 5000,
          }
        );
        return (this.saving = false);
      }
      if (
        this.picturesBefore.length < 1 ||
        this.picturesDuring.length < 1 ||
        this.picturesAfter.length < 1
      ) {
        this.snackbar.open(
          'Je moet in iedere categorie minstens 1 bestand uploaden.',
          'X',
          {
            duration: 5000,
          }
        );
        return (this.saving = false);
      }
      if (!this.selectedSubsidyOptionsForm.valid) {
        this.selectedSubsidyOptionsForm.markAllAsTouched();
        this.snackbar.open(
          'Je hebt de subsidie maatregels nog niet overal correct ingevuld.',
          'X',
          {
            duration: 5000,
          }
        );
        return (this.saving = false);
      }
      if (this.hasEnergyLabel && !this.energyLabelForm.valid) {
        this.energyLabelForm.markAllAsTouched();
        this.snackbar.open('Je hebt nog geen energielabel ingevuld', 'X', {
          duration: 5000,
        });
        return (this.saving = false);
      }
      if (!this.hasEnergyLabel && this.badlyInsulatedPartsSelected.length < 2) {
        this.snackbar.open(
          'Je hebt nog niet genoeg slecht geïsoleerde bouwdelen geselecteerd.',
          'X',
          {
            duration: 5000,
          }
        );
        return (this.saving = false);
      }
    }
    if (!this.receiptsForm.valid) {
      this.receiptsForm.markAllAsTouched();
      this.snackbar.open('Je hebt een aankoopwaarde verkeerd ingevuld.', 'X', {
        duration: 5000,
      });
      return (this.saving = false);
    }
    const receiptsFormValues = this.receiptsForm.value;
    const subsidyOptionsFormValues = this.selectedSubsidyOptionsForm.value;
    this.receiptsForm.disable();
    this.selectedSubsidyOptionsForm.disable();
    this.energyLabelForm.disable();
    const receipts = await this.uploadFiles(
      this.receipts,
      'receipts',
      receiptsFormValues
    );
    let totalReceiptValue = 0;
    receipts.forEach(
      (receipt) =>
        (totalReceiptValue = totalReceiptValue + receipt.receiptValue)
    );
    this.selectedSubsidyOptions.forEach((option) => {
      option.mainUnit = subsidyOptionsFormValues[`${option.name}MainUnit`];
      option.diy = subsidyOptionsFormValues[`${option.name}DIY`];
      if (
        option.secondaryUnit ||
        option.secondaryUnit !=
          subsidyOptionsFormValues[`${option.name}SecondaryUnit`]
      ) {
        option.secondaryUnit =
          subsidyOptionsFormValues[`${option.name}SecondaryUnit`];
      }
      if (
        subsidyOptionsFormValues[`${option.name}Code`] &&
        (option.code ||
          option.code != subsidyOptionsFormValues[`${option.name}Code`])
      ) {
        option.code =
          subsidyOptionsFormValues[`${option.name}Code`].toUpperCase();
      } else {
        option.code = '';
      }
    });
    // console.log('this.selectedSubsidyOptions', this.selectedSubsidyOptions);
    // console.log('this.picturesBefore', this.picturesBefore);
    // console.log('this.picturesDuring', this.picturesDuring);
    // console.log('this.picturesAfter', this.picturesAfter);
    const submitObj: any = {
      townshipId: this.townshipId,
      voucherId: this.voucherId,
      postal: this.postal,
      houseNumber: this.houseNumber,
      status: this.status,
      subsidyOptions: this.selectedSubsidyOptions,
      receiptValue: totalReceiptValue,
      receipts: receipts,
      picturesBefore: await this.uploadFiles(
        this.picturesBefore,
        'picturesBefore'
      ),
      picturesDuring: await this.uploadFiles(
        this.picturesDuring,
        'picturesDuring'
      ),
      picturesAfter: await this.uploadFiles(
        this.picturesAfter,
        'picturesAfter'
      ),
    };
    if (this.hasEnergyLabel) {
      submitObj.energyLabel = this.energyLabelForm.value.energyLabel;
    }
    if (!this.hasEnergyLabel) {
      submitObj.badlyInsulatedParts = this.badlyInsulatedPartsSelected;
      submitObj.energyLabel = 'Niet aanwezig';
    }
    const callable = httpsCallable(functions, 'httpSubmitBurdenOfProofForm', {
      timeout: 60000,
    });
    console.log('submitObj', submitObj);
    const result = await callable(submitObj);
    const data = result.data as any;
    console.log('data', data);
    if (data.message == 'error_too_late') {
      this.hideSendButton = true;
      this.snackbar.open(
        'De tijd om het bewijslast aan te leveren is verstreken.',
        'X',
        {
          duration: 0, // 0 = unlimited
        }
      );
    }
    if (data.message == 'succeed') {
      this.hideSendButton = true;
      this.snackbar.open('De wijzigingen zijn opgeslagen.', 'X', {
        duration: 0, // 0 = unlimited
      });
    }
  }

  async uploadFiles(fileList: FileInfo[], type: string, formValues?: any) {
    const files: any[] = [];
    for (let i = 0; i < fileList.length; i++) {
      const fileInfo: FileInfo = fileList[i];
      const saveData: any = { ...fileList[i] };
      if (!fileInfo.downloadUrl) {
        const randomFileName = `${crypto.randomUUID()}-${fileInfo.file.name}`;
        let pathRef: StorageReference;
        switch (type) {
          case 'receipts':
            pathRef = ref(
              this.st.storage,
              `/${this.townshipId}/vouchers/${this.voucherId}/receipts/${randomFileName}`
            );
            break;
          default:
            pathRef = ref(
              this.st.storage,
              `/${this.townshipId}/vouchers/${this.voucherId}/isdeImages/${randomFileName}`
            );
            break;
        }
        console.log('pathref', pathRef);
        console.log('fileInfo', fileInfo);
        if (!fileInfo.name.endsWith('.pdf')) {
          var compressedFile: File = await imageCompression(fileInfo.file, {
            maxSizeMB: 2,
            maxWidthOrHeight: 1920,
          });
        }
        const result = await uploadBytes(
          pathRef,
          compressedFile ?? fileInfo.file
        );
        console.log('result', result);
        saveData.downloadUrl = await getDownloadURL(pathRef);
        console.log('picture.downloadUrl', saveData.downloadUrl);
      }
      switch (type) {
        case 'receipts':
          saveData.receiptValue = Number(
            `${formValues[fileInfo.name]}`.replace(',', '.')
          );
          break;
        default:
          break;
      }
      delete fileInfo.file; // removes file cause it's to big to send to functions
      files.push(saveData);
    }
    return files;
  }

  handleEnergyLabelChange(event) {
    this.hasEnergyLabel = event.value;
    if (this.hasEnergyLabel) {
      this.energyLabelForm.controls['energyLabel'].enable();
      this.badlyInsulatedPartsSelected = [];
    } else {
      this.energyLabelForm.controls['energyLabel'].disable();
      this.energyLabelForm.controls['energyLabel'].setValue(null);
    }
  }

  handleCheckboxChange(event, value) {
    if (event.checked) {
      this.badlyInsulatedPartsSelected.push(value);
    } else {
      this.badlyInsulatedPartsSelected.splice(
        this.badlyInsulatedPartsSelected.indexOf(value),
        1
      );
    }
  }

  valueAscOrder = (
    a: KeyValue<string, string>,
    b: KeyValue<string, string>
  ): number => {
    return a.value.localeCompare(b.value);
  };

  getValidatorErrorMessage(
    form: any,
    controlName: string,
    controlLabel = null
  ) {
    if (this.hasValidatorError(form, controlName)) {
      const control = form.controls[controlName];
      const errorType = Object.keys(control.errors)[0];
      let errorMessage = '';
      switch (errorType) {
        case 'required':
          errorMessage = 'Dit veld is verplicht';
          break;
        case 'pattern':
          const pattern = control.errors.pattern.requiredPattern;
          if (pattern == '^[a-zA-Z0-9]*$') {
            errorMessage = 'Dit veld mag alleen alfanumerieke tekens bevatten';
          }
          break;
        case 'numberInput':
          const labelArray = [
            'aantal m2',
            'rd/ug waarde',
            'vulgewicht (kg)',
            'aankoopwaarde',
          ];
          if (
            form.value[controlName].match('[,.]') &&
            labelArray.indexOf(controlLabel.toLowerCase()) !== -1
          ) {
            errorMessage = 'Twee decimalen achter de komma verplicht';
          } else {
            errorMessage = 'Incorrect getal ingevoerd';
          }
          break;
      }
      return errorMessage;
    }
  }

  hasValidatorError(form: any, controlName: string) {
    const control = form.controls[controlName];
    if (control.errors) {
      const errorCount = Object.keys(control.errors);
      return errorCount ? true : false;
    }
    return false;
  }
}
