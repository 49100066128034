import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
} from 'firebase/firestore';
import { environment } from 'src/environments/environment';
import {
  FileInfo,
  SubsidyOption,
  TownshipPublicSettings,
  Voucher,
  VoucherGroup,
} from 'src/app/interfaces';
import { fixTermsUrl, ucFirst, updateTheme } from 'src/app/globals';
import { httpsCallable } from 'firebase/functions';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  StorageReference,
  getDownloadURL,
  ref,
  uploadBytes,
} from 'firebase/storage';
import imageCompression from 'browser-image-compression';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { badlyInsulatedParts } from '../enums';
import { KeyValue } from '@angular/common';
import { db, functions, storage } from '../app.component';
import { CustomValidators } from 'angular-custom-validators';

@Component({
  selector: 'app-burden-of-proof-form',
  templateUrl: './burden-of-proof-form.component.html',
  styleUrls: ['./burden-of-proof-form.component.scss'],
  standalone: false,
})
export class BurdenOfProofFormComponent implements OnInit {
  highContrast: boolean = false;
  loaded: boolean = false;
  township: TownshipPublicSettings;
  townshipId: string;
  townshipName: string;
  townshipLogo: string;
  envLogo: string;
  termsUrl?: string;
  voucherId: string;
  voucher: Voucher;
  voucherGroup: VoucherGroup;
  postal: string;
  houseNumber: string;
  subsidyOptions: SubsidyOption[] = [];
  subsidyOptionsInVoucherGroup: SubsidyOption[] = [];
  selectedSubsidyOptions: any[] = [];
  receipts: FileInfo[] = [];
  picturesBefore: FileInfo[] = [];
  picturesDuring: FileInfo[] = [];
  picturesAfter: FileInfo[] = [];
  status: string;
  energyLabels: string[] = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'Geen energielabel',
  ];
  badlyInsulatedParts = badlyInsulatedParts;
  saving: boolean;
  hasBadEnergyLabel: boolean = false;
  allowedFileExtensions: string[] = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'application/pdf',
  ];
  infoForm: FormGroup = new FormGroup({
    acceptInfo: new FormControl(null, Validators.requiredTrue),
  });
  energyLabelForm: FormGroup = new FormGroup({
    energyLabel: new FormControl(null, [Validators.required]),
    badlyInsulatedParts: new FormControl([]),
  });
  selectedSubsidyOptionsForm: FormGroup = new FormGroup([]);
  receiptsForm: FormGroup = new FormGroup([]);
  completedForm: FormGroup = new FormGroup({
    complete: new FormControl(null, Validators.required),
  });

  ucFirst = ucFirst;
  step: number = 1;

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    private snackbar: MatSnackBar
  ) {}

  async ngOnInit(): Promise<void> {
    this.translate.setDefaultLang(environment.language);
    this.translate.use(environment.language);

    if (
      localStorage.getItem('highContrast') &&
      localStorage.getItem('highContrast') === '1'
    ) {
      this.highContrast = true;
    }

    this.route.params.subscribe(async (params) => {
      this.townshipName = params.townshipName;
      if (this.townshipName) {
        this.postal = params.postal;
        this.houseNumber = params.houseNumber;
        await this.getInfo(params.encryptedId);
      }
    });

    const subsidyOptionDocs = (
      await getDocs(
        query(
          collection(db, `/globalSettings/global/subsidyOptions`),
          orderBy('name')
        )
      )
    ).docs;

    subsidyOptionDocs.forEach((subDoc) => {
      const subsidyOption = {
        id: subDoc.id,
        ...(subDoc.data() as SubsidyOption),
      };
      this.subsidyOptions.push(subsidyOption);
    });
  }

  formValid() {
    if (this.status == 'Submitted') {
      return true;
    }
    switch (this.step) {
      case 1:
        return this.infoForm.valid;
      case 2:
        return this.energyLabelForm.valid;
      case 3:
        return (
          this.selectedSubsidyOptionsForm.valid &&
          this.selectedSubsidyOptions.length > 0
        );
      case 4:
        return this.receipts.length > 0 && this.receiptsForm.valid;
      case 5:
        return this.picturesBefore.length > 0;
      case 6:
        return this.picturesDuring.length > 0;
      case 7:
        return this.picturesAfter.length > 0;
      case 8:
        return this.completedForm.value.complete;
      default:
        return false;
    }
  }

  async getInfo(encryptedId: string) {
    const townshipNameDoc = (
      await getDoc(doc(db, `townshipNames/${this.townshipName}`))
    ).data();
    if (!townshipNameDoc.townshipId) {
      return;
    }
    this.townshipId = townshipNameDoc.townshipId;
    const townshipRef = doc(db, `township/${this.townshipId}/settings/public`);
    this.township = (
      await getDoc(townshipRef)
    ).data() as TownshipPublicSettings;
    if (!this.township) {
      return console.error('township not found');
    }
    updateTheme({
      primary: this.township.primaryColor,
      secondary: this.township.accentColor,
    });
    const callable = httpsCallable(functions, 'httpGetVoucherInfo', {
      timeout: 60000,
    });
    const result = await callable({
      townshipId: this.townshipId,
      encryptedId,
      postal: this.postal,
      houseNumber: this.houseNumber,
    });
    const resData = result.data as any;
    this.voucherId = resData.voucher.number;
    this.voucher = resData.voucher as Voucher;
    this.voucherGroup = resData.voucherGroup as VoucherGroup;
    if (!this.voucher) {
      return console.error('error getting voucher');
    }
    if (this.voucherGroup.subsidyOptions) {
      this.voucherGroup.subsidyOptions.forEach((id) => {
        this.subsidyOptionsInVoucherGroup.push(
          this.subsidyOptions.find((subOption) => {
            return subOption.id === id;
          })
        );
      });
    }
    if (this.voucher.burdenOfProofForm) {
      this.infoForm.controls.acceptInfo.setValue(true);
      this.voucher.burdenOfProofForm.subsidyOptions?.forEach((subsidyOption) =>
        this.updateSubsidyOption(true, subsidyOption, true)
      );
      this.picturesBefore = this.voucher.burdenOfProofForm.picturesBefore;
      this.picturesDuring = this.voucher.burdenOfProofForm.picturesDuring;
      this.picturesAfter = this.voucher.burdenOfProofForm.picturesAfter;
      this.voucher.burdenOfProofForm.receipts?.forEach((receipt) => {
        const receiptValue: string = receipt.receiptValue
          .toFixed(2)
          .toString()
          .replace('.', ',');
        this.receiptsForm.addControl(
          receipt.name,
          new FormControl(receiptValue, [
            CustomValidators.numberInput(false, false, 2),
            Validators.required,
          ])
        );
      });
      this.receipts = this.voucher.burdenOfProofForm.receipts;
      this.energyLabelForm.patchValue(this.voucher.burdenOfProofForm);
      if (!this.energyLabelForm.value.badlyInsulatedParts) {
        this.energyLabelForm.controls.badlyInsulatedParts.setValue([]);
        this.energyLabelForm.updateValueAndValidity();
      }
      this.handleEnergyLabelChange(this.energyLabelForm.value.energyLabel);
      if (
        this.voucher.burdenOfProofForm.status === 'Submitted' ||
        this.voucher.burdenOfProofForm.status === 'Accepted'
      ) {
        this.status = 'Submitted';
        this.completedForm.controls.complete.setValue(true);
        this.infoForm.disable();
        this.receiptsForm.disable();
        this.energyLabelForm.disable();
        this.selectedSubsidyOptionsForm.disable();
        this.completedForm.disable();
      }
    }
    this.townshipName = this.township.name;
    if (this.township.logoImageUrl) {
      this.townshipLogo = this.township.logoImageUrl;
    } else {
      if (this.township.voucherPrefix === 'groene') {
        this.townshipLogo = '../../assets/images/groenebon.png';
      } else if (this.township.voucherPrefix === 'toegangs') {
        this.townshipLogo = '../../assets/images/toegangsbon.png';
      } else if (this.township.voucherPrefix === 'duurzaamwonen') {
        this.townshipLogo = '../../assets/images/duurzaamwonen.png';
      } else {
        this.townshipLogo = '../../assets/images/lokalebon.png';
      }
    }
    if (this.township.voucherPrefix === 'groene') {
      this.envLogo = '../../assets/images/groenebon.png';
    } else if (this.township.voucherPrefix === 'toegangs') {
      this.envLogo = '../../assets/images/toegangsbon.png';
    } else if (this.township.voucherPrefix === 'duurzaamwonen') {
      this.envLogo = '../../assets/images/duurzaamwonen.png';
    } else {
      this.envLogo = '../../assets/images/lokalebon.png';
    }
    this.termsUrl = fixTermsUrl(this.township.termsUrl);
    if (this.termsUrl) {
      const termsLowerCase = this.termsUrl.toLocaleLowerCase();
      if (
        termsLowerCase.includes('groeneapp') ||
        termsLowerCase.includes('groenebon')
      ) {
        this.termsUrl = null;
      }
    }
    this.loaded = true;
  }

  updateSubsidyOption(
    checked: boolean,
    option: any,
    existingData: boolean = false
  ) {
    if (checked) {
      this.selectedSubsidyOptions.push(option);
      this.selectedSubsidyOptionsForm.addControl(
        `${option.name}DIY`,
        new FormControl(existingData ? option.diy : true, [Validators.required])
      );
      if (option.name === 'Warmtepomp') {
        this.selectedSubsidyOptionsForm.addControl(
          `${option.name}MainUnit`,
          new FormControl(existingData ? option.mainUnit : '', [])
        );
        if (typeof option.code !== 'undefined') {
          this.selectedSubsidyOptionsForm.addControl(
            `${option.name}Code`,
            new FormControl(existingData ? option.code : '', [
              Validators.pattern('^[a-zA-Z0-9]*$'),
              Validators.required,
            ])
          );
        }
        if (typeof option.secondaryUnit !== 'undefined') {
          this.selectedSubsidyOptionsForm.addControl(
            `${option.name}SecondaryUnit`,
            new FormControl(existingData ? option.secondaryUnit : '', [
              CustomValidators.numberInput(false, false, 2),
            ])
          );
        }
      } else {
        this.selectedSubsidyOptionsForm.addControl(
          `${option.name}MainUnit`,
          new FormControl(existingData ? option.mainUnit : '', [
            Validators.required,
            CustomValidators.numberInput(false, false, 2),
          ])
        );
        const mainUnitFormControl =
          this.selectedSubsidyOptionsForm.controls[`${option.name}MainUnit`];
        if (option.name === 'PV(T)-systeem / Zonnepanelen') {
          mainUnitFormControl.removeValidators(
            CustomValidators.numberInput(false, false, 2)
          );
          mainUnitFormControl.addValidators(
            CustomValidators.numberInput(false, false, -1)
          );
          mainUnitFormControl.updateValueAndValidity();
        } else if (
          option.name === 'Klushulp' ||
          option.name === 'Schouw / Advies'
        ) {
          mainUnitFormControl.removeValidators(
            CustomValidators.numberInput(false, false, 2)
          );
          mainUnitFormControl.updateValueAndValidity();
        }
        if (typeof option.code !== 'undefined') {
          this.selectedSubsidyOptionsForm.addControl(
            `${option.name}Code`,
            new FormControl(existingData ? option.code : '', [
              Validators.pattern('^[a-zA-Z0-9]*$'),
            ])
          );
        }
        if (typeof option.secondaryUnit !== 'undefined') {
          this.selectedSubsidyOptionsForm.addControl(
            `${option.name}SecondaryUnit`,
            new FormControl(existingData ? option.secondaryUnit : '', [
              CustomValidators.numberInput(false, false, 2),
            ])
          );
        }
      }
    } else {
      const index = this.selectedSubsidyOptions.findIndex((object) => {
        return object.id == option.id;
      });
      if (index !== -1) {
        this.selectedSubsidyOptions.splice(
          this.selectedSubsidyOptions.findIndex((object) => {
            return object.id == option.id;
          }),
          1
        );
        this.selectedSubsidyOptionsForm.removeControl(`${option.name}MainUnit`);
        this.selectedSubsidyOptionsForm.removeControl(`${option.name}Code`);
        this.selectedSubsidyOptionsForm.removeControl(
          `${option.name}SecondaryUnit`
        );
        this.selectedSubsidyOptionsForm.removeControl(`${option.name}DIY`);
      }
    }
  }

  subsidyOptionIsChecked(name: string) {
    let exists = false;
    this.selectedSubsidyOptions.forEach((option) => {
      if (option.name == name) exists = true;
    });
    return exists;
  }

  getSubsidyOptionFormName(option: string) {
    return `subOpt.${option}`;
  }

  getCurrencyString(number: number) {
    let newNumber: string;
    if (!number.toString().includes('.')) {
      // this isnt a decimal
      newNumber = `${number.toString()},-`;
    } else {
      newNumber = `${number.toFixed(2)}`;
      newNumber = newNumber.replace('.', ',');
    }
    return newNumber;
  }

  setHighContrast() {
    this.highContrast = this.highContrast ? false : true;
    localStorage.setItem('highContrast', this.highContrast ? '1' : '0');
  }

  openFileInput(htmlId: string) {
    const element: HTMLElement = document.getElementById(htmlId) as HTMLElement;
    element.click();
  }

  removeFile(file: FileInfo, type: string) {
    switch (type) {
      case 'receipts':
        this.receipts.splice(this.receipts.indexOf(file), 1);
        this.receiptsForm.removeControl(file.name);
        break;
      case 'picturesBefore':
        this.picturesBefore.splice(this.picturesBefore.indexOf(file), 1);
        break;
      case 'picturesDuring':
        this.picturesDuring.splice(this.picturesDuring.indexOf(file), 1);
        break;
      case 'picturesAfter':
        this.picturesAfter.splice(this.picturesAfter.indexOf(file), 1);
        break;
    }
  }
  selectedFiles(event: any, type: string) {
    const allowedFileExtensions = this.allowedFileExtensions.slice();
    if (type != 'receipts') {
      allowedFileExtensions.splice(
        allowedFileExtensions.indexOf('application/pdf'),
        1
      );
    }
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i] as File;
      const fileInfo: FileInfo = {
        name: file.name.replace(
          /\.[a-zA-Z0-9]*/,
          file.name.match(/\.[a-zA-Z0-9]*/)[0].toLowerCase()
        ),
        file: file,
      };
      if (allowedFileExtensions.indexOf(fileInfo.file.type) == -1) {
        this.snackbar.open(
          `De bestanden moeten een ${
            type == 'receipts' ? 'pdf, ' : ''
          }jpg, jpeg of png zijn`,
          'X',
          {
            duration: 10000,
          }
        );
        event.target.value = null;
        return;
      }
      switch (type) {
        case 'receipts':
          if (this.arrayContains(this.receipts, fileInfo)) {
            return;
          }
          this.receiptsForm.addControl(
            fileInfo.name,
            new FormControl('', [
              CustomValidators.numberInput(false, false, 2),
              Validators.required,
            ])
          );
          this.receipts.push(fileInfo);
          break;
        case 'picturesBefore':
          if (this.arrayContains(this.picturesBefore, fileInfo)) {
            return;
          }
          this.picturesBefore.push({
            ...fileInfo,
            previewUrl: URL.createObjectURL(fileInfo.file),
          });
          break;
        case 'picturesDuring':
          if (this.arrayContains(this.picturesDuring, fileInfo)) {
            return;
          }
          this.picturesDuring.push({
            ...fileInfo,
            previewUrl: URL.createObjectURL(fileInfo.file),
          });
          break;
        case 'picturesAfter':
          if (this.arrayContains(this.picturesAfter, fileInfo)) {
            return;
          }
          this.picturesAfter.push({
            ...fileInfo,
            previewUrl: URL.createObjectURL(fileInfo.file),
          });
          break;
      }
    }
    event.target.value = null;
  }

  arrayContains(array: FileInfo[], fileInfo: FileInfo) {
    let exists = false;
    array.forEach((e) => {
      if (e.name === fileInfo.name) {
        exists = true;
      }
    });
    return exists;
  }

  async submit(status) {
    if (this.saving) {
      return;
    }
    this.saving = true;
    const receiptsFormValues = this.receiptsForm.value;
    const subsidyOptionsFormValues = this.selectedSubsidyOptionsForm.value;
    const energyLabelFormValues = this.energyLabelForm.value;
    this.receiptsForm.disable();
    this.selectedSubsidyOptionsForm.disable();
    this.energyLabelForm.disable();
    this.completedForm.disable();
    const receipts = await this.uploadFiles(
      this.receipts,
      'receipts',
      receiptsFormValues
    );
    let totalReceiptValue = 0;
    receipts.forEach(
      (receipt) =>
        (totalReceiptValue = totalReceiptValue + receipt.receiptValue)
    );
    this.selectedSubsidyOptions.forEach((option) => {
      option.mainUnit = subsidyOptionsFormValues[`${option.name}MainUnit`];
      option.diy = subsidyOptionsFormValues[`${option.name}DIY`];
      if (
        option.secondaryUnit ||
        option.secondaryUnit !=
          subsidyOptionsFormValues[`${option.name}SecondaryUnit`]
      ) {
        option.secondaryUnit =
          subsidyOptionsFormValues[`${option.name}SecondaryUnit`];
      }
      if (
        subsidyOptionsFormValues[`${option.name}Code`] &&
        (option.code ||
          option.code != subsidyOptionsFormValues[`${option.name}Code`])
      ) {
        option.code =
          subsidyOptionsFormValues[`${option.name}Code`].toUpperCase();
      } else {
        option.code = '';
      }
    });
    const submitObj: any = {
      townshipId: this.townshipId,
      voucherId: this.voucherId,
      postal: this.postal,
      houseNumber: this.houseNumber,
      status: status,
      subsidyOptions: this.selectedSubsidyOptions,
      receiptValue: totalReceiptValue,
      receipts: receipts,
      picturesBefore: await this.uploadFiles(
        this.picturesBefore,
        'picturesBefore'
      ),
      picturesDuring: await this.uploadFiles(
        this.picturesDuring,
        'picturesDuring'
      ),
      picturesAfter: await this.uploadFiles(
        this.picturesAfter,
        'picturesAfter'
      ),
      ...energyLabelFormValues,
    };
    const callable = httpsCallable(functions, 'httpSubmitBurdenOfProofForm', {
      timeout: 60000,
    });
    const result = await callable(submitObj);
    const data = result.data as any;
    if (data.message == 'error_too_late') {
      this.snackbar.open(
        'De tijd om het bewijslast aan te leveren is verstreken.',
        'X',
        {
          duration: 0, // 0 = unlimited
        }
      );
    }
    if (data.message == 'succeed') {
      if (status == 'Submitted') {
        this.snackbar.open(
          'Je formulier is ingediend. Je ontvangt hiervan een e-mail ter bevestiging.',
          'X',
          {
            duration: 0, // 0 = unlimited
          }
        );
      } else {
        this.snackbar.open(
          'Je formulier is als concept opgeslagen. Je ontvangt hiervan een e-mail.',
          'X',
          {
            duration: 0, // 0 = unlimited
          }
        );
      }
      if (status == 'Concept') {
        this.receiptsForm.enable();
        this.selectedSubsidyOptionsForm.enable();
        this.energyLabelForm.enable();
        this.completedForm.enable();
      }
      this.status = status;
      this.saving = false;
    }
  }

  async uploadFiles(fileList: FileInfo[], type: string, formValues?: any) {
    const files: any[] = [];
    for (let i = 0; i < fileList.length; i++) {
      const fileInfo: FileInfo = fileList[i];
      const saveData: any = { ...fileList[i] };
      if (!fileInfo.downloadUrl) {
        const randomFileName = `${crypto.randomUUID()}-${fileInfo.file.name}`;
        let pathRef: StorageReference;
        switch (type) {
          case 'receipts':
            pathRef = ref(
              storage,
              `/${this.townshipId}/vouchers/${this.voucherId}/receipts/${randomFileName}`
            );
            break;
          default:
            pathRef = ref(
              storage,
              `/${this.townshipId}/vouchers/${this.voucherId}/isdeImages/${randomFileName}`
            );
            break;
        }
        if (!fileInfo.name.endsWith('.pdf')) {
          var compressedFile: File = await imageCompression(fileInfo.file, {
            maxSizeMB: 2,
            maxWidthOrHeight: 1920,
          });
        }
        const result = await uploadBytes(
          pathRef,
          compressedFile ?? fileInfo.file
        );
        saveData.downloadUrl = await getDownloadURL(pathRef);
      }
      switch (type) {
        case 'receipts':
          saveData.receiptValue = Number(
            `${formValues[fileInfo.name]}`.replace(',', '.')
          );
          break;
        default:
          break;
      }
      delete saveData.previewUrl; // only used to display preview of the files that are going to be uploaded
      delete saveData.file; // removes file cause it's to big to send to functions
      files.push(saveData);
    }
    return files;
  }

  handleEnergyLabelChange(value) {
    const formControl = this.energyLabelForm.controls.badlyInsulatedParts;
    if (value == 'C' || value == 'Geen energielabel') {
      this.hasBadEnergyLabel = true;
      formControl.addValidators([Validators.minLength(2), Validators.required]);
    } else {
      this.hasBadEnergyLabel = false;
      formControl.clearValidators();
    }
    formControl.updateValueAndValidity();
  }

  handleCheckboxChange(event, value) {
    const formControl = this.energyLabelForm.controls.badlyInsulatedParts;
    if (event.checked) {
      formControl.setValue([...formControl.value, value]);
    } else {
      const values = formControl.value;
      values.splice(values.indexOf(value), 1);
      formControl.setValue(values);
    }
  }

  valueAscOrder = (
    a: KeyValue<string, string>,
    b: KeyValue<string, string>
  ): number => {
    return a.value.localeCompare(b.value);
  };

  getValidatorErrorMessage(
    form: any,
    controlName: string,
    controlLabel = null
  ) {
    if (this.hasValidatorError(form, controlName)) {
      const control = form.controls[controlName];
      const errorType = Object.keys(control.errors)[0];
      let errorMessage = '';
      switch (errorType) {
        case 'required':
          errorMessage = 'Dit veld is verplicht';
          break;
        case 'pattern':
          const pattern = control.errors.pattern.requiredPattern;
          if (pattern == '^[a-zA-Z0-9]*$') {
            errorMessage = 'Dit veld mag alleen alfanumerieke tekens bevatten';
          }
          break;
        case 'numberInput':
          const labelArray = [
            'aantal m2',
            'rd/ug waarde',
            'vulgewicht (kg)',
            'aankoopwaarde',
          ];
          if (
            form.value[controlName].match('[,.]') &&
            labelArray.indexOf(controlLabel.toLowerCase()) !== -1
          ) {
            errorMessage = 'Twee decimalen achter de komma verplicht';
          } else {
            errorMessage = 'Incorrect getal ingevoerd';
          }
          break;
      }
      return errorMessage;
    }
  }

  hasValidatorError(form: any, controlName: string) {
    const control = form.controls[controlName];
    if (control.errors) {
      const errorCount = Object.keys(control.errors);
      return errorCount ? true : false;
    }
    return false;
  }
}
