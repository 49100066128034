<!-- To use a transparent background with this iframe, use <iframe src="stuff.htm" allowtransparency="true"></iframe> -->
<!-- <div *ngIf="!awaitingResponse else loading"> -->
<div
  class="wrapper"
  *ngIf="township; else loading"
>
  <mat-card class="group-link">
    <div class="img-wrapper">
      <img
        [src]="headerImg"
        alt="logo"
        class="logo"
      />
    </div>
    <mat-card-content>
      <p class="header">{{ groupLinkTxt }}</p>
      <p>Kies een campagne</p>
      <div style="display: flex; flex-direction: column">
        <a
          mat-flat-button
          style="margin-top: 15px; font-size: 15px"
          *ngFor="let voucherGroup of sortBy('name')"
          href="{{ voucherGroup.url }}"
        >
          {{ voucherGroup.name }}
        </a>
      </div>
    </mat-card-content>
    <!-- <mat-card-actions align="end">
      <button
        [class.spinner]="waiting"
        mat-raised-button
        color="primary"
        [disabled]="!groupLinkForm.valid || waiting"
        (click)="navigate()"
      >
        VERDER
      </button>
    </mat-card-actions> -->
  </mat-card>
</div>
<!-- </div> -->
<ng-template #loading>
  <div class="loading-wrapper">
    <div class="loading">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</ng-template>
