import { HighContrastModeDetector } from '@angular/cdk/a11y';
import { BidiModule } from '@angular/cdk/bidi';
import * as i0 from '@angular/core';
import { InjectionToken, inject, NgModule } from '@angular/core';

/**
 * Injection token that configures whether the Material sanity checks are enabled.
 * @deprecated No longer used and will be removed.
 * @breaking-change 21.0.0
 */
const MATERIAL_SANITY_CHECKS = new InjectionToken('mat-sanity-checks', {
  providedIn: 'root',
  factory: () => true
});
/**
 * Module that captures anything that should be loaded and/or run for *all* Angular Material
 * components. This includes Bidi, etc.
 *
 * This module should be imported to each top-level component module (e.g., MatTabsModule).
 * @deprecated No longer used and will be removed.
 * @breaking-change 21.0.0
 */
class MatCommonModule {
  constructor() {
    // While A11yModule also does this, we repeat it here to avoid importing A11yModule
    // in MatCommonModule.
    inject(HighContrastModeDetector)._applyBodyHighContrastModeCssClasses();
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.2.0",
    ngImport: i0,
    type: MatCommonModule,
    deps: [],
    target: i0.ɵɵFactoryTarget.NgModule
  });
  static ɵmod = i0.ɵɵngDeclareNgModule({
    minVersion: "14.0.0",
    version: "19.2.0",
    ngImport: i0,
    type: MatCommonModule,
    imports: [BidiModule],
    exports: [BidiModule]
  });
  static ɵinj = i0.ɵɵngDeclareInjector({
    minVersion: "12.0.0",
    version: "19.2.0",
    ngImport: i0,
    type: MatCommonModule,
    imports: [BidiModule, BidiModule]
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.2.0",
  ngImport: i0,
  type: MatCommonModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [BidiModule],
      exports: [BidiModule]
    }]
  }],
  ctorParameters: () => []
});
export { MatCommonModule as M, MATERIAL_SANITY_CHECKS as a };
