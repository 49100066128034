<!-- To use a transparent background with this iframe, use <iframe src="stuff.htm" allowtransparency="true"></iframe> -->
<!-- <div *ngIf="!awaitingResponse else loading"> -->
<div
  class="wrapper"
  role="main"
  *ngIf="loaded; else loading"
>
  <mat-card class="saldo-checker">
    <div class="imgWrapper">
      <img
        [src]="townshipImage"
        alt="logo"
        class="logo"
      />
    </div>
    <mat-card-content>
      <div
        class="title"
        role="heading"
        aria-level="1"
      >
        {{ 'saldo-checker.header-message' | translate }}
      </div>
      <p class="info">
        {{ 'saldo-checker.info-message' | translate : environmentObj }}
      </p>
      <form
        class="saldo-form"
        aria-label="zoeken op bonnummer"
        (ngSubmit)="checkSaldo()"
        [formGroup]="saldoCheckerForm"
      >
        <mat-form-field class="form-field-width">
          <mat-label
            >{{ environmentObj.value
            }}{{ ('input.coupon' | translate).toLowerCase() }}</mat-label
          >
          <input
            formControlName="voucher"
            autocomplete="off"
            matInput
            required
          />
          <mat-error>
            {{ getError('voucher') }}
          </mat-error>
          <mat-error>
            {{ error }}
          </mat-error>
        </mat-form-field>
        <div
          *ngIf="
            this.saldoCheckerForm.controls.voucher.pristine &&
            this.saldoCheckerForm.controls.voucher.untouched
          "
          class="info-voucher"
        >
          {{ 'saldo-checker.info-voucher' | translate : environmentObj }}
        </div>
      </form>
      <!-- <div
        *ngIf="!value"
        class="recaptcha"
      >
        <re-captcha
          (resolved)="resolved($event)"
          siteKey="{{ recaptchaKey }}"
        >
        </re-captcha>
      </div> -->
      <div
        *ngIf="value"
        class="balance-check"
      >
        <div class="valid">{{ 'saldo-checker.start-balance' | translate }}</div>
        <p class="info">€{{ originalValue }}</p>
        <div class="balance">
          {{ 'saldo-checker.current-balance' | translate }}
        </div>
        <p class="balance-text">€{{ value }}</p>
        <div class="valid">{{ 'saldo-checker.validity' | translate }}</div>
        <p
          class="result-date"
          *ngIf="resultDate"
        >
          {{ resultDate }}
        </p>
      </div>
      <!-- <p class="result-string">{{ resultString }}</p> -->
    </mat-card-content>
    <mat-card-actions class="mat-card-actions">
      <!-- <button
        class="request-button"
        [class.spinner]="awaitingResponse"
        (click)="checkSaldo()"
        [disabled]="!saldoCheckerForm.valid || !recaptcha"
        mat-flat-button
        *ngIf="!value"
      > -->
      <button
        class="request-button"
        [class.spinner]="awaitingResponse"
        (click)="checkSaldo()"
        [disabled]="!saldoCheckerForm.valid"
        mat-flat-button
        *ngIf="!value"
      >
        {{ 'request-digital.button.request' | translate }}
      </button>
      <button
        class="request-button"
        [class.spinner]="awaitingResponse"
        (click)="reset()"
        mat-flat-button
        *ngIf="value"
      >
        {{ 'request-digital.button.again' | translate }}
      </button>
    </mat-card-actions>
  </mat-card>
</div>
<!-- </div> -->
<ng-template #loading>
  <div class="loading-wrapper">
    <div class="loading">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</ng-template>
