<h1
  mat-dialog-title
  [ngStyle]="
    highContrast ? { 'filter': 'saturate(0)' } : { 'filter': 'saturate(1)' }
  "
>
  {{ 'verify.title' | translate }}
</h1>
<div
  mat-dialog-content
  [ngStyle]="
    highContrast ? { 'filter': 'saturate(0)' } : { 'filter': 'saturate(1)' }
  "
>
  <form
    class="verify-form"
    (ngSubmit)="submit()"
    [formGroup]="verifySmsForm"
  >
    <div *ngIf="!phonenumberSubmitted">
      <div style="margin-bottom: 10px">{{ 'verify.sms_info' | translate }}</div>
      <mat-form-field>
        <mat-label>{{ 'verify.number' | translate }}</mat-label>
        <input
          cdkFocusInitial
          placeholder="0612345678"
          minlength="10"
          maxlength="10"
          formControlName="phonenumber"
          autocomplete="off"
          matInput
          required
          (keydown.space)="$event.preventDefault()"
        />
        <mat-error
          *ngIf="verifySmsForm.controls.phonenumber.hasError('required')"
        >
          {{ 'errors.required' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div *ngIf="phonenumberSubmitted">
      <div style="margin-bottom: 10px">{{ 'verify.info' | translate }}</div>
      <mat-form-field>
        <mat-label>{{ 'verify.code' | translate }}</mat-label>
        <input
          cdkFocusInitial
          placeholder="123456"
          formControlName="code"
          autocomplete="off"
          matInput
          required
          (keydown.space)="$event.preventDefault()"
        />
        <mat-error *ngIf="verifySmsForm.controls.code.hasError('required')">
          {{ 'errors.required' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
<mat-dialog-actions
  align="end"
  [ngStyle]="
    highContrast ? { 'filter': 'saturate(0)' } : { 'filter': 'saturate(1)' }
  "
>
  <button
    mat-button
    class="cancel-button"
    mat-dialog-close
  >
    {{ 'verify.cancel' | translate }}
  </button>
  <button
    mat-flat-button
    class="submit-button"
    (click)="submit()"
  >
    {{ 'verify.submit' | translate }}
  </button>
</mat-dialog-actions>
