export enum VoucherStatus {
  available = `available`,
  activated = `activated`,
  partiallyClaimed = `partiallyClaimed`,
  claimed = `claimed`,
  paid = `paid`,
  expired = `expired`,
  blocked = `blocked`,
}

export enum badlyInsulatedParts {
  flatRoof = `Dak (hellend/plat): slecht geïsoleerd als dikte isolatie < 9 cm. `,
  roofAttic = `Zolder-/vlieringvloer: slecht geïsoleerd als er geen isolatie aanwezig is (als alternatief voor dakisolatie, alleen bij een onverwarmde zolder en gesloten vlieringluik of gesloten toegangsdeur).`,
  facade = `Gevel: slecht geïsoleerd als geen spouwmuurisolatie, buitengevelisolatie of voorzetwand aanwezig is.`,
  floor = `Vloer/bodem: slecht geïsoleerd als isolatie < 5 cm.`,
  glas = `Glas: slecht geïsoleerd als sprake is van enkel glas, oud dubbel glas of HR-glas.`,
}

export enum BurdenOfProofStatus {
  Submitted = 'Ingediend',
  Rejected = 'Afgekeurd',
  Accepted = 'Goedgekeurd',
  Concept = 'Concept',
}

export enum AmbitionLevels {
  bronze = 'Brons',
  silver = 'Zilver',
  gold = 'Goud',
  platinum = 'Platina',
}

export enum MeasuresList {
  roofInsulation = 'Dakisolatie',
  facadeInsulation = 'Gevelisolatie',
  glassInsulation = 'Glasisolatie',
  floorInsulation = 'Vloerisolatie',
  solarPanels = 'Zonnepanelen',
  heatingPump = 'Warmtepomp',
  solarBoiler = 'Zonneboiler',
  heatRecovery = 'Warmteterugwinning',
}
