/* src/app/activate-voucher/activate-voucher.component.scss */
.wrapper.is-embedded {
  max-height: none;
}
.wrapper {
  overflow-y: auto;
  max-height: 100vh;
  overflow-x: clip;
  background: none transparent;
}
.wrapper .terms-title {
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: bold;
}
.wrapper .general-terms {
  position: relative;
  top: -16px;
  margin: 24px 0 8px 0;
}
.wrapper .general-terms .terms-link-info::after {
  width: 210px;
  content: "Door op deze link te klikken wordt er een nieuw tabblad geopend.";
}
.wrapper .general-terms .title {
  background-color: var(--primary);
  color: var(--primary-contrast);
}
.wrapper .voucher-terms mat-label {
  color: rgba(0, 0, 0, 0.6) !important;
}
.wrapper .voucher-terms textarea {
  color: rgba(0, 0, 0, 0.87) !important;
}
.wrapper .terms-checkbox {
  position: relative;
  top: -16px;
  margin-bottom: 8px;
}
.wrapper .activate-wrapper {
  margin: 12px auto;
  box-shadow: none;
}
.wrapper .activate-wrapper .img-wrapper {
  text-align: center;
}
.wrapper .activate-wrapper .img-wrapper .logo {
  height: 90px;
  content: var(--logo-fullwidth);
  margin-top: -16px;
  margin-bottom: 12px;
}
.wrapper .activate-wrapper .title {
  margin-bottom: 16px;
  font-size: 26px;
  text-align: center;
  color: var(--primary);
}
.wrapper .activate-wrapper .title-embedded {
  margin-bottom: 16px;
  font-size: 26px;
  text-align: center;
  color: #1a1a17;
  font-family: Viga;
  font-size: 48px;
}
.wrapper .activate-wrapper .hint-text {
  height: 8px;
  align-items: flex-end;
}
.wrapper .activate-wrapper .hint-text .mat-hint {
  height: 17px;
  float: right;
  position: relative;
  bottom: 16px;
  right: 0px;
}
.wrapper .activate-wrapper .statistics {
  left: 0;
  right: 0;
  margin: auto;
  top: 40px;
  max-width: 200px;
}
.wrapper .activate-wrapper .statistics .statistic {
  font-size: 50px;
  text-align: center;
  color: var(--primary);
}
.wrapper .activate-wrapper .statistics .statistic-embedded {
  font-size: 98px;
  text-align: center;
  font-family: Viga;
  background-color: var(--primary);
  color: var(--primary-contrast);
}
.wrapper .activate-wrapper .statistics .subtitle {
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}
.wrapper .activate-wrapper .statistics .subtitle-embedded {
  font-size: 28px;
  text-transform: uppercase;
  text-align: center;
  color: #1c1c18;
}
.wrapper .activate-wrapper form {
  margin-top: 24px;
}
.wrapper .activate-wrapper .actions {
  bottom: 16px;
  right: 16px;
  display: flex;
  justify-content: space-between;
}
.wrapper .activate-wrapper .actions button {
  text-transform: uppercase;
}
.wrapper .requestForm button {
  margin-right: 12px;
}
.wrapper .requestForm mat-card-content {
  margin-bottom: 36px;
}
.wrapper .questionnaire {
  width: 100%;
}
.wrapper .questionnaire form {
  margin: 16px auto;
}
.wrapper .questionnaire mat-form-field {
  width: 100%;
}
.wrapper .questionnaire .questionnaire-title {
  font-size: 18px;
  font-weight: bold;
}
.wrapper .questionnaire .questionnaire-question {
  margin-bottom: 16px;
}
.wrapper .acitvateDateNotPassed {
  margin-top: 6px;
}
.wrapper .acitvateDateNotPassed span {
  color: red;
}
.loading-wrapper {
  height: 100vh;
  width: 100vw;
}
.loading-wrapper .loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.loading-wrapper.passport {
  height: calc(100vh - 320px);
  width: calc(100vw - 50px);
}
.loading-wrapper.find-voucher-groups {
  height: calc(100vh - 345px);
  width: calc(100vw - 50px);
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}
.disabled-inputs ::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline,
.disabled-inputs ::ng-deep .mat-form-field-label,
.disabled-inputs ::ng-deep .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline,
.disabled-inputs ::ng-deep .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick,
.disabled-inputs #postal,
.disabled-inputs #houseNumber,
.disabled-inputs #houseNumberAddition {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: default;
}
.flex-class {
  flex: 1 1 100%;
  box-sizing: border-box;
  max-width: 100%;
}
.house-number-input {
  width: 60%;
  padding-right: 6px;
  box-sizing: border-box;
}
.house-number-addition-input {
  width: 40%;
  padding-left: 6px;
  box-sizing: border-box;
}
.activate-voucher-form {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.activate-voucher-form .fieldset {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.formfield-width-100 {
  width: 100%;
}
/*# sourceMappingURL=activate-voucher.component.css.map */
