import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GroupLink, TownshipPublicSettings, VoucherGroup } from '../interfaces';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  getCurrencyString,
  fixTermsUrl,
  calculateTextColor,
  getTownshipColors,
  getTermsUrl,
} from '../globals';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { VerifySmsComponent } from './dialogs/verify-sms/verify-sms.component';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ActivateVoucherComponentData } from '../activate-voucher/activate-voucher.component';
import { CustomValidators } from '../validators/custom-validators';

@Component({
  selector: 'app-find-voucher-groups',
  templateUrl: './find-voucher-groups.component.html',
  styleUrls: ['./find-voucher-groups.component.scss'],
})
export class FindVoucherGroupsComponent implements OnInit {
  loaded: boolean = false;
  awaitingResponse: boolean;
  noActions: boolean = false;
  nonLinkedSearch: boolean;
  errorMessage: string;
  townshipId: string;
  voucherGroupId: string;
  township: TownshipPublicSettings;
  townshipName: string;
  voucherGroups: VoucherGroup[];
  groupLinkedVoucherGroups: VoucherGroup[];
  voucherGroupGroupLinks: any[];
  filteredVoucherGroups: VoucherGroup[] = [];
  groupLinksFullInfo: any[];
  groupLinks: GroupLink[];
  checked: boolean;
  townshipLogo: string;
  termsUrl?: string;
  savedFormInfo: {
    postal: string;
    houseNumber: string;
    houseNumberAddition: string;
  };
  addressString: string = '';
  phonenumber: string;
  addressForm = new FormGroup({
    postal: new FormControl('', Validators.required),
    houseNumber: new FormControl('', [
      Validators.required,
      CustomValidators.numberInput(false, false, 0),
    ]),
    houseNumberAddition: new FormControl(''),
  });
  verificationForm: FormGroup = new FormGroup({});
  voucherGroupForm = new FormGroup({
    voucherGroup: new FormControl(null, Validators.required),
  });
  termsForm = new FormGroup({
    acceptTerms: new FormControl(null, Validators.requiredTrue),
  });
  theme: any = {};

  highContrast: boolean = false;
  verificationNeeded: boolean = false;
  preparingRequest: boolean = false;

  getCurrencyString = getCurrencyString;
  @ViewChild('stepper') stepper: MatStepper;
  activateVoucherComponentData: ActivateVoucherComponentData;

  constructor(
    private route: ActivatedRoute,
    public db: AngularFirestore,
    private translate: TranslateService,
    private fns: AngularFireFunctions,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.translate.setDefaultLang(environment.language);
    this.translate.use(environment.language);

    if (
      localStorage.getItem('highContrast') &&
      localStorage.getItem('highContrast') === '1'
    ) {
      this.highContrast = true;
    }

    this.route.params.subscribe(async (params) => {
      console.log('params', params);
      this.townshipName = params.townshipName;
      if (this.townshipName) {
        await this.getInfo();
      } else {
        this.nonLinkedSearch = true;
      }
      if (params.customParams) {
        const customParamsSplit = params.customParams.split('*');
        if (customParamsSplit.length < 2) {
          // Not an adress param
          if (params.customParams === 'prepare') this.preparingRequest = true;
          return;
        }
        const patchObj = {
          postal: customParamsSplit[0],
          houseNumber: customParamsSplit[1],
          houseNumberAddition: customParamsSplit[2],
        };
        this.addressForm.patchValue(patchObj);
        await this.findVoucherGroups();
      }
    });
  }

  async getInfo() {
    if (!this.townshipId) {
      const townshipNameDoc = (
        await getDoc(
          doc(this.db.firestore, `townshipNames/${this.townshipName}`)
        )
      ).data();
      console.log('townshipNameDoc', townshipNameDoc);
      if (!townshipNameDoc.townshipId) {
        return;
      }
      this.townshipId = townshipNameDoc.townshipId;
    }
    this.township = (
      await getDoc(
        doc(this.db.firestore, `township/${this.townshipId}/settings/public`)
      )
    ).data() as TownshipPublicSettings;
    console.log('township', this.township);
    this.townshipName = this.township.name;
    this.theme = await getTownshipColors(this.township);
    this.loaded = true;
  }

  async findVoucherGroups(
    pin?: string,
    phonenumber?: string,
    verificationCode?: string
  ) {
    if (this.addressForm.valid && !this.awaitingResponse) {
      if (!pin) {
        this.verificationNeeded = false;
      }
      const form = this.addressForm.value;
      console.log('form', form);
      this.awaitingResponse = true;
      const postal = (form.postal as string).toUpperCase().replace(/\s+/g, '');
      console.log('test', postal);
      const houseNumber = form.houseNumber.toString();
      const houseNumberAddition = form.houseNumberAddition
        ? (form.houseNumberAddition as string)
        : null;
      this.addressString = postal.concat(
        ' ',
        houseNumber,
        houseNumberAddition ?? ''
      );
      console.log(this.addressString);
      const callable = this.fns.httpsCallable('httpSearchVoucherGroups');
      console.log('calling now');
      if (this.nonLinkedSearch) {
        this.townshipId = undefined;
        this.voucherGroups = undefined;
      }

      const result = await lastValueFrom(
        callable({
          townshipId: this.townshipId,
          postal,
          houseNumber,
          houseNumberAddition,
          pin,
          phonenumber: phonenumber,
          verificationCode: verificationCode,
        })
      );
      console.log('result', result);
      if (result.townshipId) {
        this.townshipId = result.townshipId;
        await this.getInfo();
      }
      this.awaitingResponse = false;

      if (result.status === 'pin') {
        if (this.stepper.selectedIndex == 1) {
          this.verificationForm.controls.pin.setErrors({
            'incorrectPin': true,
          });
          return null;
        }
        this.verificationNeeded = true;
        if (this.verificationForm.controls)
          this.verificationForm = new FormGroup({
            pin: new FormControl(null, Validators.required),
          });
        return this.stepper.next();
      } else if (result.status === 'address_not_found_in_system') {
        return (this.errorMessage =
          'find-voucher-group.error_address_not_found');
      } else if (
        result.status === 'smsVerificationRequired' ||
        result.status === 'verification_code_sent'
      ) {
        return this.verifySms(result.status);
      } else if (result.status === 'phonenumber_does_not_match') {
        return (this.errorMessage =
          'find-voucher-group.error_matching_phonenumber');
      } else if (
        result.status === 'phonenumber_already_coupled_to_other_address'
      ) {
        return (this.errorMessage =
          'find-voucher-group.error_coupled_phonenumber');
      }

      this.groupLinksFullInfo = []; // all grouplinks in a township
      const allGroupLinks = await getDocs(
        collection(this.db.firestore, `township/${this.townshipId}/groupLinks`)
      );
      allGroupLinks.forEach((groupLinkInfoDoc) => {
        const groupLinkInfo = groupLinkInfoDoc.data();
        if (!groupLinkInfo.order) groupLinkInfo.order = 1;
        this.groupLinksFullInfo.push(groupLinkInfo);
      });
      this.groupLinksFullInfo.push({
        name: 'none',
        text: 'Geen',
        order: 10000,
      });
      if (result.voucherGroups) {
        this.groupLinkedVoucherGroups = []; //vouchergroups with a grouplink
        this.groupLinks = []; // all grouplinks(the objects) in the result of vouchergroups
        this.voucherGroupGroupLinks = []; // all grouplinks(only names) in the result of vouchergroups
        result.voucherGroups.forEach((resultVoucherGroup) => {
          if (!resultVoucherGroup.groupLink)
            resultVoucherGroup.groupLink = 'none';
          this.groupLinkedVoucherGroups.push(resultVoucherGroup);
          if (
            !this.voucherGroupGroupLinks.includes(resultVoucherGroup.groupLink)
          ) {
            this.groupLinksFullInfo.forEach((groupLinksSelectedInfo) => {
              if (
                groupLinksSelectedInfo.name === resultVoucherGroup.groupLink
              ) {
                this.groupLinks.push(groupLinksSelectedInfo);
              }
            });
            this.voucherGroupGroupLinks.push(resultVoucherGroup.groupLink);
          }
        });

        this.checkSituation();
        this.sortingOrder();

        this.voucherGroups = result.voucherGroups;
        this.savedFormInfo = {
          postal: postal,
          houseNumber,
          houseNumberAddition,
        };
        this.voucherGroups.length < 1
          ? (this.noActions = true)
          : (this.noActions = false);

        this.errorMessage = undefined;

        this.stepper.next();

        if (this.voucherGroups.length > 0) {
          let indexToSelect = 0;
          if (this.groupLinks.length > 0) {
            indexToSelect = this.voucherGroups.findIndex(
              (object) => object.groupLink === this.groupLinks[0].name
            );
          }
          this.selectVoucherGroup(this.voucherGroups[indexToSelect]);
        }
      } else if (result.error === 'errorAddress') {
        console.log('result', result);
        this.errorMessage = 'find-voucher-group.error_searching_address';
      } else {
        this.errorMessage = 'find-voucher-group.generic_error';
      }
    }
  }

  async generateActivateVoucherStep() {
    console.log('valid?', this.termsForm.valid);
    if (!this.termsForm.valid) {
      this.termsForm.markAllAsTouched();
      return;
    }
    const addressForm = this.addressForm.value;
    const voucherGroupForm = this.voucherGroupForm.value;
    this.activateVoucherComponentData = {
      townshipId: this.townshipId,
      voucherGroupId: voucherGroupForm.voucherGroup.id,
      userData: addressForm as any,
      width: '590px',
      termsAccepted: true,
      colorData: this.theme,
      noStatistics: true,
      activateType: 'find-voucher-groups',
      preparingRequest: this.preparingRequest,
    };
    this.stepper.next();
  }

  verifySms(status?: string) {
    let phoneSubmitted = false;
    if (status === 'smsVerificationRequired') {
      phoneSubmitted = false;
    } else if (status === 'verification_code_sent') {
      phoneSubmitted = true;
    }
    const dialogRef = this.dialog.open(VerifySmsComponent, {
      width: '350px',
      data: { phonenumberSubmitted: phoneSubmitted },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        const phonenumber =
          result.phonenumber !== '' ? result.phonenumber : this.phonenumber;
        this.phonenumber = phonenumber;
        this.findVoucherGroups(null, this.phonenumber, result.code);
      }
    });
  }

  checkSituation() {
    this.filteredVoucherGroups = [];
    this.groupLinks.forEach((groupLink) => {
      this.groupLinkedVoucherGroups.forEach((voucherGroup) => {
        if (groupLink.name === voucherGroup.groupLink) {
          this.filteredVoucherGroups.push(voucherGroup);
        }
      });
    });

    let voucherGroupListToFilter = [...this.filteredVoucherGroups];

    this.filteredVoucherGroups = [...voucherGroupListToFilter];
    let groupLinksToFilter = [...this.groupLinks];
    let groupLinksDeleted = 0;
    // filter out grouplinks with no vouchergroups linked to them
    this.groupLinks.forEach((groupLink, index) => {
      if (
        this.filteredVoucherGroups.filter(
          (voucherGroup) => groupLink.name === voucherGroup.groupLink
        ).length < 1
      ) {
        console.log('groupLink.name to delete', groupLink.name);
        console.log('index', index);
        groupLinksToFilter.splice(index - groupLinksDeleted, 1);
        groupLinksDeleted++;
      }
    });

    this.groupLinks = [...groupLinksToFilter];

    let newGroupLinks = [];
    this.groupLinks.forEach((groupLink) => {
      let voucherGroupsInGroupLink = [];
      console.log('grouplink', groupLink.name);
      this.filteredVoucherGroups.forEach((voucherGroup) => {
        if (voucherGroup.groupLink === groupLink.name) {
          voucherGroupsInGroupLink.push(voucherGroup);
        }
      });

      let obj = {
        'name': `${groupLink.name}`,
        'text': groupLink.text,
        'order': groupLink.order,
        'voucherGroups': voucherGroupsInGroupLink,
      };
      newGroupLinks.push(obj);
    });
    console.log('newGroupLinks', newGroupLinks);

    this.groupLinks = newGroupLinks;
  }

  sortingOrder() {
    console.log(this.groupLinks);
    this.groupLinks.sort((groupLink1, groupLink2) => {
      if (groupLink1.order > groupLink2.order) {
        return 1;
      }
      if (groupLink1.order < groupLink2.order) {
        return -1;
      }

      if (groupLink1.name > groupLink2.name) {
        return 1;
      }
      if (groupLink1.name < groupLink2.name) {
        return -1;
      }
      return 0;
    });
  }

  async selectVoucherGroup(voucherGroup) {
    this.termsUrl = await getTermsUrl(
      voucherGroup?.termsURL,
      this.township?.termsUrl
    );
    this.voucherGroupForm.controls.voucherGroup.setValue(voucherGroup);
  }

  getError(form: FormGroup, fieldName: string) {
    const field = form.get(fieldName);
    if (field.touched || !field.pristine) {
      let error;
      if (field.hasError('required')) {
        error = 'required';
      }
      if (field.hasError('startsWith')) {
        error = 'must_start_with_06';
      }
      if (field.hasError('email')) {
        error = 'email';
      }
      if (field.hasError('pattern')) {
        error = 'pattern';
      }
      if (field.hasError('minlength')) {
        error = 'phone';
      }
      if (field.hasError('incorrectPin')) {
        error = 'incorrect_pin';
      }
      const res = this.translate.instant(`errors.${error}`) as string;
      return res;
    }
    return '';
  }

  setHighContrast() {
    this.highContrast = this.highContrast ? false : true;
    localStorage.setItem('highContrast', this.highContrast ? '1' : '0');
  }
}
