<h1 mat-dialog-title>{{ 'succeed-activation.title' | translate }}</h1>
<div mat-dialog-content>
  <div
    *ngIf="
      !data.voucherGroup.delaySend && !data.voucherGroup.dontSendUponActivation
    "
  >
    {{ 'succeed-activation.info' | translate }}
  </div>
  <div *ngIf="data.voucherGroup.delaySend">
    {{ 'succeed-activation.info_delay' | translate }}
  </div>
  <div *ngIf="data.voucherGroup.dontSendUponActivation">
    {{ 'succeed-activation.info_not_sending' | translate }}
  </div>
</div>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close onClick="location.reload();">
    {{ 'succeed-activation.done' | translate }}
  </button>
</mat-dialog-actions>
