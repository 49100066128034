import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-complete-order',
    templateUrl: './complete-order.component.html',
    styleUrls: ['./complete-order.component.scss'],
    standalone: false
})
export class CompleteOrderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
