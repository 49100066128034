/* src/app/find-voucher-groups/general-terms/general-terms.component.scss */
.terms-wrapper {
  height: 160px;
  overflow-y: scroll;
  overflow-x: hidden;
}
h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
  margin: 10px 0;
}
h4 {
  font-size: 14px;
}
p {
  font-size: 12px;
  margin: 6px 0;
}
/*# sourceMappingURL=general-terms.component.css.map */
