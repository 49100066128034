<!-- To use a transparent background with this iframe, use <iframe src="stuff.htm" allowtransparency="true"></iframe> -->
<div class="wrapper">
  <mat-card class="activate-card">
    <mat-card-content>
      <div class="title">
        Er is een nieuw venster geopend om uw betaling af te ronden.
      </div>
      <div class="title">
        Klaar met betalen? Dan kunt u dit venster sluiten.
      </div>
    </mat-card-content>
  </mat-card>
</div>
