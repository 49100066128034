/* src/app/buy-vouchers/buy-vouchers.component.scss */
.mat-radio-button {
  margin: 4px 12px 8px;
}
.titles {
  font-weight: bold;
  font-size: 18px;
  color: #40484b;
  opacity: 1;
  margin-bottom: 10px;
  margin-top: 15px;
}
.border-button {
  border-radius: 24px;
}
.name-margin {
  margin: auto;
}
.message {
  margin-bottom: 20px;
}
.amounts {
  width: 100%;
}
.amounts .amount {
  font-size: 16px;
  margin-bottom: 8px;
  font-weight: 500;
  display: flex;
}
.amounts .primary {
  font-size: 18px;
  color: var(--primary);
}
.amounts .value {
  min-width: 64px;
}
.amounts P {
  margin-left: 6px;
}
.wrapper {
  padding: 8px;
  max-height: calc(100vh - 16px);
  background: white;
  max-height: calc(100vh - 16px);
}
.wrapper .general-terms {
  position: relative;
  top: -16px;
  margin-bottom: 8px;
}
.wrapper .general-terms title {
  color: var(--primary);
}
.wrapper .general-terms a {
  color: var(--primary);
}
.wrapper .general-terms .terms-link-info::after {
  width: 210px;
  content: "Door op deze link te klikken wordt er een nieuw tabblad geopend.";
}
.wrapper .voucher-terms mat-label {
  color: rgba(0, 0, 0, 0.6) !important;
}
.wrapper .voucher-terms textarea {
  color: rgba(0, 0, 0, 0.87) !important;
}
.wrapper .textarea-positioning .textarea {
  width: 100%;
  color: transparent !important;
}
.wrapper .terms-checkbox {
  position: relative;
  top: -16px;
}
.wrapper .delivery-position {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.wrapper .step {
  display: flex;
  flex-direction: column;
}
.wrapper .stepper {
  display: flex;
  width: 300px;
  align-items: center;
  margin: 20px auto 20px auto;
}
.wrapper .stepper-step {
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  margin: 4px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}
.wrapper .stepper-active-step {
  background-color: white;
  border: 1px solid var(--primary);
}
.wrapper .stepper-inactive-step {
  background-color: lightgrey;
}
.wrapper .stepper-dashes {
  border-top: 2px dashed var(--secondary);
  width: 100%;
}
.wrapper .button-back {
  color: var(--primary);
}
.wrapper .activate-card {
  margin: auto;
  width: 100%;
  max-width: 350px;
  min-width: 300px;
  box-shadow: none;
}
.wrapper .activate-card .imgWrapper {
  text-align: center;
}
.wrapper .activate-card .imgWrapper .logo {
  height: 90px;
  content: var(--logo-fullwidth);
  margin-bottom: 12px;
}
.wrapper .activate-card .title {
  margin-bottom: 16px;
  font-size: 26px;
  text-align: center;
  color: var(--primary);
}
.wrapper .activate-card .hint-text {
  height: 8px;
  align-items: flex-end;
}
.wrapper .activate-card .hint-text .mat-hint {
  height: 17px;
  float: right;
  position: relative;
  bottom: 16px;
  right: 0px;
}
.wrapper .activate-card .statistics {
  left: 0;
  right: 0;
  margin: auto;
  top: 40px;
  max-width: 200px;
}
.wrapper .activate-card .statistics .statistic {
  font-size: 50px;
  text-align: center;
  color: var(--primary);
}
.wrapper .activate-card .statistics .subtitle {
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}
.wrapper .activate-card .mat-card-actions {
  position: absolute;
  bottom: 16px;
  right: 16px;
}
.wrapper .activate-card .mat-card-actions button {
  text-transform: uppercase;
}
.loading-wrapper {
  height: 100vh;
  width: 100vw;
}
.loading-wrapper .loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}
:host ::ng-deep .mat-horizontal-stepper-header-container {
  position: inherit;
}
.icon img {
  width: 25px;
  height: 25px;
  margin-right: 8px;
  border-radius: 5px;
  margin-top: 16px;
}
.static-prefix {
  display: flex;
  place-content: flex-start;
  align-items: center;
  height: 30px;
}
.static-prefix img {
  margin-top: 0px;
}
.step-3-form-field-flex-100 {
  flex: 1 1 100%;
  box-sizing: border-box;
  max-width: 100%;
}
.flex-buttons {
  flex: 1 1 0%;
  box-sizing: border-box;
  flex-direction: row;
  display: flex;
}
.flex-spacer {
  flex: 1 1 0%;
  box-sizing: border-box;
}
.house-number {
  width: 55%;
  padding-right: 6px;
  box-sizing: border-box;
}
.house-number-addition {
  width: 45%;
  padding-left: 6px;
  box-sizing: border-box;
}
.formfield-width-100 {
  width: 100%;
}
.spacing-flex {
  flex-grow: 1;
}
.buttons {
  display: flex;
}
.icon-wrapper {
  display: flex;
  place-content: flex-start;
  align-items: center;
}
/*# sourceMappingURL=buy-vouchers.component.css.map */
