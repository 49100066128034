/* src/app/buy-vouchers/buy-success/buy-success.component.scss */
.loading-wrapper {
  height: 100vh;
  width: 100vw;
}
.loading-wrapper .loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.wrapper {
  margin: auto;
  padding: 10px 16px 0;
  min-width: 420px;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wrapper .success-image {
  width: calc(100% - 20px);
}
.title-position {
  width: 460px;
  margin: 10px 0px 0px 15px;
}
.title {
  font-size: 28px;
  margin-bottom: 10px;
  color: var(--primary);
  font-family:
    Roboto,
    "Helvetica Neue",
    sans-serif;
  font-weight: bold;
}
.order-confirmation {
  font-size: 20px;
  color: black;
  font-family:
    Roboto,
    "Helvetica Neue",
    sans-serif;
  font-weight: bold;
  margin: 20px 0 10px 0;
  line-height: 1.5;
}
.your-order {
  font-size: 20px;
  color: var(--accent);
  font-family:
    Rubik,
    "Helvetica Neue",
    sans-serif;
  font-weight: bold;
  width: 100%;
  margin: 10px 0 10px 20px;
}
mat-divider {
  margin-bottom: 5px;
  width: 100%;
  border-top-width: 2px;
}
.table-row-padding {
  padding: 10px 0 10px 0;
}
.order-table {
  display: flex;
  min-width: 100%;
  flex-direction: column;
}
.order-table .order-table-row:first-child {
  align-items: center;
  color: black;
  font-family:
    Roboto,
    "Helvetica Neue",
    sans-serif;
  font-weight: bold;
  background-color: #fcfaf1;
}
.order-table .order-table-row:first-child .order-table-column {
  font-size: 15px;
}
.order-table .order-table-row {
  flex: 1;
  display: flex;
}
.order-table .order-table-row .order-table-column:first-child {
  flex: 3;
}
.order-table .order-table-row .order-table-column {
  flex: 1;
  padding: 20px 15px;
  font-size: 14px;
}
/*# sourceMappingURL=buy-success.component.css.map */
