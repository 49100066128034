import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VoucherGroup } from 'src/app/interfaces';

export interface DialogData {
  voucherGroup: VoucherGroup;
}

@Component({
    selector: 'app-succeed-activation',
    templateUrl: './succeed-activation.component.html',
    styleUrls: ['./succeed-activation.component.scss'],
    standalone: false
})
export class SucceedActivationComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit(): void {}
}
