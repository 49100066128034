<div
  class="page"
  role="main"
  *ngIf="loaded; else loading"
  [ngStyle]="
    highContrast ? { 'filter': 'saturate(0)' } : { 'filter': 'saturate(1)' }
  "
>
  <div class="wrapper-bg">
    <div class="header-wrapper">
      <div class="header-image-wrapper">
        <div
          class="header-img"
          style="background-image:url('{{ townshipImage }}');"
        ></div>
      </div>
      <div class="header-text-button-wrapper">
        <h1 class="header-text">
          {{ township.name }}
        </h1>
        <div class="header-button-wrapper">
          <button
            (click)="setHighContrast()"
            mat-flat-button
            class="header-button"
          >
            <mat-icon>contrast</mat-icon>
            Hoog contrast
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="content">
    <div
      class="step step-0"
      *ngIf="step === 0"
    >
      <div class="form-wrapper">
        <form
          class="enter-address-form"
          aria-label="zoeken op adres"
          [formGroup]="addressForm"
          (ngSubmit)="getHouseInfo()"
        >
          <div
            class="step-0-title"
            role="heading"
            aria-level="1"
          >
            <div>Je huis verduurzamen?</div>
            <div>Meld je aan voor het gratis aanbod!</div>
          </div>
          <div
            class="step-0-subtitle"
            id="step0Form"
          >
            Op basis van je postcode en huisnummer krijg je direct zicht op het
            aanbod voor jouw woning.
          </div>
          <div
            role="group"
            aria-labelledBy="step0Form"
          >
            <mat-form-field>
              <mat-label>{{ 'input.postal' | translate }}</mat-label>
              <input
                pattern="[1-9][0-9]{3}[\s]?[A-Za-z]{2}"
                formControlName="postal"
                id="postal"
                matInput
                #postalCode
                required
                autocomplete="postal-code"
                placeholder="1234AB"
              />
              <mat-error>
                {{ getError('postal') }}
              </mat-error>
            </mat-form-field>
            <div class="half-fields">
              <mat-form-field>
                <mat-label>{{ 'input.houseNumber' | translate }}</mat-label>
                <input
                  formControlName="houseNumber"
                  matInput
                  autocomplete="address-line2"
                  type="text"
                  required
                  id="houseNumber"
                />
                <mat-error>
                  {{ getError('houseNumber') }}
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{
                  'input.houseNumberAddition' | translate
                }}</mat-label>
                <input
                  formControlName="houseNumberAddition"
                  matInput
                  id="houseNumberAddition"
                  autocomplete="new-password"
                  (keydown.space)="$event.preventDefault()"
                />
                <mat-hint>Dit veld is optioneel</mat-hint>
                <mat-error>
                  {{ getError('houseNumberAddition') }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="actions">
            <button
              mat-flat-button
              class="spinner-button"
              [disabled]="addressForm.invalid || awaitingResponse"
            >
              Doe de scan!
              <mat-spinner
                diameter="20"
                *ngIf="awaitingResponse"
              ></mat-spinner>
            </button>
          </div>
        </form>
      </div>
      <div class="address-image-wrapper">
        <div class="address-image"></div>
      </div>
    </div>
    <div
      class="stepper-header"
      *ngIf="step > 0 && step < 4 && houseInfo"
    >
      <div class="your-home">
        <div class="title">Uw woning:</div>
        <div class="infos">
          <div>Postcode: {{ houseInfo.postal }}</div>
          <div>
            Huisnummer: {{ houseInfo.houseNumber
            }}{{ houseInfo.houseNumberAddition ?? '' }}
          </div>
          <div>Bouwjaar: {{ houseInfo.buildYear }}</div>
          <div>Energielabel: {{ houseInfo.energyLabel }}</div>
          <div>
            Oppervlakte: {{ houseInfo.surfaceM2
            }}{{ houseInfo.surfaceM2 === 'N/A' ? '' : ' m2' }}
          </div>
        </div>
      </div>
      <div class="progress">
        <div class="text">Stap {{ step }} van 3</div>
        <progress
          title="progress"
          [value]="step"
          max="4"
        ></progress>
      </div>
    </div>
    <div
      class="step step-1"
      *ngIf="step === 1"
    >
      <form
        class="house-type-form"
        aria-label="soort woning kiezen"
        [formGroup]="houseTypeForm"
        (ngSubmit)="next(houseTypeForm)"
      >
        <div
          class="title-cards-wrapper"
          role="group"
          aria-labelledby="step1HouseType"
        >
          <div
            class="title"
            id="step1HouseType"
            role="heading"
            aria-level="1"
          >
            Wat is uw woningtype?
          </div>
          <div class="cards-wrapper">
            <div
              class="card"
              tabindex="0"
              role="tabpanel"
              aria-label="vrijstaande woning"
              [class.active]="houseTypeForm.value.houseType === 'detached'"
              (click)="houseTypeForm.controls.houseType.setValue('detached')"
              (keyup.enter)="
                houseTypeForm.controls.houseType.setValue('detached')
              "
            >
              <div class="card-type-image">
                <mat-icon [svgIcon]="'Building_Detached'"></mat-icon>
              </div>
              <div class="card-type-text">Vrijstaande woning</div>
            </div>
            <div
              class="card"
              tabindex="0"
              role="tabpanel"
              aria-label="twee onder een kap"
              [class.active]="houseTypeForm.value.houseType === 'semidetached'"
              (click)="
                houseTypeForm.controls.houseType.setValue('semidetached')
              "
              (keyup.enter)="
                houseTypeForm.controls.houseType.setValue('semidetached')
              "
            >
              <div class="card-type-image">
                <mat-icon [svgIcon]="'Building_Semidetached'"></mat-icon>
              </div>
              <div class="card-type-text">Twee onder een kap</div>
            </div>
            <div
              class="card"
              tabindex="0"
              role="tabpanel"
              aria-label="hoekwoning"
              [class.active]="
                houseTypeForm.value.houseType === 'townhouse_corner'
              "
              (click)="
                houseTypeForm.controls.houseType.setValue('townhouse_corner')
              "
              (keyup.enter)="
                houseTypeForm.controls.houseType.setValue('townhouse_corner')
              "
            >
              <div class="card-type-image">
                <mat-icon [svgIcon]="'Building_Townhouse_Corner'"></mat-icon>
              </div>
              <div class="card-type-text">Hoekwoning</div>
            </div>
            <div
              class="card"
              tabindex="0"
              role="tabpanel"
              aria-label="tussenwoning"
              [class.active]="houseTypeForm.value.houseType === 'townhouse'"
              (click)="houseTypeForm.controls.houseType.setValue('townhouse')"
              (keyup.enter)="
                houseTypeForm.controls.houseType.setValue('townhouse')
              "
            >
              <div class="card-type-image">
                <mat-icon [svgIcon]="'Building_Townhouse'"></mat-icon>
              </div>
              <div class="card-type-text">Tussenwoning</div>
            </div>
            <div
              class="card"
              tabindex="0"
              role="tabpanel"
              aria-label="appartement"
              [class.active]="houseTypeForm.value.houseType === 'apartment'"
              (click)="houseTypeForm.controls.houseType.setValue('apartment')"
              (keyup.enter)="
                houseTypeForm.controls.houseType.setValue('apartment')
              "
            >
              <div class="card-type-image">
                <mat-icon [svgIcon]="'Building_Apartment'"></mat-icon>
              </div>
              <div class="card-type-text">Appartement</div>
            </div>
          </div>
        </div>

        <div class="actions">
          <button
            mat-button
            (click)="step = step - 1"
            type="button"
          >
            <mat-icon>arrow_back</mat-icon>
            {{ 'find-voucher-group.body.previous' | translate }}
          </button>
          <button
            mat-flat-button
            [disabled]="!houseTypeForm.controls.houseType.value"
          >
            {{ 'find-voucher-group.body.next' | translate }}
          </button>
        </div>
      </form>
    </div>
    <div
      class="step step-2"
      *ngIf="step === 2"
    >
      <form
        class="sustainability-options-form"
        [formGroup]="sustainabilityOptionsForm"
        (ngSubmit)="next(sustainabilityOptionsForm)"
        aria-label="woning duurzaamheid kiezen"
      >
        <div class="title-cards-wrapper">
          <div
            class="title"
            role="heading"
            aria-level="1"
          >
            Hoe duurzaam is uw woning nu?
          </div>
          <div class="small-title">U kunt meerdere antwoorden selecteren</div>

          <div
            class="sub-title"
            id="step3Isolation"
            role="heading"
            aria-level="2"
          >
            Hoe is uw woning geisoleerd?
          </div>
          <div
            class="cards-wrapper"
            role="group"
            aria-labelledby="step3Isolation"
          >
            <div
              class="card"
              tabindex="0"
              role="tabpanel"
              aria-label="dakisolatie"
              [class.active]="
                sustainabilityOptionsForm.value.measures.includes('Dakisolatie')
              "
              (click)="toggleMeasure('Dakisolatie')"
              (keyup.enter)="toggleMeasure('Dakisolatie')"
            >
              <div class="card-type-image">
                <mat-icon [svgIcon]="'Measure_Roof_Insulation'"></mat-icon>
              </div>
              <div class="card-type-text">Dakisolatie</div>
            </div>
            <div
              class="card"
              tabindex="0"
              role="tabpanel"
              aria-label="gevelisolatie"
              [class.active]="
                sustainabilityOptionsForm.value.measures.includes(
                  'Gevelisolatie'
                )
              "
              (click)="toggleMeasure('Gevelisolatie')"
              (keyup.enter)="toggleMeasure('Gevelisolatie')"
            >
              <div class="card-type-image">
                <mat-icon [svgIcon]="'Measure_Facade_Insulation'"></mat-icon>
              </div>
              <div class="card-type-text">Gevelisolatie</div>
            </div>
            <div
              class="card"
              tabindex="0"
              role="tabpanel"
              aria-label="glasisolatie"
              [class.active]="
                sustainabilityOptionsForm.value.measures.includes(
                  'Glasisolatie'
                )
              "
              (click)="toggleMeasure('Glasisolatie')"
              (keyup.enter)="toggleMeasure('Glasisolatie')"
            >
              <div class="card-type-image">
                <mat-icon [svgIcon]="'Measure_Glass_Insulation'"></mat-icon>
              </div>
              <div class="card-type-text">Dubbel Glas</div>
            </div>
            <div
              class="card"
              tabindex="0"
              role="tabpanel"
              aria-label="vloerisolatie"
              [class.active]="
                sustainabilityOptionsForm.value.measures.includes(
                  'Vloerisolatie'
                )
              "
              (click)="toggleMeasure('Vloerisolatie')"
              (keyup.enter)="toggleMeasure('Vloerisolatie')"
            >
              <div class="card-type-image">
                <mat-icon [svgIcon]="'Measure_Floor_Insulation'"></mat-icon>
              </div>
              <div class="card-type-text">Vloerisolatie</div>
            </div>
          </div>
          <div
            class="sub-title"
            id="step3OtherMeasures"
            role="heading"
            aria-level="2"
          >
            Heeft u een van deze overige maatregelen?
          </div>
          <div
            class="cards-wrapper"
            role="group"
            aria-labelledby="step3OtherMeasures"
          >
            <div
              class="card"
              tabindex="0"
              role="tabpanel"
              aria-label="zonnepanelen"
              [class.active]="
                sustainabilityOptionsForm.value.measures.includes(
                  'Zonnepanelen'
                )
              "
              (click)="toggleMeasure('Zonnepanelen')"
              (keyup.enter)="toggleMeasure('Zonnepanelen')"
            >
              <div class="card-type-image">
                <mat-icon [svgIcon]="'Measure_Solar_Panels'"></mat-icon>
              </div>
              <div class="card-type-text">Zonnepanelen</div>
            </div>
            <div
              class="card"
              tabindex="0"
              role="tabpanel"
              aria-label="warmtepomp"
              [class.active]="
                sustainabilityOptionsForm.value.measures.includes('Warmtepomp')
              "
              (click)="toggleMeasure('Warmtepomp')"
              (keyup.enter)="toggleMeasure('Warmtepomp')"
            >
              <div class="card-type-image">
                <mat-icon [svgIcon]="'Measure_Heat_Pump'"></mat-icon>
              </div>
              <div class="card-type-text">Warmtepomp</div>
            </div>
            <div
              class="card"
              tabindex="0"
              role="tabpanel"
              aria-label="zonneboiler"
              [class.active]="
                sustainabilityOptionsForm.value.measures.includes('Zonneboiler')
              "
              (click)="toggleMeasure('Zonneboiler')"
              (keyup.enter)="toggleMeasure('Zonneboiler')"
            >
              <div class="card-type-image">
                <mat-icon [svgIcon]="'Measure_Solar_Boiler'"></mat-icon>
              </div>
              <div class="card-type-text">Zonneboiler</div>
            </div>
            <div
              class="card"
              tabindex="0"
              role="tabpanel"
              aria-label="warmteterugwinning"
              [class.active]="
                sustainabilityOptionsForm.value.measures.includes(
                  'Warmteterugwinning'
                )
              "
              (click)="toggleMeasure('Warmteterugwinning')"
              (keyup.enter)="toggleMeasure('Warmteterugwinning')"
            >
              <div class="card-type-image">
                <mat-icon [svgIcon]="'Measure_Heating_Network'"></mat-icon>
              </div>
              <div class="card-type-text">Warmteterugwinning</div>
            </div>
          </div>
        </div>
        <div class="actions">
          <button
            mat-button
            (click)="step = step - 1"
            type="button"
          >
            <mat-icon>arrow_back</mat-icon>
            {{ 'find-voucher-group.body.previous' | translate }}
          </button>
          <button
            mat-flat-button
            type="submit"
          >
            {{ 'find-voucher-group.body.next' | translate }}
          </button>
        </div>
      </form>
    </div>
    <div
      class="step step-4"
      *ngIf="step === 3"
    >
      <form
        class="goal-form"
        [formGroup]="goalForm"
        (ngSubmit)="findVoucherGroups()"
        aria-label="ambitieniveau kiezen"
      >
        <div class="title-cards-wrapper">
          <div
            class="title"
            id="step4Title"
            role="heading"
            aria-level="1"
          >
            Geef aan wat u graag wil bereiken
          </div>
          <div class="small-title-no-sub">
            Wat wilt u doen om comfortabel te wonen en energie te besparen
          </div>
          <div
            class="cards-wrapper"
            role="group"
            aria-labelledby="step4Title"
          >
            <div
              class="card level"
              tabindex="0"
              role="tabpanel"
              aria-label="brons"
              [class.active]="goalForm.value.goal === 'bronze'"
              (click)="goalForm.controls.goal.setValue('bronze')"
              (keyup.enter)="goalForm.controls.goal.setValue('bronze')"
            >
              <div class="card-type-image">
                <mat-icon [svgIcon]="'Level_Bronze'"></mat-icon>
              </div>
              <div class="card-type-text">Brons: Kleine aanpassingen</div>
              <div class="card-type-sub-text">
                Ik wil een tochtvrije woning, LED lampen plaatsen
              </div>
            </div>
            <div
              class="card level"
              tabindex="0"
              role="tabpanel"
              aria-label="zilver"
              [class.active]="goalForm.value.goal === 'silver'"
              (click)="goalForm.controls.goal.setValue('silver')"
              (keyup.enter)="goalForm.controls.goal.setValue('silver')"
            >
              <div class="card-type-image">
                <mat-icon [svgIcon]="'Level_Silver'"></mat-icon>
              </div>
              <div class="card-type-text">Zilver: Isoleren</div>
              <div class="card-type-sub-text">
                Ik wil een beter geïsoleerde woning
              </div>
            </div>
            <div
              class="card level"
              tabindex="0"
              role="tabpanel"
              aria-label="goud"
              [class.active]="goalForm.value.goal === 'gold'"
              (click)="goalForm.controls.goal.setValue('gold')"
              (keyup.enter)="goalForm.controls.goal.setValue('gold')"
            >
              <div class="card-type-image">
                <mat-icon [svgIcon]="'Level_Gold'"></mat-icon>
              </div>
              <div class="card-type-text">Goud: Minder gas</div>
              <div class="card-type-sub-text">
                Ik wil een goedkopere gasrekening
              </div>
            </div>
            <div
              class="card level"
              tabindex="0"
              role="tabpanel"
              aria-label="platina"
              [class.active]="goalForm.value.goal === 'platinum'"
              (click)="goalForm.controls.goal.setValue('platinum')"
              (keyup.enter)="goalForm.controls.goal.setValue('platinum')"
            >
              <div class="card-type-image">
                <mat-icon [svgIcon]="'Level_Platinum'"></mat-icon>
              </div>
              <div class="card-type-text">Platina: Aardgasvrij</div>
              <div class="card-type-sub-text">Ik wil van het gas af</div>
            </div>
          </div>
        </div>
        <div class="actions">
          <button
            mat-button
            (click)="step = step - 1"
            type="button"
          >
            <mat-icon>arrow_back</mat-icon>
            {{ 'find-voucher-group.body.previous' | translate }}
          </button>
          <button
            mat-flat-button
            class="spinner-button"
            [disabled]="!goalForm.controls.goal.value || awaitingResponse"
          >
            Bekijk uw advies
            <mat-spinner
              diameter="20"
              *ngIf="awaitingResponse"
            ></mat-spinner>
          </button>
        </div>
      </form>
    </div>
    <div
      class="step step-5"
      *ngIf="step === 4"
    >
      <form
        class="verification-form"
        aria-label="verificatie met pincode"
        [formGroup]="verificationForm"
        (ngSubmit)="findVoucherGroups(verificationForm.value)"
      >
        <div class="title-input-wrapper">
          <div
            class="title"
            role="heading"
            aria-level="1"
          >
            Uw campagnes bekijken
          </div>
          <div
            class="small-title-no-sub"
            *ngIf="verificationForm.controls.pin"
          >
            Vul hier de pincode in die u heeft ontvangen. Met deze pincode kunt
            alleen u zien welke campagnes voor u beschikbaar zijn.
          </div>
          <mat-form-field *ngIf="verificationForm.controls.pin">
            <mat-label>Pincode</mat-label>
            <input
              cdkFocusInitial
              placeholder="1234"
              formControlName="pin"
              autocomplete="off"
              matInput
              required
              (keydown.space)="$event.preventDefault()"
            />
            <mat-error>
              {{ getError('pin', verificationForm) }}
            </mat-error>
          </mat-form-field>
          <div
            class="custom-tooltip pin-info"
            tabindex="0"
            role="tabpanel"
            aria-label="pin info"
          >
            Waar staat mijn pincode?
          </div>
          <div class="actions">
            <button
              mat-button
              (click)="step = step - 1"
              type="button"
            >
              <mat-icon>arrow_back</mat-icon>
              {{ 'find-voucher-group.body.previous' | translate }}
            </button>
            <button
              mat-flat-button
              class="spinner-button"
              [disabled]="!verificationForm.valid || awaitingResponse"
            >
              Start
              <mat-spinner
                diameter="20"
                *ngIf="awaitingResponse"
              ></mat-spinner>
            </button>
          </div>
        </div>
      </form>
    </div>
    <div
      class="step step-6"
      *ngIf="step === 5"
    >
      <div class="step-6-wrapper">
        <div class="title-option-wrapper">
          <div
            class="title"
            role="heading"
            aria-level="1"
          >
            {{
              noActions
                ? 'Geen campagnes beschikbaar'
                : 'Deze campagnes zijn beschikbaar'
            }}
          </div>
          <div class="small-title">
            {{
              noActions
                ? 'Er zijn helaas geen campagnes beschikbaar op dit adres'
                : 'De campagnes hieronder zijn beschikbaar op dit adres'
            }}
          </div>
          <div class="small-title">
            {{
              noActions
                ? addressForm.controls.postal.value +
                  ' ' +
                  addressForm.controls.houseNumber.value +
                  addressForm.controls.houseNumberAddition.value
                : 'Kies een campagne voor meer informatie'
            }}
          </div>
          <div
            class="group-links-wrapper"
            *ngFor="let groupLinkFor of groupLinks; let i1 = index"
          >
            <div class="group-link-title">
              {{ i1 + 1 }}: {{ groupLinkFor.text }}
            </div>
            <div class="voucher-group-cards-wrapper">
              <div
                class="voucher-group-card-wrapper"
                *ngFor="
                  let voucherGroup of groupLinkFor.voucherGroups;
                  let i2 = index
                "
              >
                <div
                  class="voucher-group-card"
                  [class.selected-voucher-group-card]="
                    voucherGroupForm.value.voucherGroup === voucherGroup
                  "
                  tabindex="0"
                  role="tabpanel"
                  [title]="voucherGroup.name"
                  (click)="selectVoucherGroup(voucherGroup)"
                  (keyup.enter)="selectVoucherGroup(voucherGroup)"
                >
                  <div
                    class="avatar"
                    *ngIf="voucherGroup.imageUrl"
                    style="background-image:url('{{ voucherGroup.imageUrl }}');"
                  ></div>
                  <div
                    class="avatar placeholder"
                    *ngIf="!voucherGroup.imageUrl"
                  >
                    {{ voucherGroup.name[0] }}
                  </div>
                  <div class="card-content">
                    <div class="title-slogan">
                      <div class="title">
                        {{ voucherGroup.name }}
                      </div>
                      <div
                        class="slogan"
                        *ngIf="voucherGroup.slogan"
                      >
                        {{ voucherGroup.slogan }}
                      </div>
                    </div>
                    <div class="voucher-value-wrapper">
                      <div
                        class="voucher-value"
                        *ngIf="
                          voucherGroup.couponValue &&
                          !voucherGroup.altTextCouponValue
                        "
                      >
                        €{{ getCurrencyString(voucherGroup.couponValue) }}
                      </div>
                      <div
                        class="coupon"
                        *ngIf="!voucherGroup.altTextCouponValue"
                      >
                        {{ 'find-voucher-group.body.voucher' | translate }}
                      </div>
                      <div
                        class="alt-coupon"
                        *ngIf="voucherGroup.altTextCouponValue"
                      >
                        {{ voucherGroup.altTextCouponValue }}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="
                    groupLinkFor.voucherGroups.length > 1 &&
                    i2 < groupLinkFor.voucherGroups.length - 1
                  "
                  class="separator"
                >
                  <div class="text">
                    - {{ 'find-voucher-group.body.or' | translate }} -
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="actions">
            <button
              mat-button
              (click)="step = step - 2"
            >
              <mat-icon>arrow_back</mat-icon>
              {{ 'find-voucher-group.body.previous' | translate }}
            </button>
            <button
              mat-flat-button
              *ngIf="!noActions"
              (click)="step = step + 1"
              [disabled]="!voucherGroupForm.value.voucherGroup"
            >
              {{ 'find-voucher-group.body.select' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="step step-7"
      *ngIf="step === 6"
    >
      <div
        class="voucher-group-details"
        *ngIf="voucherGroupForm.value.voucherGroup as voucherGroup"
      >
        <div
          class="top-title"
          role="heading"
          aria-level="1"
        >
          {{ voucherGroup.name }}
        </div>
        <div
          class="avatar image"
          *ngIf="voucherGroup.imageUrl"
          style="background-image:url('{{ voucherGroup.imageUrl }}');"
        ></div>
        <div
          class="avatar placeholder"
          *ngIf="!voucherGroup.imageUrl"
        >
          {{ voucherGroup.name[0] }}
        </div>
        <div class="slogan-desc">
          <h3
            class="slogan"
            *ngIf="voucherGroup.slogan"
          >
            {{ voucherGroup.slogan }}
          </h3>
          <div
            class="description"
            *ngIf="voucherGroup.description"
          >
            {{ voucherGroup.description }}
          </div>
        </div>
        <div class="terms">
          <h3 class="title">
            {{ 'find-voucher-group.body.terms_group' | translate }}
          </h3>
          <div
            class="voucher-group-terms"
            *ngIf="voucherGroup.terms"
          >
            {{ voucherGroup.terms }}
          </div>
          <div
            class="general-terms"
            *ngIf="!termsUrl"
          >
            <div class="title">
              {{ 'find-voucher-group.body.terms_general' | translate }}
            </div>
            <app-general-terms></app-general-terms>
          </div>
          <div
            class="general-terms"
            *ngIf="termsUrl"
          >
            <mat-label>
              <span>{{ 'activate.body.terms.read-terms' | translate }} </span>
              <a
                href="{{ termsUrl }}"
                target="_blank"
                class="custom-tooltip terms-link-info"
                aria-label="algemene voorwaarden"
              >
                {{ 'activate.body.terms.terms-condition' | translate }}
              </a>
              <span>{{ 'activate.body.terms.here' | translate }}</span>
            </mat-label>
          </div>
        </div>
        <form
          [formGroup]="termsForm"
          aria-label="voorwaarden accepteren checkbox"
        >
          <mat-checkbox
            class="terms-checkbox"
            formControlName="acceptTerms"
          >
            {{ 'find-voucher-group.body.terms_accept' | translate }}
          </mat-checkbox>
        </form>
        <div class="actions">
          <button
            mat-button
            (click)="step = step - 1"
            (click)="termsForm.reset()"
          >
            <mat-icon>arrow_back</mat-icon>
            {{ 'find-voucher-group.body.previous' | translate }}
          </button>
          <button
            mat-flat-button
            (click)="generateActivateVoucherStep()"
            [disabled]="!termsForm.valid"
          >
            {{ 'find-voucher-group.body.next' | translate }}
          </button>
        </div>
      </div>
    </div>
    <div
      class="step step-8"
      *ngIf="step === 7"
    >
      <div
        class="title"
        role="heading"
        aria-level="1"
      >
        Uw gegevens
      </div>
      <div class="small-title-no-sub">
        U wilt deelnemen aan de volgende campagne:
      </div>
      <app-activate-voucher
        *ngIf="activateVoucherComponentData"
        [inputData]="activateVoucherComponentData"
      ></app-activate-voucher>
      <div class="floating-back-button-wrapper">
        <div class="floating-back-button">
          <button
            mat-button
            (click)="activateVoucherComponentData = null; step = step - 1"
          >
            <mat-icon>arrow_back</mat-icon>
            {{ 'find-voucher-group.body.previous' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #loading>
  <div class="loading-wrapper">
    <div class="loading">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</ng-template>
