import * as i0 from '@angular/core';
import { NgModule } from '@angular/core';
import { M as MatPseudoCheckbox } from './pseudo-checkbox-4a60d45e.mjs';
import { M as MatCommonModule } from './common-module-a39ee957.mjs';
class MatPseudoCheckboxModule {
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.2.0",
    ngImport: i0,
    type: MatPseudoCheckboxModule,
    deps: [],
    target: i0.ɵɵFactoryTarget.NgModule
  });
  static ɵmod = i0.ɵɵngDeclareNgModule({
    minVersion: "14.0.0",
    version: "19.2.0",
    ngImport: i0,
    type: MatPseudoCheckboxModule,
    imports: [MatCommonModule, MatPseudoCheckbox],
    exports: [MatPseudoCheckbox]
  });
  static ɵinj = i0.ɵɵngDeclareInjector({
    minVersion: "12.0.0",
    version: "19.2.0",
    ngImport: i0,
    type: MatPseudoCheckboxModule,
    imports: [MatCommonModule]
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.2.0",
  ngImport: i0,
  type: MatPseudoCheckboxModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [MatCommonModule, MatPseudoCheckbox],
      exports: [MatPseudoCheckbox]
    }]
  }]
});
export { MatPseudoCheckboxModule as M };
