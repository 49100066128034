<!-- To use a transparent background with this iframe, use <iframe src="stuff.htm" allowtransparency="true"></iframe> -->
<div
  class="wrapper"
  role="main"
  [ngStyle]="
    highContrast ? { 'filter': 'saturate(0)' } : { 'filter': 'saturate(1)' }
  "
  *ngIf="loaded; else loading"
>
  <div
    class="wrapper-bg"
    role="banner"
  >
    <div class="header-wrapper">
      <div class="header-image-wrapper">
        <div
          class="header-img"
          style="
            background-image:url('{{ townshipImage }}');
            "
        ></div>
      </div>
      <div class="header-text-button-wrapper">
        <h1 class="header-text">
          {{ townshipName }}
        </h1>
        <div class="header-button-wrapper">
          <button
            (click)="setHighContrast()"
            mat-flat-button
            class="header-button"
          >
            <mat-icon>contrast</mat-icon>
            Hoog contrast
          </button>
        </div>
      </div>
    </div>
  </div>
  <mat-stepper
    [linear]="true"
    #stepper
  >
    <mat-step [stepControl]="addressForm">
      <form
        class="address-form-wrapper"
        [formGroup]="addressForm"
        aria-label="zoeken op adres"
      >
        <div
          role="group"
          aria-label="adres gegevens"
        >
          <mat-form-field class="postal">
            <mat-label>{{ 'input.postal' | translate }}</mat-label>
            <input
              matInput
              formControlName="postal"
              pattern="^[1-9][0-9]{3} ?(?!sa|sd|ss|SA|SD|SS)[A-Za-z]{2}$"
              autocomplete="postal-code"
              data-lpignore="true"
              placeholder="1234AB"
            />
            <mat-error>
              {{ getError(addressForm, 'postal') }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="house-number">
            <mat-label>{{ 'input.houseNumber' | translate }}</mat-label>
            <input
              matInput
              formControlName="houseNumber"
              autocomplete="address-line2"
              (keydown.space)="$event.preventDefault()"
              type="text"
              oninput="this.value = !!this.value && Math.abs(this.value) >= 1 ? Math.abs(this.value) : null"
              data-lpignore="true"
            />
            <mat-error>
              {{ getError(addressForm, 'houseNumber') }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="addition">
            <mat-label>
              {{ 'input.houseNumberAddition' | translate }}
            </mat-label>
            <input
              matInput
              formControlName="houseNumberAddition"
              autocomplete="off"
              (keydown.space)="$event.preventDefault()"
              data-lpignore="true"
            />
            <mat-hint>Dit veld is optioneel</mat-hint>
            <mat-error>
              {{ getError(addressForm, 'houseNumberAddition') }}
            </mat-error>
          </mat-form-field>
        </div>
        <div
          class="error-searching-address"
          *ngIf="errorMessage"
        >
          {{ errorMessage | translate }}
        </div>
        <div class="stepper-buttons-wrapper">
          <div class="stepper-buttons">
            <div></div>
            <button
              mat-flat-button
              class="spinner-button"
              (click)="findVoucherGroups()"
              [disabled]="awaitingResponse"
            >
              {{ 'find-voucher-group.body.next' | translate }}

              <mat-spinner
                diameter="20"
                *ngIf="awaitingResponse"
              ></mat-spinner>
            </button>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step
      [stepControl]="verificationForm"
      *ngIf="verificationNeeded"
    >
      <form
        class="verification-form-wrapper"
        [formGroup]="verificationForm"
        (ngSubmit)="findVoucherGroups(verificationForm.controls.pin.value)"
        aria-label="verificatie met pincode"
      >
        <div
          class="title"
          role="heading"
          aria-level="1"
        >
          Uw campagnes bekijken
        </div>
        <div class="small-title-no-sub">
          Vul hier de pincode in die u heeft ontvangen. Met deze pincode kunt
          alleen u zien welke campagnes voor u beschikbaar zijn op
          {{ addressString }}
        </div>
        <mat-form-field>
          <mat-label>Pincode</mat-label>
          <input
            cdkFocusInitial
            placeholder="1234"
            formControlName="pin"
            autocomplete="off"
            matInput
            required
            (keydown.space)="$event.preventDefault()"
          />
          <mat-error>
            {{ getError(verificationForm, 'pin') }}
          </mat-error>
        </mat-form-field>
        <div
          class="custom-tooltip pin-info"
          tabindex="0"
          role="tabpanel"
          aria-label="pin info"
        >
          Waar staat mijn pincode?
        </div>
        <div class="actions">
          <button
            mat-flat-button
            (click)="stepper.previous()"
            type="button"
          >
            <mat-icon>arrow_back</mat-icon>
            {{ 'find-voucher-group.body.previous' | translate }}
          </button>
          <button
            mat-flat-button
            [disabled]="!verificationForm.valid || awaitingResponse"
          >
            Start
            <mat-spinner
              diameter="20"
              *ngIf="awaitingResponse"
            ></mat-spinner>
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="voucherGroupForm">
      <div
        class="voucher-groups-wrapper"
        *ngIf="voucherGroups"
      >
        <div class="title-wrapper">
          <div
            class="title"
            role="heading"
            aria-level="1"
          >
            {{
              noActions
                ? 'Geen campagnes beschikbaar'
                : 'Deze campagnes zijn beschikbaar'
            }}
          </div>
          <div class="small-title">
            {{
              noActions
                ? 'Er zijn helaas geen campagnes beschikbaar op ' +
                  addressString
                : 'De campagnes hieronder zijn beschikbaar op ' + addressString
            }}
          </div>
          <div class="small-title">
            {{ !noActions ? 'Kies een campagne voor meer informatie' : '' }}
          </div>
        </div>
        <div
          class="group-links-wrapper"
          *ngFor="let groupLinkFor of groupLinks; let i1 = index"
        >
          <div class="group-link-title">
            {{ i1 + 1 }}: {{ groupLinkFor.text }}
          </div>
          <div class="voucher-group-cards-wrapper">
            <div
              class="voucher-group-card-wrapper"
              *ngFor="
                let voucherGroup of groupLinkFor.voucherGroups;
                let i2 = index
              "
            >
              <div
                class="voucher-group-card"
                tabindex="0"
                role="tabpanel"
                [title]="voucherGroup.name"
                [class.selected-voucher-group-card]="
                  voucherGroupForm.value.voucherGroup === voucherGroup
                "
                (click)="selectVoucherGroup(voucherGroup)"
                (keyup.enter)="selectVoucherGroup(voucherGroup)"
              >
                <div
                  class="avatar"
                  *ngIf="voucherGroup.imageUrl"
                  style="background-image:url('{{ voucherGroup.imageUrl }}');"
                ></div>
                <div
                  class="avatar placeholder"
                  *ngIf="!voucherGroup.imageUrl"
                >
                  {{ voucherGroup.name[0] }}
                </div>
                <div class="card-content">
                  <div class="title-slogan">
                    <div class="title">
                      {{ voucherGroup.name }}
                    </div>
                    <div
                      class="slogan"
                      *ngIf="voucherGroup.slogan"
                    >
                      {{ voucherGroup.slogan }}
                    </div>
                  </div>
                  <div class="voucher-value-wrapper">
                    <div
                      class="voucher-value"
                      *ngIf="
                        voucherGroup.couponValue &&
                        !voucherGroup.altTextCouponValue
                      "
                    >
                      €{{ getCurrencyString(voucherGroup.couponValue) }}
                    </div>
                    <div
                      class="coupon"
                      *ngIf="!voucherGroup.altTextCouponValue"
                    >
                      {{ 'find-voucher-group.body.voucher' | translate }}
                    </div>
                    <div
                      class="alt-coupon"
                      *ngIf="voucherGroup.altTextCouponValue"
                    >
                      {{ voucherGroup.altTextCouponValue }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                *ngIf="
                  groupLinkFor.voucherGroups.length > 1 &&
                  i2 < groupLinkFor.voucherGroups.length - 1
                "
                class="separator"
              >
                <div class="text">
                  - {{ 'find-voucher-group.body.or' | translate }} -
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="stepper-buttons-wrapper">
          <div class="stepper-buttons">
            <button
              mat-button
              matStepperPrevious
              type="button"
            >
              <mat-icon>arrow_back</mat-icon>
              {{ 'find-voucher-group.body.previous' | translate }}
            </button>
            <button
              mat-flat-button
              matStepperNext
              [disabled]="!voucherGroupForm.value.voucherGroup"
            >
              {{ 'find-voucher-group.body.select' | translate }}
            </button>
          </div>
        </div>
      </div>
    </mat-step>
    <mat-step [stepControl]="termsForm">
      <div
        class="voucher-group-details"
        *ngIf="voucherGroupForm.value.voucherGroup as voucherGroup"
      >
        <div
          class="top-title"
          role="heading"
          aria-level="1"
        >
          {{ voucherGroup.name }}
        </div>
        <div class="small-top-title">
          {{ addressString }}
        </div>
        <div
          class="avatar large image"
          *ngIf="voucherGroup.imageUrl"
          style="background-image:url('{{ voucherGroup.imageUrl }}');"
        ></div>
        <div
          class="avatar large placeholder"
          *ngIf="!voucherGroup.imageUrl"
        >
          {{ voucherGroup.name[0] }}
        </div>
        <div class="title-slogan-desc">
          <h3
            class="slogan"
            *ngIf="voucherGroup.slogan"
          >
            {{ voucherGroup.slogan }}
          </h3>
          <div
            class="description"
            *ngIf="voucherGroup.description"
          >
            {{ voucherGroup.description }}
          </div>
        </div>
        <div class="terms">
          <h3 class="title">
            {{ 'find-voucher-group.body.terms_group' | translate }}
          </h3>
          <div
            class="voucher-group-terms"
            *ngIf="voucherGroup.terms"
          >
            {{ voucherGroup.terms }}
          </div>
          <div
            class="general-terms"
            *ngIf="!termsUrl"
          >
            <div class="title">
              {{ 'find-voucher-group.body.terms_general' | translate }}
            </div>
            <app-general-terms></app-general-terms>
          </div>
          <div
            class="general-terms"
            *ngIf="termsUrl"
          >
            <mat-label>
              <span>{{ 'activate.body.terms.read-terms' | translate }} </span>
              <a
                href="{{ termsUrl }}"
                target="_blank"
                class="custom-tooltip terms-link-info"
                aria-label="algemene voorwaarden"
              >
                {{ 'activate.body.terms.terms-condition' | translate }}
              </a>
              <span>{{ 'activate.body.terms.here' | translate }}</span>
            </mat-label>
          </div>
        </div>
        <form
          [formGroup]="termsForm"
          aria-label="voorwaarden checkbox"
        >
          <mat-checkbox
            class="terms-checkbox"
            formControlName="acceptTerms"
          >
            {{ 'find-voucher-group.body.terms_accept' | translate }}
          </mat-checkbox>
        </form>
        <div class="stepper-buttons-wrapper">
          <div class="stepper-buttons">
            <button
              mat-button
              matStepperPrevious
              (click)="termsForm.reset()"
            >
              {{ 'find-voucher-group.body.previous' | translate }}
            </button>
            <button
              mat-flat-button
              (click)="generateActivateVoucherStep()"
              [disabled]="!termsForm.valid"
            >
              {{ 'find-voucher-group.body.next' | translate }}
            </button>
          </div>
        </div>
      </div>
    </mat-step>
    <mat-step>
      <div class="activate-title-wrapper">
        <div
          class="title"
          role="heading"
          aria-level="1"
        >
          Uw gegevens
        </div>
        <div class="small-title-no-sub">
          U wilt op {{ addressString }} deelnemen aan de volgende campagne:
        </div>
      </div>
      <app-activate-voucher
        *ngIf="activateVoucherComponentData"
        [inputData]="activateVoucherComponentData"
      ></app-activate-voucher>
      <div class="floating-back-button-wrapper">
        <div class="floating-back-button">
          <button
            mat-button
            matStepperPrevious
            (click)="activateVoucherComponentData = null"
          >
            {{ 'find-voucher-group.body.previous' | translate }}
          </button>
        </div>
      </div>
    </mat-step>
  </mat-stepper>
</div>
<ng-template #loading>
  <div class="loading-wrapper">
    <div class="loading">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</ng-template>
