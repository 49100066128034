/* src/app/saldo-checker/saldo-checker.component.scss */
.wrapper {
  overflow-y: auto;
  max-height: calc(100vh - 16px);
  padding: 8px;
}
.wrapper .saldo-checker {
  margin: auto;
  width: 300px;
  box-shadow: none;
  background-color: white;
}
.wrapper .saldo-checker .title {
  font-family:
    Viga,
    "Helvetica Neue",
    sans-serif;
  font-size: 44px;
  text-align: start;
  color: var(--primary);
}
.wrapper .saldo-checker .info {
  font-family:
    Roboto-Light,
    "Helvetica Neue",
    sans-serif;
  color: #4d4d4d;
  font-size: 16px;
  margin: 15px 0 30px 0;
}
.wrapper .saldo-checker .info-voucher {
  color: #656d71;
  margin-top: -14px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 85%;
}
.wrapper .saldo-checker .saldo-form {
  display: flex;
  flex-wrap: wrap;
}
.wrapper .saldo-checker .saldo-form button {
  margin-right: 12px;
}
.wrapper .saldo-checker .saldo-form mat-card-content {
  margin-bottom: 36px;
}
.wrapper .saldo-checker .saldo-form .form-field-width {
  width: 100%;
}
.wrapper .saldo-checker .recaptcha {
  margin-top: 20px;
}
.wrapper .request-button {
  border-radius: 20px;
}
.wrapper .imgWrapper {
  text-align: start;
  margin-bottom: 12px;
}
.wrapper .imgWrapper .logo {
  width: 90px;
  content: var(--logo-fullwidth);
}
.wrapper .balance-check {
  margin-top: 20px;
}
.wrapper .balance-check .info {
  color: var(--secondary);
}
.wrapper .balance-check .valid {
  font-family:
    Roboto,
    "Helvetica Neue",
    sans-serif;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 20px;
  text-align: start;
  color: #40484b;
}
.wrapper .balance-check .balance {
  font-family:
    Viga,
    "Helvetica Neue",
    sans-serif;
  margin-bottom: 10px;
  font-size: 20px;
  text-align: start;
  color: var(--secondary);
}
.wrapper .balance-check .balance-text {
  font-family:
    Roboto,
    "Helvetica Neue",
    sans-serif;
  font-weight: bold;
  margin: 15px 0 30px 0;
  font-size: 16px;
  color: var(--secondary);
  text-align: start;
}
.wrapper .mat-card-actions {
  display: flex;
  justify-content: flex-end;
  margin: 0 !important;
}
.wrapper .result-string,
.wrapper .result-date {
  text-align: start;
  font-family:
    Roboto-Light,
    "Helvetica Neue",
    sans-serif;
  color: #4d4d4d;
  font-size: 16px;
}
.loading-wrapper {
  height: 100vh;
  width: 100vw;
}
.loading-wrapper .loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}
/*# sourceMappingURL=saldo-checker.component.css.map */
